import axios from "axios";
import { tokenService } from "./tokenService";
import { addSettingsDomainType } from "../store/CalendarReducer";
import { datesType, workDayDbType,  workDayUpdateType } from "../apiTypes/responses/workTimeResponseType";
import { apiAuth } from "./apiAuth";



const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/workTimes`,
})

instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );

export const apiWorkTimes = {
    createPeriod(period: string, items: addSettingsDomainType[],owner: string){
        return instance.post<workDayDbType>("/create", {period, datesArray:items,owner})
    },
    getPeriod(period: string,userId: string){
        return instance.get<workDayDbType>(`/${userId}/${period}`)
    },
    deleteDate(periodId: string,date: string){
        return instance.delete<{date: string, periodId: string}>(`delete/day/${periodId}/${date}`)
    },
    updateDate(userId: string, data:workDayUpdateType){
        return instance.put<workDayDbType>(`/add/day/${userId}`, data)
    },
    getWorkTimesForWeek(userId: string,start: string, end: string){
        return instance.get<datesType[]>(`/for-week`,{
            params: {
                start,
                end,
                userId
            }
        })
    },
    getMaxEndOfEntry(userId: string,date: string,time: string){
      return instance.get<{endOfDay:string, beginNextEntry: string}>(`/end-of-day/${userId}`,{
        params: {
          date,
          time
        }
      })
    }
}