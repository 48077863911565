import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Switch from "@mui/material/Switch"
import c from "./additional.module.css"
import { useDispatch, useSelector } from "react-redux"
import { ModalWindowStateType, deleteAdditionalOptionsArrayAC, setAdditionalOptionsArrayAC, showCallListAC, showQueueMonthAC } from "../../store/ModalWindowsReducer"
import { AppRootState } from "../../store/storeRedux"
import { useEffect } from "react"
import { FlexBox } from "../../styles/components/FlexBox"
import { Box } from "../../styles/components/Box"


export const AdditionaWindow = () => {
    const dispatch = useDispatch()
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)
    useEffect(() => {
        if(modalState.callList ){
            dispatch(setAdditionalOptionsArrayAC("Список обзвона"))
        }
        if(modalState.queueMonth){
            dispatch(setAdditionalOptionsArrayAC("Очередь на месяц"))
        }
        if(!modalState.callList){
            dispatch(deleteAdditionalOptionsArrayAC("Список обзвона"))
        }
        if(!modalState.queueMonth){
            dispatch(deleteAdditionalOptionsArrayAC("Очередь на месяц"))
        }
    },[modalState.callList, modalState.queueMonth])
    return (
        <Box height="75px" zIndex={25} background="#fff" top="125px" right="0px" position="absolute" gap={3} width="auto">
            <FlexBox  direction="column" gap={2}>
                <FormControlLabel checked = {modalState.callList} onChange={(e, val) => dispatch(showCallListAC(val))}  style={{background: "#f8f8f8", borderRadius: "8px",margin: 0, padding: "0 10px", color: "#262626"}} control={<Switch defaultChecked />} label="Список обзвона" />
                <FormControlLabel checked = {modalState.queueMonth} onChange={(e, val) => dispatch(showQueueMonthAC(val)) } style={{background: "#f8f8f8", borderRadius: "8px", margin: 0, padding: "0 10px",color: "#262626"}} control={<Switch defaultChecked />} label="Очередь на месяц" />
            </FlexBox>
        </Box>
    )
}
