import { addDays, addMonths, endOfMonth, endOfWeek, isAfter, isBefore, startOfMonth, subMonths } from "date-fns"



export const calendarHelper = {
    getBeginMonth(date: Date):Date{
        const begin = startOfMonth(date)
        return begin
    },
    getPeriodString(date: Date):string{
        return date.toLocaleString("ru",{month: "2-digit", year: "numeric"})
    },
    getMonth(date:Date):string{
        return date.toLocaleString("ru",{month: "long"})
    },
    addMonth(date:Date):Date{
        const newPeriod = addMonths(date,1)
        return newPeriod
    },
    subMonth(date:Date):Date{
        const newPeriod = subMonths(date,1)
        return newPeriod
    },
    localeStringToDate(date: string):Date{
        const formatDate = date.split(".").reverse().join("-")
        const dateNew =  new Date(formatDate)
        return dateNew
    },
    isoDateToLocal(date: string): string{
        return new Date(date).toLocaleDateString()
    },
    getDatesArray (startDate: Date):{dayOrder: number, dateCurrentDay:string}[]{
        const datesArray:{dayOrder: number, dateCurrentDay:string}[] = []
        let start = startDate
        const end = endOfMonth(startDate)
        while(!isAfter(start,end)){
            datesArray.push({dayOrder: start.getDay() === 0 ? start.getDay() + 7 : start.getDay(), dateCurrentDay: start.toLocaleDateString()})
            start = addDays(start,1)
        }
        return datesArray
    },
    getMonthFromNumber(month: number):string{
        switch(month){
            case 1 : return "январь"
            case 2 : return "февраль"
            case 3 : return "март"
            case 4 : return "апрель"
            case 5 : return "май"
            case 6 : return "июнь"
            case 7 : return "июль"
            case 8 : return "август"
            case 9 : return "сентябрь"
            case 10 : return "октябрь"
            case 11 : return "ноябрь"
            case 12 : return "декабрь"
                default:
                    return "Декабрь"
        }
    }
}