import { add, addDays, addMonths, endOfMonth, endOfWeek, format, formatISO, isAfter, isBefore, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from '../store/storeRedux';
import { selectDayAC, setMonthAC, updateDate } from '../store/CalendarReducer';
import { currentMonthDataType, setMonthForSettingsAC, stateSettingsType } from '../store/SettingReducer';
import { ModalWindowStateType, showModalForUpdateDayAC } from '../store/ModalWindowsReducer';
import { ChangeWindow } from './changeWindow/ChangeWindow';
import { Text } from '../styles/components/Text';
import { theme } from '../styles/components/Theme.styled';
import { Box } from '../styles/components/Box';
import { FlexBox } from '../styles/components/FlexBox';
import { SuperButton } from '../styles/components/SuperButton';
import { NextPrevButton } from '../styles/components/NextButton';
import { Grid } from '../styles/components/Grid';
import { useEffect, useState } from 'react';
import { ru } from 'date-fns/locale';
import { calendarHelper } from '../utils/calendar/calendar-helper';


type settingDateType = { dayOrder: number, dateCurrentDay: string }[]
type datesType = {
    date: Date,
    isoDate: string,
    isPresent: boolean
}
export const CalendarFreeTime = () => {
    const dispatch = useDispatch()
    const [start, setStart] = useState<Date>(new Date())
    const [dates, setDates] = useState<Array<datesType>>([])
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)
    const prevMonth = () => {
        setStart(subMonths(start, 1))
    }
    const nextMonth = () => {
        setStart(addMonths(start, 1))
    }

    const fillCalendar = () => {
        const datesArray: datesType[] = []
        const datesForSettngs: settingDateType = []
        let firstDay = startOfMonth(start)
        let weekOfFirst = startOfWeek(firstDay, { locale: ru })
        const lastDay = endOfMonth(start)
        let weekOfEnd = endOfWeek(lastDay, { locale: ru })
        while (isBefore(weekOfFirst, weekOfEnd)) {
            if (weekOfFirst.getMonth() === start.getMonth()) {
                datesForSettngs.push({
                    dayOrder:  weekOfFirst.getDay() === 0 ? weekOfFirst.getDay() + 7 : weekOfFirst.getDay(),
                    dateCurrentDay: weekOfFirst.toLocaleDateString()
                })
            }
            datesArray.push({ date: weekOfFirst, isPresent: weekOfFirst.getMonth() === start.getMonth(),isoDate: calendarHelper.localeStringToDate(weekOfFirst.toLocaleDateString()).toISOString() })
            weekOfFirst = addDays(weekOfFirst, 1)
        }
        setDates(datesArray)
        dispatch(setMonthForSettingsAC(datesForSettngs, start.toLocaleString('ru', { month: 'numeric', year: 'numeric' })))
    }

    let weekDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"]
    const year = start.getFullYear()
    const month = start.toLocaleString("ru", { month: "long" })

    useEffect(() => {
        fillCalendar()
    }, [start])

    return (

        <Box position='relative' boxShadow='0px 4px 8px 0px rgba(0, 0, 0, 0.25)' width='330px'  height='100%' borderRaduis='16px' background='#fff'>
            {
                modalState?.modalForUpdateDay && !modalState.timePickerWindow && <ChangeWindow />
            }
            <FlexBox direction='column' align='center' justify='center' width='100%' height='100%' padding='14px 17px'>
                <Text size={14} color={theme.colors.fonts.main} font='mont'>Выберите день для создания интервала</Text>
                <Text size={14} font='mont' color={theme.colors.fonts.main}>{year}</Text>

                <FlexBox padding='0 20px' direction='row' width='100%' justify='space-between' align='center'>
                    <SuperButton onClick={prevMonth} maxWidth='15px' varinat='fake'>
                        <NextPrevButton component="calendar" type='prev' />
                    </SuperButton>
                    <Text transform='capitalize' font='mont' size={14} color={theme.colors.fonts.main}>{month}</Text>
                    <SuperButton onClick={nextMonth} maxWidth='15px' varinat='fake'>
                        <NextPrevButton component='calendar' type='next' />
                    </SuperButton>
                </FlexBox>
                <FlexBox height='100%' padding='15px 0' width='100%' direction='column'>
                    <FlexBox direction='row' width='100%' justify='space-between'>
                        {
                            weekDays.map(w =>
                                <FlexBox  align='center' justify='center' width='100%' direction='row' key={new Date().toISOString() + w}>
                                    <Text transform="capitalize" weight={500} font='Inter' color={theme.colors.fonts.main} size={15} >{w}</Text>
                                </FlexBox>
                            )
                        }

                    </FlexBox>
                    <FlexBox height='100%' direction='row'>
                        <Grid width='100%' height='100%' columns='1fr 1fr 1fr 1fr 1fr 1fr 1fr' gap={2}>
                            {dates.map((d,i) =>

                                <FlexBox border='2px solid #fff' className='date' align='center' justify='center' borderRadius='8px' width='100%' height='100%' direction='row' key={d.isoDate + i} onClick={() => {
                                    dispatch(showModalForUpdateDayAC(true))
                                    dispatch(updateDate(d.isoDate))
                                    dispatch(selectDayAC(d.isoDate))
                                }
                                }>
                                    <Text font='mont' size={14} color={d.isPresent ? theme.colors.fonts.main : "#AAA"}>{d.date.getDate()}</Text>
                                </FlexBox>
                            )
                            }
                        </Grid>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Box>

    )
}