import { css, styled } from "styled-components";
import sprite from "../../images/sprite.svg"

type propsType = {
    isAuth: boolean
}
export const Logo = (props: propsType) => {
    return (
        <LogoContainer isAuth={props.isAuth}>
            {
                props.isAuth
                    ? <svg className="logo" width={43} height={32}>
                        <use xlinkHref={`${sprite}#logo2`}></use>
                    </svg>
                    : <svg className="logo" width={86} height={65}>
                        <use xlinkHref={`${sprite}#logo`}></use>
                    </svg>
            }
            <svg className="logoMobile" width={28} height={21}>
                <use xlinkHref={`${sprite}#logo_mobile`}></use>
            </svg>

            <Text isAuth={props.isAuth}>Ipluse</Text>
        </LogoContainer>
    )

}

const LogoContainer = styled.div<propsType>`
    display: flex;
    flex-direction: ${props => props.isAuth ? "row" : "column"};
    ${props => props.isAuth && css<propsType>`
        align-items: end;
        gap: 10px;
        justify-content: center;
    `};
    .logoMobile {
        display:none;
        @media screen and (max-width: 768px) {
            display:block
      };
    }
    .logo {
        @media screen and (max-width: 768px) {
        display:none
      };
    };
    @media screen and (max-width: 768px) {
            gap:3px;
            align-items:center;
            flex-direction: column
      };
`
const Text = styled.span<propsType>`
    color: #FFF;
text-align: center;
font-family: Mont;
font-size: ${props => props.isAuth ? "14px" : "28px"};
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: 0.56px;
@media screen and (max-width: 768px) {
       font-size: 12px;
      };
`