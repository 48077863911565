import { css, styled } from "styled-components";


type propsType = {
    size: number | string,
    color: string,
    font: "mont" | "Inter",
    transform? : "capitalize",
    weight?: number,
    realtiveSize?:boolean,
    subtitle?:boolean,
    content?:string,
    contentMargin?:string,
    contentSize?:number,
    opacity?:number,
    align?:string
}
export const Text = styled.span<propsType>`
    font-size: ${props => props.size + "px"};
    color: ${props => props.color };
    font-family: ${props => props.font};
    text-transform: ${props => props.transform ? props.transform : "none"};
    @media (max-width: 900px) {
        font-size: 10px;
    }
    @media (max-width: 768px) {
        font-size: 9px;
    }
    ${props => props.realtiveSize && css<propsType>`
        font-size: ${props => props.size};
    `}
    ${props => props.weight && css<propsType>`
        font-weight: ${props => props.weight};
    `};
    ${props => props.opacity && css<propsType>`
        opacity: ${props => props.opacity};
    `};
    ${props => props.align && css<propsType>`
        text-align: ${props => props.align};
    `}
   
    ${props => props.subtitle && css<propsType>`
        &::after{
            content: '${props => props.content}';
            margin-left: ${props => props.contentMargin ? props.contentMargin : 0};
            ${props => props.contentSize && css<propsType>`
                font-size: ${props => props.contentSize + "px"};
            `}
        }
    `}
    
`