import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Registration } from './registration/Registration';
import { Login } from './registration/Login';
import Greeting from './greeting/Greeting';
import { Navbar } from './Navbar/Navbar';
import { UserInfo } from './userInfo/UserInfo';
import { authMeTC, setUserAC, stateUserType } from './store/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from './store/storeRedux';
import { registerAC, stateRegisterType } from './store/AuthReducer';
import { UserProfile } from './userProfiles/UserProfile';
import { ForgotPassword } from './registration/ForgotPassword';
import { Analytic } from './analytics/Analytic';
import { Tariffs } from './tarifs/Tariff';
import { ChargeOff } from './adminPage/chargeOff/ChargeOff';
import { ClientCard } from './mainPage/clientCard/ClientCard';
import { ProSettings } from './proSettings/ProSettings';
import { Header } from './header/Header';
import { Notification } from './notifications/Notification';
import ProgressBar from './resultComponent/ProgressBar';
import { socket } from './apiServer/socket';
import { ModalComponents } from './modalWindows/ModalComponents';
import { Wrapper } from './styles/components/Wrapper';
import { FlexBox } from './styles/components/FlexBox';
import { CompleteRegister } from './registration/CompleteRegister';
import { RecoveryPasswordPage } from './registration/RecoveryPasswordPage';
import ProtectedRoutes from './ProtectedRoutes';
// const MainPage = React.lazy(() => import('./mainPage/MainPage'));
import { MainPage } from './mainPage/MainPage';
import TimeTables from './timeTable/TimeTables';
import Settings from './settings/Settings';
import { tokenService } from './apiServer/tokenService';
import newMessageSound from "./sounds/tone.mp3"
import { PriceList } from './proSettings/PriceList';
import { Vidzhet } from './proSettings/Vidzhet';
import { TeamMates } from './proSettings/TeamMates';
import { DeviceView, setDeviceViewAC } from './store/AppReducer';
import { WorkCategory } from './proSettings/WorkCategory';
import { AdminPage } from './adminPage/AdminPage';




const selectApp = (app: AppRootState) => app.appState
function App() {

  const userState = useSelector<AppRootState, stateUserType>(state => state.user)
  const auth = useSelector<AppRootState, stateRegisterType>(state => state.authState)
  const appState = useSelector(selectApp)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const userId = userState.user?.id as string
  useEffect(() => {
   const token = tokenService.getToken()
    if (!userState.isAuth && token) {
      dispatch(authMeTC())
     
    }
  }, [])
  

 


  useEffect(() => {
    // socket.on("example", (data) => {
    //   alert(data)
    // })
    
    socket.on("not",async(data:any) => {
    
       console.log("socket data",data)
      
      try {
       
         
          
          
        
        
      } catch (error) {
        console.error("eror",error)
      }
     
    })
  },[])
  
  useEffect(() => {
   
    if (!userState.isAuth && auth.isRegister) {
      if (userState.user && !userState.user?.completeRegisterData.firstLevel && !userState.user.completeRegisterData.secondLevel) {
        navigate("/register/complete")
        return
      }
      if (userState.user && userState.user?.completeRegisterData.firstLevel && userState.user.completeRegisterData.secondLevel ) {
        navigate("/userInfo")
        return
      }
      if (userState.user && userState.user?.completeRegisterData.firstLevel && userState.user.completeRegisterData.secondLevel) {
        navigate("/greeting")
        return
      }
    }
    if (userState.isAuth) {
     
      if (userState.user && !userState.user.completeRegisterData.firstLevel) {
        navigate("/register/complete")
        return
      }
      if (userState.user && userState.user.completeRegisterData.firstLevel && !userState.user.completeRegisterData.secondLevel) {
        navigate("/userInfo")
        return
      }
      userState.user?.isAdmin ? navigate("/sa/clients") : navigate("/main")
    } else {
      navigate("/login")
      return
    }
  }, [userState.isAuth, userState.user?.completeRegisterData.firstLevel, userState?.user?.completeRegisterData.secondLevel])



useEffect(() => {
  if(window.innerWidth <= 760){
    dispatch(setDeviceViewAC(DeviceView.MOBILE))
  }
},[window.innerWidth])



  return (

    <Suspense fallback = {<ProgressBar/>}>
      <Wrapper>
        <Navbar id={userId} />
        <FlexBox className='content' height='100%' justify='start' padding='25px 25px 12px 25px' width='100%' direction='column'>
          {userState.isAuth && userState?.user?.completeRegisterData.firstLevel && userState?.user?.completeRegisterData.secondLevel && <Header />}
          {
            appState.isLoading && <ProgressBar />
          }
          <ModalComponents />
          <Routes>
            {!userState.user?.completeRegisterData.firstLevel && (auth.isRegister || userState.isAuth) && <Route path='/register/complete' element={<CompleteRegister />}></Route>}

            <Route 
             element={<ProtectedRoutes />} 
            >
              <Route path='/' element={<Navigate to={"/main"} />}></Route>
              <Route path='/main' element={<MainPage userId={userState.user?.id as string} />} />
              <Route path='/timeTable' element={<TimeTables />}></Route>
              <Route path='timeTable/settings' element={<Settings />}></Route>
              <Route path='/userProfile' element={<UserProfile />}></Route>
              <Route path='/analytic' element={<Analytic />}></Route>
              <Route path='/sa/clients' element={<AdminPage />}></Route>
              {/* <Route path='/payments' element={<Payments />}></Route> */}
              <Route path='/tariffs' element={<Tariffs />}></Route>
              <Route path='/chargeoff' element={<ChargeOff />}></Route>
              <Route path='/notifications' element={<Notification />}></Route>
              <Route path="/client/:id" element={<ClientCard />}></Route>
              <Route path='/proSettings/' element={<ProSettings />}>
                <Route  index element={<Navigate to={'priceList'}/>}></Route>
                <Route  path='workcategory' element={<WorkCategory/>}></Route>
                <Route  path='priceList' element={<PriceList/>}></Route>
                <Route  path='vidzhet' element={<Vidzhet/>}></Route>
                <Route  path='team' element={<TeamMates/>}></Route>
              </Route>
            </Route>

            <Route path='/' element={<Login />} ></Route>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Registration />}></Route>
            <Route path='/forgot' element={<ForgotPassword />}></Route>
            <Route path='/recovery-password' element={<RecoveryPasswordPage />}></Route>
            {(auth.isRegister || userState.isAuth) && userState.user?.completeRegisterData.firstLevel && <Route path='/userInfo' element={<UserInfo userId={userState.user.id as string} />} ></Route>}
            <Route path='/greeting' element={<Greeting />}></Route>
          </Routes>
        </FlexBox>

      </Wrapper>
    </Suspense>



  );
}

export default App;
