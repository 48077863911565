



export enum calendarTrafficType {
    NONE = "NONE",
    SEVERAL ="SEVERAL",
    MORE = "MORE",
    UPLOADED = "UPLOADED"
}

