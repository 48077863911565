
import axios from "axios";
import { rootUserType, tariffType } from "../store/UserReducer";
import {  updateFullNameResponseType, userUpdateDataResponseType } from "../apiTypes/responses/userResponsesType";
import { tokenService } from "./tokenService";
import { tariffInfoType } from "../apiTypes/responses/tariffRespons.type";
import { apiAuth } from "./apiAuth";


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/users`
})
const profileInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/profile`
})

instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token)  config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
    
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );
export const apiUser = {
   
    selectTariff(id: string | null, tariff: tariffType) {
        return instance.put<tariffInfoType>(`/select-tariff`, { tariff: tariff })
    },
    checkBalance(userId: string) {
        return profileInstance.post("/check/balance", { userId })
    },
    setProfileInfo( userAvatar: File | null, surname: string, name: string, patronymic: string) {
        const formData = new FormData()
       if(userAvatar) formData.append('userAvatar', userAvatar)
        formData.append('lastName', surname)
        formData.append('firstName', name)
        formData.append('thirdName', patronymic)
        return instance.put<updateFullNameResponseType>("/update/full-name", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    },
    generateWorkID(){
        return instance.put<{workID: string}>("/generate/workID")
    },
    updateUserData(oldPassword: string, newPassword: string, color: string, phoneNumber: string){
       return instance.put<userUpdateDataResponseType>("/update", {color, phoneNumber, oldPassword, newPassword})
    },
    findUserByProfileId(id: string){
        return instance.get<rootUserType>(`/profile-id/${id}`)
    }
   
    
}

