

import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createCategoryAC } from "../../store/ModalWindowsReducer"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { stateUserType } from "../../store/UserReducer"
import { Box } from "../../styles/components/Box"
import { theme } from "../../styles/components/Theme.styled"
import { FlexBox } from "../../styles/components/FlexBox"
import { SuperButton } from "../../styles/components/SuperButton"
import { TextField } from "../../styles/components/TextField"
import { Text } from "../../styles/components/Text"
import { createWorkCategoryTC } from "../../store/ProsettingsReducer"
export const CreateCategory = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const user = useSelector<AppRootState, stateUserType>( state => state.user)
    const hideModal = () => dispatch(createCategoryAC(false))
    const [value, setValue] = useState("")
    const [error, setError] = useState<string>("")
    const saveCategory = () => {
       if(value) {
        dispatch(createWorkCategoryTC(value))
        dispatch(createCategoryAC(false))
       } else{ 
        setError("Категория не может быть пустой")
       }
    }
    const handler = (v: string) => {
        setError("")
        setValue(v)
    } 
    return (
            <Box   direction="column" width="353px" height="max-content" padding="24px 32px" background={theme.colors.colorForm} borderRaduis="16px" boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)" position="fixed" top="50%" left="50%" transform="translateX(-50%) translateY(-50%)" zIndex={27} gap={20} >
                <Text color={theme.colors.fonts.main} weight={600} font="Inter" size={18}>Добавление категорий работ</Text>
                <FlexBox  gap={5} direction="column">
                    <Text color={theme.colors.fonts.main} size={16} font="mont">Название</Text>
                    <TextField placeholder="Введите название категории" value={value} onChange = { (e) => handler(e.currentTarget.value)} type="text" />
                    {
                        error && <Text color={theme.colors.fonts.errors} font="mont" size={12}>{error}</Text>
                    }
                </FlexBox>
                
               
                <FlexBox gap={10} height="36px" direction="row" width="1005">
                    <SuperButton varinat="outlined" onClick={hideModal}>Отменить</SuperButton>
                    <SuperButton varinat="contained" onClick={saveCategory}>Сохранить</SuperButton>
                </FlexBox>
            </Box>
        

    )
}