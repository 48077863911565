import { useSearchParams } from "react-router-dom"
import { FlexBox } from "../styles/components/FlexBox"
import { Text } from "../styles/components/Text"
import { TextField } from "../styles/components/TextField"
import { theme } from "../styles/components/Theme.styled"
import { useForm } from "react-hook-form"
import { SuperButton } from "../styles/components/SuperButton"
import { passRecoveryFormType } from "../apiTypes/formTypes/registerFormType"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { restorePasswordTC } from "../store/AuthReducer"
import { NavLink } from "react-router-dom"
import { useEffect } from "react"
import { setRestorePassErrorAC } from "../store/ErrorMessagesReducer"

const selectError = (state: AppRootState) => state.errorState
const selectAppState = (state: AppRootState) => state.appState
export const RecoveryPasswordPage = () => {
    const dispatch: thunkDispatchRootType = useDispatch()
    const errorState = useSelector(selectError)
    const appState = useSelector(selectAppState)
    const [searchParams, setSearchParams] = useSearchParams()
    const { handleSubmit, register, formState: { errors }, watch } = useForm<passRecoveryFormType>()
    const code = searchParams.get("code")
    const userId = searchParams.get("userId")
    const restorePassword = (data: passRecoveryFormType) => {
        if (code && userId) dispatch(restorePasswordTC(code, userId, data.password))
    }
    useEffect(() => {
        const sub = watch((value, { name, type }) => {
            if (name && type) {
                dispatch(setRestorePassErrorAC(""))
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [watch])
    return (
        <FlexBox direction="row" align="center" justify="center" height="100%" width="100%" >
            <FlexBox as={"form"} onSubmit={handleSubmit(restorePassword)} direction="column" width="325px">
                <FlexBox direction="column" width="100%" align="start" gap={5}>
                    <Text font="mont" color={theme.colors.fonts.main} size={28}>Восстановление пароля</Text>
                    <Text size={14} color={theme.colors.fonts.main} font="mont">Введите новый пароль</Text>
                </FlexBox>
                <FlexBox direction="column" gap={30} marginTop="30px" align="center" justify="center">

                    <FlexBox direction="column" align="start" width="100%">
                        <Text size={14} font="mont" color={theme.colors.fonts.main}>Пароль</Text>
                        <TextField {...register("password", {
                            required: true, minLength: { value: 8, message: "Пароль должен быть не менее 8 символов" }
                        })} width="100%" type="password" />
                        {
                            errors.password?.message && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.password.message}</Text>
                        }
                        {
                            errorState.recoveryPassError && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errorState.recoveryPassError}</Text>
                        }
                    </FlexBox>

                    <FlexBox direction="column" width="100%" align="start">
                        <Text size={14} font="mont" color={theme.colors.fonts.main}>Подтвердите пароль</Text>
                        <TextField {...register("seconPassword", {
                            validate: (v) => v === watch("password") || "Пароли не совпадают"

                        })}
                            width="100%" type="password" />
                        {errors.seconPassword?.message && <Text font="mont" size={14} color={theme.colors.fonts.errors}>{errors.seconPassword.message}</Text>}
                    </FlexBox>
                    <FlexBox width="100%" direction="column">
                        <SuperButton type="submit" height="48px" varinat="contained" hover hoverColor={theme.colors.fonts.main} >Восстановить</SuperButton>
                        {
                            errorState.passwordCodeError && <FlexBox direction="column">
                                <Text size={14} color={theme.colors.fonts.errors} font="mont">
                                    {errorState.passwordCodeError}
                                </Text>
                                <NavLink to={"/forgot"}>Отправьте запрос на восстановление пароля</NavLink>
                            </FlexBox>

                        }
                        {
                            appState.succesRestorePassMessage && <FlexBox direction="column">
                                <Text size={14} color={"green"} font="mont">
                                    {appState.succesRestorePassMessage}
                                </Text>
                                <NavLink to={"/login"}>Войти в систему</NavLink>
                            </FlexBox>

                        }
                    </FlexBox>
                </FlexBox>
            </FlexBox>

        </FlexBox>
    )
}