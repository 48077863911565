import axios from "axios";
import { Dispatch } from "redux";
import { clientsStateType } from "./ClientsReducer";
import { adminClientType } from "../apiTypes/root/admin.type";
import { thunkActionRootType } from "./storeRedux";
import { setIsLoadingAC } from "./AppReducer";
import { apiAdmin } from "../apiServer/apiAdmin";
import { showadminPayWindowAC } from "./ModalWindowsReducer";

export type AdminStateType = {
  clients: adminClientType[];
};

export type adminAT = getUsersAT | incBalanceAT;

type getUsersAT = {
  type: "ADMIN/GET-USERS";
  payload: adminClientType[];
};
type incBalanceAT = {
  type: "ADMIN/INC-BALANCE";
  payload: { userId: string; balance: number };
};
const initState: AdminStateType = {
  clients: [],
};

export const AdminReducer = (
  state: AdminStateType = initState,
  action: adminAT
): AdminStateType => {
  switch (action.type) {
    case "ADMIN/GET-USERS":
      return { ...state, clients: action.payload };
    case "ADMIN/INC-BALANCE":
      return {
        ...state,
        clients: state.clients.map((cl) =>
          cl.id === action.payload.userId
            ? { ...cl, balance: cl.balance + action.payload.balance }
            : cl
        ),
      };
    default:
      return state;
  }
};
const getAdminClientsAC = (payload: adminClientType[]): getUsersAT => {
  return { type: "ADMIN/GET-USERS", payload };
};
const incBalanceAC = (userId: string, balance: number): incBalanceAT => {
  return { type: "ADMIN/INC-BALANCE", payload: { userId, balance } };
};

export const getAdminClientsTC =
  (): thunkActionRootType => async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiAdmin.getClients();
      dispatch(getAdminClientsAC(res.data));
    } catch (error) {
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const adminIncUserBalanceTC =
  (userId: string, balance: number): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiAdmin.incBalance(userId, balance);
    //   dispatch(getAdminClientsTC())
      dispatch(incBalanceAC(userId, balance));
    } catch (error) {
    } finally {
      dispatch(setIsLoadingAC(false));
      dispatch(showadminPayWindowAC({ value: false, userId: "" }));
    }
  };
