import { datesType } from "../../apiTypes/responses/workTimeResponseType";
import { workTimeRenderType } from "../../apiTypes/state/workTime-type";
import { calendarHelper } from "../../utils/calendar/calendar-helper";
import { weekPeriodType } from "../../utils/timeTable/timeTable-helper";
import { convertNumberToTimeString } from "../../utils/utils";



export const calendatStateHelper = {
    setRenderTimes(items: datesType[], weekPeriod: weekPeriodType[]):string[] | undefined{
        if (items.length) {
            const newArr: workTimeRenderType[] = []
            items.forEach(el => {
                const matchDate = weekPeriod.find(item => item.date.toLocaleDateString() === new Date(el.date).toLocaleDateString())
                if (matchDate) {
                    el.workTimes.map(val => newArr.push({ start: val.start, end: val.end, startNumber: val.startNumber, endNumber: val.endNumber, date: el.date }))
                } else {
                    return []
                }
            })
            if (!newArr.length) {
                return []
            }
            let sortingArray = newArr.sort((a, b) => a.startNumber > b.startNumber ? 1 : -1)
            if (sortingArray.length) {
                let start = sortingArray[0].startNumber
                let end = sortingArray.at(-1)?.startNumber as number
                const arrStr: string[] = []
                
                while (start <= end) {
                    const interval = start%60 === 0  ?  60 : start%60
                    arrStr.push(convertNumberToTimeString(start))
                    start = start + interval
                }
                return arrStr
            }

        } else {
            return []
        }
    },
    setRenderTimesForDate(items:datesType[], date: string): string[]{
        const searchItem = items.find(el => new Date(el.date).toLocaleDateString() === new Date(date).toLocaleDateString())
        if(!searchItem) return []
        const result:string[] = searchItem.workTimes.map(el => el.start)
        return result
    }
}