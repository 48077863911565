import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState } from "../../store/storeRedux";
import { clientsStateType, getClientsTC } from "../../store/ClientsReducer";
import { useForm } from "react-hook-form";
import { addNoteTC, getNotesTC } from "../../store/NotesReducer";
import { stateUserType, tariffType } from "../../store/UserReducer";
import theMan from "../../assets/pictures/theMan.png";
import {
  calendarStateType,
  getWorkDaysTC,
  workTimeStateType,
} from "../../store/CalendarReducer";
import {
  getFirstDateAndTimeForNoteTC,
  getMaxEndOfEntryTC,
  sessionsType,
  setDateAndTimeForNoteAC,
  setFirstFreeTimeAndDateForNoteAC,
} from "../../store/SessionsReducer";
import {
  addNewClientCardAC,
  editSelectTimeAC,
  ModalWindowStateType,
  selectClientAC,
  setNewEntryFromQueueStateAC,
  showErrorWindowAC,
  showNewEntryAC,
  showTimesWindowAC,
} from "../../store/ModalWindowsReducer";
import { TimesWindow } from "../../timeTable/TimesWindow";
import { ErrorWindow } from "../../timeTable/ErrorWindow";
import ReactDOM from "react-dom";
import {
  ProSettingsType,
  getWorkServicesTC,
} from "../../store/ProsettingsReducer";
import { CustomSelect } from "../../styles/components/CustomSelect";
import { commonUsersType } from "../../store/TeamMateReducer";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { Icon } from "../../styles/components/Icon";
import { Avatar } from "../../styles/components/Avatar";
import { Select } from "../../styles/components/Select";
import { FakeInput } from "../../styles/components/FakeInput";
import { TextField } from "../../styles/components/TextField";
import { ListItem } from "../../styles/components/ListItem";
import { FlexBox } from "../../styles/components/FlexBox";
import { Box } from "../../styles/components/Box";
import { noteInputType } from "../../apiTypes/responses/notesResponsesType";
import { FreeTimesCalendar } from "../../timeTable/FreeTimeCalendar";
import { theme } from "../../styles/components/Theme.styled";
import { resetClientCardAC, setClientCardTC } from "../../store/ClientCardReducer";
import { removeQueueTC } from "../../store/QueueReducer";
type propsType = {
  // tariff: tariffType
  // sessions: Array<sessionsType>,
  teamMates?: commonUsersType[] | null;
};

const selectClientCard = (state:AppRootState) => state.clientCard
const selectCommonState = (app: AppRootState) => app.commonReducerState;
const selectSessions = (app: AppRootState) => app.sessions;
const selectUser = (app: AppRootState) => app.user;
export const NewEntry = (props: propsType) => {
  const clients = useSelector<AppRootState, clientsStateType[]>(
    (state) => state.client
  );
  const sessions = useSelector(selectSessions);
  const user = useSelector(selectUser);
  const clientCard = useSelector(selectClientCard)
  const [clientList, setClientsList] = useState(false);
  const [minutes, setMinutes] = useState(sessions.selectedDuration || "60");
  const [selectDate, setSelectDate] = useState(false);
  const [date, setDate] = useState<string>(sessions.selectedDate);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [client, setClient] = useState<clientsStateType[]>(clients);
  const [targetClient, setTargetClient] = useState<clientsStateType | null>(
    null
  );
  const [clientName, setClientName] = useState<string>("");
  const [time, setTime] = useState<string>(sessions.selectedTime);
  const [disableNext, setDisableNext] = useState(false);
  const modalState = useSelector<AppRootState, ModalWindowStateType>(
    (state) => state.ModalWindowReducer
  );
  // const calendarState = useSelector<AppRootState, calendarStateType>(
  //   (state) => state.calendarState
  // );
  const proSettings = useSelector<AppRootState, ProSettingsType>(
    (state) => state.proSettings
  );
  // const commonState = useSelector(selectCommonState);

  useEffect(() => {
    if (!clients.length) dispatch(getClientsTC());
  }, [clients.length]);

  const dispatch = useDispatch();

  const counter = (e: any) => {
    e.preventDefault();
    setErrorMessage("");
   
    setMinutes((prev) => (Number(prev) + 5).toString());
  };
  const decrement = (e: any) => {
    e.preventDefault();
    setMinutes((parseInt(minutes) - 5).toString());
  };

  const selectClient = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 2) {
      setClientsList(true);
    }
    if (e.currentTarget.value.length === 0) {
      setTargetClient(null);
    }
    const searchClients = clients.filter(
      (cl) =>
        `${cl.surname} ${cl.name} ${cl.patronymic}`
          .toLowerCase()
          .includes(e.currentTarget.value.toLowerCase()) ||
        cl.phone
          ?.replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .split(" ")
          .join("")
          .split("-")
          .join("")
          .includes(e.currentTarget.value)
    );
    if (searchClients.length <= 0) {
      setTargetClient(null);
    }
    setClient(searchClients);
    setClientName(e.currentTarget.value);
  };

  const cancel = () => {
    dispatch(showNewEntryAC({value: false, fromQueue: false}));
  };

  const { register, handleSubmit, getValues, setValue, setError, clearErrors, watch, formState: {errors} } =
    useForm();
  const onSubmit = (data: any) => {
    if (!date || !targetClient || !time || !minutes) {
      dispatch(showErrorWindowAC(true));
      return;
    } else if (!data.service && (user.user?.tariff === tariffType.PRO || user.user?.tariff === tariffType.SuperPRO) ) {
        setError("service",{message: "Выберите услугу"})
        return
    } else {
      if (Number(minutes) > 0) {
        if (targetClient) {
          const payload: noteInputType = {
            userId: user.user?.id as string,
            clientId: targetClient?.id as string,
            date,

            time,
            duration: minutes,
            name: targetClient?.name,
            surname: targetClient.surname,
            comments: data.comments,
            clientAvatar: targetClient.photoURL,
            phone: targetClient.phone as string,
            serviceId: data.service ? data.service : "",
          };
          if(user.user?.tariff === tariffType.FREE) delete payload.serviceId
          dispatch(addNoteTC(payload));
          if(modalState.newEntryShow.fromQueue && modalState.newEntryFromQueueState.queueId){
            dispatch(removeQueueTC(modalState.newEntryFromQueueState.queueId))
          }
          dispatch(showNewEntryAC({value: false, fromQueue: false}));
        }
      } else {
        setErrorMessage("Продолжительность не может быть 0 минут");
      }
    }
  };

  const defineDate = (e: any) => {
    e.preventDefault();
    setSelectDate(true);
  };

  const showTimesWindow = () => {
    dispatch(showTimesWindowAC(true));
  };
  const clientHandler = () => {
    setClientsList(true);
  };
  const showAddNewClientHandler = (e: React.SyntheticEvent<HTMLLIElement>) => {
    e.stopPropagation();
    dispatch(addNewClientCardAC(true));
  };
  const selectClientHandler = (cl: clientsStateType) => {
    setClientName(`${cl.surname} ${cl.name} ${cl.patronymic}`);
    setTargetClient(cl);
    setClientsList(false);
  };

  const disabledPrev = (!date || !time)
  const disabledNext = (!date || !time) || disableNext || (!sessions.endOfEntryState?.endOfDay || !sessions.endOfEntryState.beginNextEntry) || (Number(minutes) === sessions.endOfEntryState.beginNextEntry)
  useEffect(() => {
    if (modalState.client) {
      setTargetClient(modalState.client);
    }
   
  }, [modalState.client]);
  
  useEffect(() => {
    if (!proSettings.workService.length && (user.user?.tariff === tariffType.PRO || user.user?.tariff === tariffType.SuperPRO)) {
      dispatch(getWorkServicesTC());
    }
  }, [proSettings.workService.length]);
  useEffect(() => {
    const sub = watch((value, {name, type}) => {
       
        if(value.service){
            clearErrors("service")
        }
    })
    return () => sub.unsubscribe()
  },[watch])

  useEffect(() => {
    if(date && time) {
      dispatch(getMaxEndOfEntryTC(user.user?.id as string,date,time))
    }
    
  },[date, time])
  
  useEffect(() => {
    if(clientCard.client.id){
      dispatch(selectClientAC(clientCard.client))
    }
  },[clientCard.client.id])

  useEffect(() => {
    if(modalState.newEntryFromQueueState.clientId){
      dispatch(setClientCardTC(modalState.newEntryFromQueueState.clientId))
    }
  },[modalState.newEntryFromQueueState.clientId])
  useEffect(() => {
    if(modalState.newEntryFromQueueState.workServiceId && proSettings.workService.length) {
      const work = proSettings.workService.find(w => w.name === modalState.newEntryFromQueueState.workServiceId)
      if(work)setValue("service",work.id)
    }
  },[modalState.newEntryFromQueueState.workServiceId, proSettings.workService.length])
  useEffect(() => {
   if(modalState.newEntryShow.fromQueue && !sessions.firstDateAndTimeForQueue.date){
    dispatch(getFirstDateAndTimeForNoteTC())
   } 
   if(sessions.firstDateAndTimeForQueue.date){
    const date = sessions.firstDateAndTimeForQueue.date
    setDate(date)
    setTime(sessions.firstDateAndTimeForQueue.time)
    setMinutes(sessions.firstDateAndTimeForQueue.duration.toString())
   }
  },[modalState.newEntryShow.fromQueue, sessions.firstDateAndTimeForQueue.date])
  useEffect(() => {
    return () => {
      dispatch(setFirstFreeTimeAndDateForNoteAC({date: "",duration: 0,time: ""}))
      dispatch(setNewEntryFromQueueStateAC({clientId: "",workServiceId: "",queueId: ""}))
      dispatch(setDateAndTimeForNoteAC("", "", ""));
      dispatch(selectClientAC(null));
      // dispatch(resetClientCardAC())
    };
  },[])
  return ReactDOM.createPortal(
    <>
      <Box
        className="newEntry"
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
        direction="column"
        width="453px"
        height="min-content"
        padding="24px 52px"
        background={theme.colors.colorForm}
        borderRaduis="16px"
        boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)"
        position="fixed"
        top="50%"
        left="50%"
        transform="translateX(-50%) translateY(-50%)"
        zIndex={27}
        gap={15}
      >
        {selectDate && (
          <FreeTimesCalendar
            setMinutes={setMinutes}
            setTime={setTime}
            setSelectDate={setSelectDate}
            setDate={setDate}
          />
        )}
        <Text font="mont" size={18} color={theme.colors.fonts.main}>
          Новая запись
        </Text>
        <Box direction="column" height="min-content" gap={12} position="relative">
          {clientList && (
            <Box
              component="newEntry"
              position="absolute"
              height="281px"
              top="85px"
            >
              {client.map((cl) => (
                <ListItem
                  onClick={() => selectClientHandler(cl)}
                  key={cl.id}
                  component="newEntry-client"
                >
                  <Avatar size="extraSmall" src={theMan} />
                  <FlexBox width="100%" align="center" direction="column">
                    <Text size={12} color="#000" font="mont">
                      {cl.surname} {cl.name} {cl.patronymic}
                    </Text>
                    <Text size={10} color="#000" font="mont">
                      {cl.phone}
                    </Text>
                  </FlexBox>
                </ListItem>
              ))}
              <ListItem onClick={showAddNewClientHandler} component="newEntry">
                <Icon svgID="blMan" width={12} height={14} />
                <Text font="mont" size={14} color={theme.colors.fonts.main}>
                  Добавить нового
                </Text>
              </ListItem>
            </Box>
          )}

          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={15} font="mont">
              Клиент
            </Text>
            <FakeInput active={targetClient ? true : false}>
              {targetClient && targetClient.photoURL && (
                <FlexBox
                  direction="row"
                  align="center"
                  justify="center"
                  width="40px"
                >
                  {targetClient && targetClient.photoURL ? (
                    <Avatar size="newEntry" src={targetClient.photoURL} />
                  ) : targetClient ? (
                    targetClient?.gender === "male" ? (
                      <Avatar size="newEntry" src={theMan} />
                    ) : (
                      <Icon svgID="avaFemale" width={38} height={32} />
                    )
                  ) : (
                    <></>
                  )}
                </FlexBox>
              )}
              {!modalState.client && (
                <TextField
                  active={targetClient ? true : false}
                  kind="fake"
                  {...register("surname")}
                  autoFocus
                  value={clientName}
                  onChange={selectClient}
                  onClick={clientHandler}
                  type="text"
                  placeholder="Начните вводить ФИО клиента"
                />
              )}
              {modalState.client && (
                <Text size={15} color="#fff" font="mont">
                  {modalState.client.surname} {modalState.client.name}{" "}
                  {modalState.client.patronymic}
                </Text>
              )}
            </FakeInput>
          </FlexBox>

          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={15} font="mont">
              Услуга
            </Text>
            <Select
              setValue={setValue}
              defaultValue={modalState.newEntryShow.fromQueue ? watch("service") : ""}
              name="service"
              state={proSettings.workService}
              placeholder="Выберите услугу"
              tooltip="Для тарифов PRO и PRO+"
            />
            <Text color={theme.colors.fonts.errors} size={12} font="mont">{errors.service?.message as string}</Text>
          </FlexBox>

          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Дата и время
            </Text>
            <FlexBox
              className="newEntryDateAndTime"
              direction="row"
              height="50px"
              width="100%"
              justify="space-between"
              gap={8}
            >
              <CustomSelect />

              {date && !selectDate ? (
                <FlexBox
                  width="100%"
                  background="#fff"
                  direction="row"
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="8px"
                  align="center"
                  justify="center"
                  onClick={defineDate}
                >
                  {new Date(date).toLocaleDateString()}
                </FlexBox>
              ) : (
                <FlexBox
                  onClick={defineDate}
                  width="94px"
                  background="#fff"
                  direction="row"
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="8px"
                  align="center"
                  justify="space-around"
                >
                  <Icon svgID="calendar2" width={16} height={16} />
                  <Text color={theme.colors.fonts.main} font="mont" size={14}>
                    Дата
                  </Text>
                </FlexBox>
              )}

              {time && !selectDate ? (
                <FlexBox
                  width="100%"
                  background="#fff"
                  direction="row"
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="8px"
                  align="center"
                  justify="space-around"
                  onClick={showTimesWindow}
                >
                  {time}
                </FlexBox>
              ) : (
                <FlexBox
                  width="94px"
                  background="#fff"
                  direction="row"
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="8px"
                  align="center"
                  justify="space-around"
                >
                  <Icon
                    svgID="clock"
                    width={22}
                    height={22}
                    transform="scale(0.7)"
                  />
                  <Text color={theme.colors.fonts.main} size={14} font="mont">
                    Время
                  </Text>
                </FlexBox>
              )}
              {modalState.timesWindow && (
                <>
                  <TimesWindow
                    setMinutes={setMinutes}
                    setTime={setTime}
                    date={date}
                  />
                </>
              )}
              {modalState.errorWindow && (
                <>
                  <ErrorWindow />
                </>
              )}
            </FlexBox>
          </FlexBox>

          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Продолжительность
            </Text>
            <FlexBox
              background="#fff"
              borderRadius="8px"
              direction="row"
              height="50px"
              align="center"
              justify="space-around"
            >
              <SuperButton
                varinat="counter"
                disabled={parseInt(minutes) <= 0 || disabledPrev}
                onClick={decrement}
              >
                <Text color="#fff" font="mont" size={10}>
                  -5
                </Text>
              </SuperButton>
              <Text color="#262626" font="mont" size={16}>
                {Math.trunc(parseInt(minutes) / 60)
                  .toString()
                  .padStart(2, "0")}
                :{(parseInt(minutes) % 60).toString().padStart(2, "0")}
              </Text>
              <SuperButton disabled = {disabledNext} onClick={counter} varinat="counter">
                <Text size={10} font="mont" color="#fff">
                  +5
                </Text>
              </SuperButton>
            </FlexBox>
            {errorMessage && (
              <Box position="absolute" bottom={"121px"}>
                {errorMessage}
              </Box>
            )}
          </FlexBox>
          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Комментарии
            </Text>
            <TextField
              textIndent="10px"
              height="85px"
              as={"textarea"}
              {...register("comments")}
            ></TextField>
          </FlexBox>
          <FlexBox
            marginTop="15px"
            direction="row"
            gap={12}
            height="50px"
            width="100%"
          >
            <SuperButton onClick={cancel} varinat="outlined">
              Отменить
            </SuperButton>
            <SuperButton type="submit" varinat="contained">
              Создать
            </SuperButton>
          </FlexBox>
        </Box>
      </Box>
    </>,
    document.body
  );
};

/*
autoFocus onBlur={() => setClientsList(false)}
*/
