import styled, { css } from "styled-components";

type propsType= {
    width: string | number,
    height: string | number,
    borderColor: string,
    opacity?:number,
    vertical?: boolean,
    
}

export const Border = styled.div<propsType>`
    width: ${props => props.width};
    height: ${props => props.height};
    border: 1px solid;
    border-color: ${props => props.borderColor};
    opacity: ${props => props.opacity ? props.opacity : 1};
    ${props => props.vertical && css<propsType>`
        height: 100%;
        width: 1px;
    `}
`