import { AxiosError, isAxiosError } from "axios"
import { apiNotes } from "../apiServer/apiNote"
import { setIsLoadingAC } from "./AppReducer"
import { logoutTC } from "./AuthReducer"
import { notesStateType } from "./NotesReducer"
import { thunkActionRootType } from "./storeRedux"

export type TimeTableStateType = {
    notesForWeek: notesStateType[]
}
export type actionsTimeTableType = setNotesForMonthAT


type setNotesForMonthAT = {
    type: "TIME-TABLE/NOTE-FOR-MONTH",
    notes: notesStateType[]
}
const initState:TimeTableStateType = {
    notesForWeek: []
}


export const TimeTableReducer = (state: TimeTableStateType  = initState, action: actionsTimeTableType):TimeTableStateType => {
    switch (action.type){
        case "TIME-TABLE/NOTE-FOR-MONTH":
            return {...state, notesForWeek: action.notes}
        default: 
            return state
    }
}

const setNotesForMonthAC = (notes:notesStateType[]):setNotesForMonthAT => {
    return {type: "TIME-TABLE/NOTE-FOR-MONTH", notes}
}


export const getNoteForMonthTC = (userId: string, start: string, end: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiNotes.getForWeek(userId, start, end)
        dispatch(setNotesForMonthAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}