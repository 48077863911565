import { useEffect,  useState } from "react"
import { Box } from "../styles/components/Box"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState } from "../store/storeRedux"
import { getWorkDaysTC } from "../store/CalendarReducer"
import { Table } from "../styles/components/Table"
import { TableRow } from "../styles/components/TableRow"
import { Column } from "../styles/components/Column"
import { fetchTeamMatesNotesTC, fetchTeamMatesTC } from "../store/TeamMateReducer"
import { stateUserType } from "../store/UserReducer"
import { TimeTableCellContainer } from "../styles/components/TimeTableCellContainer"
import { ButtonNote } from "../styles/components/ButtonNote"
import sprite from "../images/sprite.svg"
import { getNotesTC, notesStateType } from "../store/NotesReducer"
import { selectMasterAC, toggleSelectModeAC } from "../store/CommonReducers"
import { Note } from "./Note"
type propsType = {
    user: stateUserType,
    day: { date: string, day: moment.Moment }
    // showNoteWindow: (date: string, time: string, selectedMaster?: string) => void
}
type profileUserType = {
    _id: string,
    avatarUrl: string,
    firstName: string
}
const selectWorkDays = (state: AppRootState) => state.calendarState
const selectTeamMates = (state: AppRootState) => state.teamMates
const selectUserNotes = (state: AppRootState) => state.notes
export const TimeTableForDay = (props: propsType) => {

    const [btn, setBtn] = useState(false)
    const dispatch = useDispatch()
    const calendarState = useSelector(selectWorkDays)
    const teamMates = useSelector(selectTeamMates)
    const profileNotes = useSelector(selectUserNotes)
    const period = props.day.date.split(".").splice(1, 2).join(".")
    const [times, setTimes] = useState<Array<string>>([])

    const showBtn = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.currentTarget.childElementCount < 3) {
            setBtn(true)
        }
    }
    const hideBtn = () => setBtn(false)
    const newEntryHandler = (date: string, time: string, user: any | profileUserType) => {

        const master = { masterID: user._id, avatarUrl: user.avatarUrl, masterName: user.firstName }
        // const note = teamMates.notes.filter(el => el.date === date && el.owner === user._id && (parseInt(el.time) === parseInt(time) || parseInt(getEndSession(el.time, el.duration)) === parseInt(time) || parseInt(getEndSession(el.time, el.duration)) === parseInt(time) + 1))
        // const newNote = note.map(el => ({ start: convertTimeToNumber(el.time), end: el.duration }))
        // console.log("new",note)
        // console.log(parseInt(getEndSession("14:00","120")), parseInt("15:00") + 1)
        // props.showNoteWindow(date, time)
        // const newTime = parseInt(convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)))
        // console.log("time new", newTime)
        // props.showNoteWindow(date, newNote.length ? newTime === parseInt(time) ? convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)) : "Выберите время" : time, newNote.length ? (60 - Number(convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)).split(":")[1])).toString() : "60")
        dispatch(selectMasterAC(master))
        dispatch(toggleSelectModeAC(false))


    }
    // const renderPeriodTime = () => {

    //     if (calendarState.workTimeState[period] && calendarState.workTimeState[period].workDays.length > 0) {
    //         const state = [...calendarState.workTimeState[period].workDays].filter(el => el.date === props.day.date)
    //         let min = state.map(el => (Number(el.startWorkTime.split(":")[0]) * 60) + (Number(el.startWorkTime.split(":")[1]))).sort()[0]
    //         const max = state.map(el => (Number(el.endWorkTime.split(":")[0]) * 60) + (Number(el.endWorkTime.split(":")[1]))).sort().at(-1) as number
    //         const arr: string[] = []
    //         while (min <= max) {
    //             const el: string = Math.trunc(min / 60).toString() + ":" + (min % 60).toString().padStart(2, "0")
    //             arr.push(el);
    //             min += 60

    //         }
    //         setTimes(arr)
    //     }


    // }


    useEffect(() => {
        // renderPeriodTime()
        dispatch(fetchTeamMatesNotesTC(props.user.user?.id as string, props.day.date))

    }, [props.day.date])
    useEffect(() => {
        // dispatch(getWorkDaysTC(props.user.user?._id as string))
        dispatch(fetchTeamMatesTC(props.user.user?.id as string))
        // dispatch(getNotesTC(props.user.user?._id as string))
    }, [])
    return (
        <Box>
            <Table component="TimeTable">
                {
                    times.map((t, i) =>
                        <TableRow component="TimeTable" key={i} colors="other">
                            <Column component="TimeTable" >{t}</Column>

                            {
                                teamMates.teamMates.map(user =>
                                    <TimeTableCellContainer onMouseLeave={hideBtn} onMouseEnter={showBtn}>
                                        {/* {btn &&
                                            <ButtonNote onClick={() => newEntryHandler(props.day.date, t, user.user)} key={user.user._id + t}>
                                                <svg>
                                                    <use xlinkHref={`${sprite}#people`}></use>
                                                </svg>
                                            </ButtonNote>
                                        } */}
                                        {
                                            teamMates.notes.map((note, i) =>
                                                note.userId === user.user._id && parseInt(note.time) === parseInt(t)
                                                    ? <Note el={note}  />
                                                    : <></>
                                            )

                                        }

                                    </TimeTableCellContainer>

                                )
                            }
                            <TimeTableCellContainer onMouseLeave={hideBtn} onMouseEnter={showBtn}>
                                {/* {btn &&
                                    <ButtonNote onClick={() => newEntryHandler(props.day.date, t, { _id: props.user.user?._id as string, avatarUrl: props?.user?.user?.avatarUrl as string, firstName: props.user.user?.firstName as string })} key={props.user.user?._id + t}>
                                        <svg>
                                            <use xlinkHref={`${sprite}#people`}></use>
                                        </svg>
                                    </ButtonNote>
                                } */}
                                {
                                    profileNotes.map(el =>
                                        el.date === props.day.date && el.time === t
                                            ? <Note el={el} />
                                            : <></>
                                    )
                                }
                            </TimeTableCellContainer>



                        </TableRow>
                    )
                }
            </Table>

        </Box>
    )
}




/*

  const newEntryHandler = (date: string, time: string, user: usersType | profileUserType) => {

        const master = { masterID: user._id, avatarUrl: user.avatarUrl, masterName: user.firstName }
        const note = teamMates.notes.filter(el => el.date === date && el.owner === user._id && (parseInt(el.time) === parseInt(time) || parseInt(getEndSession(el.time, el.duration)) === parseInt(time) || parseInt(getEndSession(el.time, el.duration)) === parseInt(time) + 1))
        const newNote = note.map(el => ({ start: convertTimeToNumber(el.time), end: el.duration }))
        console.log("new",note)
        // console.log(parseInt(getEndSession("14:00","120")), parseInt("15:00") + 1)
        props.showNoteWindow(date, time)
        const newTime = parseInt(convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)))
        console.log("time new", newTime)
        // props.showNoteWindow(date, newNote.length ? newTime === parseInt(time) ? convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)) : "Выберите время" : time, newNote.length ? (60 - Number(convertNumberToTimeString(newNote.at(-1)?.start as number + Number(newNote.at(-1)?.end)).split(":")[1])).toString() : "60")
        dispatch(selectMasterAC(master))
        dispatch(toggleSelectModeAC(false))


    }
*/