import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import { SuperButton } from "../../styles/components/SuperButton"
import { TextField } from "../../styles/components/TextField"
import { Text } from "../../styles/components/Text"
import { theme } from "../../styles/components/Theme.styled"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { editWorkCategoryTC } from "../../store/ProsettingsReducer"
import { editWorkCategoryWindowAC } from "../../store/ModalWindowsReducer"

const selectModalState = (state:AppRootState) => state.ModalWindowReducer
export const EditWorkCategoryWindow = () => {
    const modalState = useSelector(selectModalState)
    const dispatch:thunkDispatchRootType = useDispatch()
    const [value,setValue] = useState<string>(modalState.editWorkCategoryState.category.category)
    const handler = (name: string) => setValue(name)
    const saveCategory = () => dispatch(editWorkCategoryTC(modalState.editWorkCategoryState.category.id, value))
    const hideModal = () => {
        dispatch(editWorkCategoryWindowAC({value: false, category: {id: "",category: ""}}))
    }
    return (
        <Box   direction="column" width="353px" height="max-content" padding="24px 32px" background={theme.colors.colorForm} borderRaduis="16px" boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)" position="fixed" top="50%" left="50%" transform="translateX(-50%) translateY(-50%)" zIndex={27} gap={20} >
                <Text color={theme.colors.fonts.main} weight={600} font="Inter" size={18}>Добавление категорий работ</Text>
                <FlexBox  gap={5} direction="column">
                    <Text color={theme.colors.fonts.main} size={16} font="mont">Название</Text>
                    <TextField placeholder="Введите название категории" value={value} onChange = { (e) => handler(e.currentTarget.value)} type="text" />
                    {/* {
                        error && <Text color={theme.colors.fonts.errors} font="mont" size={12}>{error}</Text>
                    } */}
                </FlexBox>
                
               
                <FlexBox gap={10} height="36px" direction="row" width="1005">
                    <SuperButton varinat="outlined" onClick={hideModal}>Отменить</SuperButton>
                    <SuperButton varinat="contained" onClick={saveCategory}>Сохранить</SuperButton>
                </FlexBox>
            </Box>
    )
}