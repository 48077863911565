import axios from "axios"
import { tokenService } from "./tokenService"
import { queueFormType } from "../apiTypes/formTypes/queueForm.type"
import { queueType } from "../apiTypes/root/queue.type"
import { apiAuth } from "./apiAuth"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/user-pro`
})


instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token)  config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
    
)
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );

export const apiQueue = {
    async create(dto: queueFormType){
        return instance.post<queueType>("/queue",dto)
    },
    async get(month: number, year: number) {
        return instance.get<queueType[]>(`/queue`, {
            params: {
                month,year
            }
        })
    },
    async getForMonth(year: number, month: number){
      return instance.get<queueType[]>("/queue/for-month",{
        params: {
          year, month
        }
      })
    },
    async deleteById(id: string){
      return instance.delete(`/queue/${id}`)
    },
    async reschedule(year: number, month: number, id: string,workServiceId: string){
      return instance.put(`/queue/reschedule/${id}`, {year,month,workServiceId})
    }
}