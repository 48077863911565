import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientsStateType, removeClientTC } from "../../store/ClientsReducer";
import { AppRootState } from "../../store/storeRedux";
import { showRemoveClientWindowAC } from "../../store/ModalWindowsReducer";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { FlexBox } from "../../styles/components/FlexBox";
import { Text } from "../../styles/components/Text";
import { SuperButton } from "../../styles/components/SuperButton";


const selectModal = (app: AppRootState) => app.ModalWindowReducer
export const RemoveCard = () => {
    const modalState = useSelector(selectModal)
    const { removeClientWindow: state } = modalState
    const dispatch = useDispatch()

    const cancel = () => {
        dispatch(showRemoveClientWindowAC({ value: false, clientID: "" }))
    }
    const removeClient = () => {
        dispatch(removeClientTC(state.clientID))
        dispatch(showRemoveClientWindowAC({ value: false, clientID: "" }))

    }
    return (
        <Box component="removeCard" width="453px" height="218px" background={theme.colors.colorForm} position="absolute" borderRaduis="8px" direction="row" top="33%" left="33%" zIndex={37}  padding = "24px 64px">
            <FlexBox direction="column">
                <FlexBox direction="column" gap={4}>
                    <Text font="mont" color={theme.colors.fonts.main} size={18}>
                        Вы уверены, что хотите удалить клиента?
                    </Text>
                    <Text font="mont" color={theme.colors.fonts.main} size={14}>
                        У вас не будет возможности восстановить эти даннные
                    </Text>

                </FlexBox>
                <FlexBox marginTop="12px" width="100%" direction="row" gap={12} height="48px">
                    <SuperButton onClick={removeClient} varinat="outlined">Удалить</SuperButton>
                    <SuperButton onClick={cancel}  varinat="contained">Отменить</SuperButton>
                </FlexBox>
            </FlexBox>

        </Box>
    )
}