


export const tokenService = {
    saveToken(token: string){
       const accesToken =  localStorage.setItem("token", token)
       return accesToken
    },
    getToken():string{
        const accessToekn = localStorage.getItem("token")
        const token = `Bearer ${accessToekn}`
        return token
    },
    removeToken(){
        localStorage.removeItem("token")
    }
}