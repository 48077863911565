import { ThunkAction } from "redux-thunk"
import { AppActionsType, AppRootState } from "./storeRedux"
import { apiTeamMates } from "../apiServer/apiTeamMates"
import { notesStateType } from "./NotesReducer"
import { setEnableSwitchAC, setErrorMessageTeamReqAC, setIsLoadingAC } from "./AppReducer"
import { apiNotification } from "../apiServer/apiNotification"
import { showCreateTeammateAC } from "./ModalWindowsReducer"


const initState: TeamMateStateType = {
    userID: "",
    teamMates: [],
    notes: [],
}

export const TeamMateReducer = (state: TeamMateStateType = initState, action: TeamMateAT): TeamMateStateType => {
    switch (action.type) {
        case "TEAM-MATE/ADD":
            return { ...state, teamMates: [...state.teamMates, action.teamMate] }
        case "TEAM-MATE/GET":
            return { ...state, teamMates: action.teamMates }
        case "TEAM-MATE/GET-NOTE":
            return { ...state, notes: action.notes }
        case "TEAM-MATE/CHANGE-RIGHT":
            const teamMate = state.teamMates.find(el => el.user._id === action.teamMateID)
            return {...state, teamMates: state.teamMates.map(el => el.user._id === teamMate?.user._id ? {...el, rights: el.rights.map(item => item._id === action.right._id ? action.right : item)} : el)}
        
        default:
            return state
    }
}



const getTeamMateAC = (teamMates: commonUsersType[]): getTeamMateAT => {
    return { type: "TEAM-MATE/GET", teamMates }
}
const getTeamMatesNotesAC = (notes: notesStateType[]): getTeamMatesNotesAT => {
    return { type: "TEAM-MATE/GET-NOTE", notes }
}
const changeTeamMateRighAC = (teamMateID: string, right:rightsType):changeTeamMateRightAT => {
    return {type: "TEAM-MATE/CHANGE-RIGHT", right, teamMateID}
}


export const fetchTeamMatesTC = (userId: string): ThunkAction<void, AppRootState, unknown, AppActionsType> => async dispatch => {
    const res = await apiTeamMates.getTeamMates(userId)
    dispatch(getTeamMateAC(res.data))
}

export const fetchTeamMatesNotesTC = (userID: string, date: string): ThunkAction<void, AppRootState, unknown, AppActionsType> => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiTeamMates.getTeamMatesNotes(userID, date)
        dispatch(getTeamMatesNotesAC(res.data.flat()))
    } catch (error) {

    } finally {
        dispatch(setIsLoadingAC(false))
    }

}
export const changeTeamMateRighTC = (teamMateID: string, right:rightsType):ThunkAction<void,AppRootState,unknown,AppActionsType> => async dispatch => {
    dispatch(setEnableSwitchAC(false))
    try {
        const res = await apiTeamMates.changeTeamMateRightValue(teamMateID, right)
        dispatch(changeTeamMateRighAC(res.data.teamMateID, res.data.right))
    } catch (error) {
        
    } finally {
        dispatch(setEnableSwitchAC(true))
    }
}
export const createTeamMateTC = (data: FormData, owner: string):ThunkAction<void,AppRootState,unknown,AppActionsType> => async dispatch => {
    try {
        const res = await apiNotification.createTeamMate(owner, data)
        dispatch(fetchTeamMatesTC(owner))
        dispatch(showCreateTeammateAC(false))
    } catch (error:any) {
        dispatch(setErrorMessageTeamReqAC(error?.response!.data.message))
    }
}
export type TeamMateStateType = {
    userID: string,
    teamMates: commonUsersType[],
    notes: notesStateType[],
}
export type commonUsersType = {user:any , rights: rightsType[]}
export type rightsType = {
    _id: string,
    name: "clients" | "timetable" | "settings" | "analytic",
    value: "A" | "B" | "C"
}
type addTeamMateAT = {
    type: "TEAM-MATE/ADD",
    teamMate: commonUsersType
}
type getTeamMateAT = {
    type: "TEAM-MATE/GET",
    teamMates: commonUsersType[]
}
type getTeamMatesNotesAT = {
    type: "TEAM-MATE/GET-NOTE",
    notes: notesStateType[]
}
type changeTeamMateRightAT = {
    type: "TEAM-MATE/CHANGE-RIGHT",
    right: rightsType,
    teamMateID: string
}

export type TeamMateAT = addTeamMateAT | getTeamMateAT | getTeamMatesNotesAT | changeTeamMateRightAT 

