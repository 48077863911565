import { useDispatch } from "react-redux"
import { queueType } from "../apiTypes/root/queue.type"
import c from "./timeTables.module.css"
import { thunkDispatchRootType } from "../store/storeRedux"
import { queueRescheduleWindowAC, setNewEntryFromQueueStateAC, showNewEntryAC } from "../store/ModalWindowsReducer"
import { removeQueueTC } from "../store/QueueReducer"

type propsType = {
    queues: queueType[]
}

export const Queue = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const showNewEntryFromQueue = (workServiceId: string, clientId: string,queueId: string) => {
        dispatch(showNewEntryAC({value: true, fromQueue: true}))
        dispatch(setNewEntryFromQueueStateAC({workServiceId,clientId,queueId}))
       }
    return (
        <div style={{ width: "100%", height: "100%", background: "#ffffff", color: "#fff" }}>
        {
            props.queues.map((q,i) => 
                <div key={q.client.id + i } style={{height: "8%", background: "#295CA3", color: "white", fontSize: "12px", padding: "5px", borderRadius: "10px", margin: "1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <div style={{display: "flex", justifyContent: "flex-start", width: "100%", gap: "3px"}}>
            <button onClick={() => dispatch(removeQueueTC(q.id))} className={c.close}></button>
            <button onClick={() => dispatch(queueRescheduleWindowAC({value: true, month: q.month, year: q.year,workServiceId: q.workService.name,clientId: q.client.id, id: q.id}))} className={c.folding}></button>
            <button onClick={() => showNewEntryFromQueue(q.workService.name,q.client.id,q.id)} className={c.unfolding} ></button>
            </div>
            <span style={{fontSize:"10px"}}>{`${q.client.surname} ${q.client.name}`}</span>
            <span style={{fontSize:"10px"}}>{q.client.phone}</span>
        </div>
                
            )
        }
        
       
    </div>
    )
}