import c from "./clientCard.module.css";
import plus from "../../assets/actions/plus.svg";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { changeQueuePageAC, getQueueForClientCardTC } from "../../store/ClientCardReducer";
import { FlexBox } from "../../styles/components/FlexBox";
import { theme } from "../../styles/components/Theme.styled";
import { Icon } from "../../styles/components/Icon";
import { Text } from "../../styles/components/Text";
import { timeTableHelper } from "../../utils/timeTable/timeTable-helper";
import { Box } from "../../styles/components/Box";

type propsType = {
  clientId: string;
  showQueue: () => void;
};

const selectClientCard = (state: AppRootState) => state.clientCard;
const selectQueueState = (state:AppRootState) => state.queueState
export const ClientQueue = (props: propsType) => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const clientCard = useSelector(selectClientCard);
  const queueState = useSelector(selectQueueState)
  const maxPage = Math.ceil(clientCard.queue.totalCount / clientCard.queue.pageSize)
  const {items: queues} = clientCard.queue
  const scrollRef = useRef(null)
  const onScroll = () => {
    if(scrollRef.current){
      const {scrollTop,scrollHeight,clientHeight} = scrollRef.current
      if((scrollTop + clientHeight) === scrollHeight){
        if(clientCard.queue.page < maxPage) dispatch(changeQueuePageAC(clientCard.queue.page + 1))
      } 
    }
  }
  useEffect(() => {
    if (props.clientId) {
      dispatch(getQueueForClientCardTC(props.clientId,clientCard.queue.page,clientCard.queue.pageSize));
    }
  }, [props.clientId,queueState.length, clientCard.queue.page]);
  console.log("queue",clientCard.queue.items)
  return (
    <div className={`${c.itemNot}`}>
      <div className={c.add}>
        <div onClick={props.showQueue} className={c.addContent}>
          <span>Очередь</span>
          <img src={plus} alt="" />
        </div>
      </div>
      <Box onScroll={onScroll} ref={scrollRef} overFlowY="scroll" padding="0 5px" gap={5} margin="10px 0 0 0" direction="column" width="100%" height="100%" >
        {queues.map((q,i) => (
          <FlexBox
            key={q.createdAt + i}
            padding="0 3px"
            borderRadius="8px"
            border="1px solid rgba(68, 68, 68, 0.2)"
            direction="row"
            width="100%"
            height="78px"
            background={theme.colors.colorForm}
          >
            <FlexBox align="center" direction="row" height="100%">
                <Icon svgID="grid-horizont" width={32} height={32} fill="#fff"/>
            </FlexBox>
            <FlexBox  padding="5px 0"  direction="column" width="100%" height="100%">
                <FlexBox align="center" justify="center" gap={3} direction="row" width="100%">
                    <Text font="mont" size={10} color={theme.colors.fonts.secondary}>{`от ${new Date(q.createdAt).toLocaleString("ru", {day: "2-digit", month: "long",year: "numeric"})}`}</Text>
                    <Icon svgID="arrow-right" width={22} height={22}/>
                    <Text weight={600} font="mont" size={11} color={theme.colors.fonts.main}>{`на ${timeTableHelper.getMonthFromNumber(q.month)}`}</Text>
                </FlexBox>
                <FlexBox direction="column" width="100%" height="100%" align="center" justify="center">

                    <Text color={theme.colors.fonts.main} size={12} font="mont">{`на проведение работы: `}</Text>
                    <Text color={theme.colors.fonts.main} weight={600} size={14} font="mont">{`${q.work}`}</Text>
                </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
      </Box>
    </div>
  );
};
