import { thunkActionRootType } from "./storeRedux";
import { setIsLoadingAC } from "./AppReducer";
import { clientBalanceFormType } from "../apiTypes/formTypes/clientBalanceForm.type";
import { apiClientPro } from "../apiServer/apiProClient";
import {
  clientBalanceWindowAC,
  clientWorkWindowAC,
  editClientWindowAC,
} from "./ModalWindowsReducer";
import { workClientRequestType } from "../apiTypes/formTypes/createWorkClient.type";
import {
  clientCardNotesType,
  clientCardType,
  queueClientCardType,
  workClientType,
} from "../apiTypes/root/clientPro.type";
import { apiNotes } from "../apiServer/apiNote";
import { commentClientType } from "./ClientsReducer";
import { editClientFormType } from "../apiTypes/formTypes/clientFormType";
import { AxiosError, isAxiosError } from "axios";
import { logoutTC } from "./AuthReducer";

const initClient: clientCardType = {
  id: "",
  owner: "",
  photoURL: "",
  name: "",
  surname: "",
  patronymic: "",
  phone: null,
  gender: "",
  birthday: null,
  agreement: false,
  addedDate: "",
  comments: [],
  balance: 0,
  workPhotos: [],
  visitCount: 0,
  spentMoneyCount: 0,
};
const iniState: clientCardStateType = {
  client: initClient,
  works: [],
  notes: {
    notes: [],
  },
  queue: {
    items: [],
    page: 1,
    pageSize: 4,
    totalCount: 0
  },
};
export type clientCardStateType = {
  client: clientCardType;
  works: workClientType[];
  notes: clientCardNotesType;
  queue: queueClientCardType;
};

export const ClientCardReducer = (
  state: clientCardStateType = iniState,
  action: clientCardAT
): clientCardStateType => {
  switch (action.type) {
    case "SET-CLIENT": {
      return { ...state, client: action.client };
    }
    case "CLIENT-CARD/CHANGE-BALANCE":
      return { ...state, client: { ...state.client, balance: state.client.balance + action.balance } };
    case "CLIENT-CARD/GET-WORKS":
      return { ...state, works: action.payload };
    case "CLIENT-CARD/SET-NOTES":
      return { ...state, notes: action.payload };
    case "CLIENT-CARD/GET-QUEUE":
      return { ...state, queue: {totalCount: action.payload.totalCount,page: action.payload.page,pageSize: action.payload.pageSize,items: action.payload.items}
        // {...state.queue, totalCount: action.payload.totalCount, page: action.payload.page, pageSize: action.payload.pageSize, items: [...state.queue.items, ...action.payload.items] } 
      };
    case "CLIENT-CARD/ADD-COMMENT":
      return {
        ...state,
        client: {
          ...state.client,
          comments: [action.comment, ...state.client.comments],
        },
      };
    case "CLIENT-CARD/ADD-PHOTO":
      return {
        ...state,
        client: {
          ...state.client,
          workPhotos: [action.url, ...state.client.workPhotos],
        },
      };
    case "CLIENT-CARD/EDIT-CLIENT":
      const {
        name,
        surname,
        patronymic,
        phoneNumber: phone,
        RadioGroup: gender,
        birthday,
        switch: agreement,
      } = action.payload;
      return {
        ...state,
        client: {
          ...state.client,
          name,
          surname,
          patronymic,
          phone,
          gender,
          birthday,
          agreement,
        },
      };
    case "CLIENT-CARD/QUEUE-CHANGE-PAGE":
      return {...state, queue: {...state.queue, page: action.page}}
    case "CLIENT-CARD/DEC-BALANCE":
      return {...state, client: {...state.client, balance: state.client.balance - action.balance}}
    case "CLIENT-CARD/RESET":
      return iniState
    default:
      return state;
  }
};

const setClintCardAC = (client: clientCardType): setClientType => {
  return { type: "SET-CLIENT", client };
};
const changeBalanceAC = (balance: number): changeBalanceAT => {
  return  {
    type: "CLIENT-CARD/CHANGE-BALANCE",
    balance,
  };
} 
const getWorksAC = (payload: workClientType[]): getClientWorksAT => ({
  type: "CLIENT-CARD/GET-WORKS",
  payload,
});
const setClientCardNotesAC = (
  payload: clientCardNotesType
): setClientCardNotesAT => ({ type: "CLIENT-CARD/SET-NOTES", payload });
const setQueueClientCardAC = (
  payload: queueClientCardType
): setQueueClientCardAT => ({ type: "CLIENT-CARD/GET-QUEUE", payload });
const addCommentAC = (payload: commentClientType): addCommentAT => ({
  type: "CLIENT-CARD/ADD-COMMENT",
  comment: payload,
});
const addPhotoAC = (url: string): addPhotoAT => {
  return { type: "CLIENT-CARD/ADD-PHOTO", url };
};
const editClientAC = (payload: editClientFormType): editClientAT => {
  return { type: "CLIENT-CARD/EDIT-CLIENT", payload };
};

const decBalanceAC = (balance: number):decBalanceAT => {
  return {type: "CLIENT-CARD/DEC-BALANCE",balance}
}

export const changeQueuePageAC = (page: number):changeQueuePageAT => {
  return {type: "CLIENT-CARD/QUEUE-CHANGE-PAGE",page}
}
export const resetClientCardAC = ():resetClientCardAT => {
  return {type: "CLIENT-CARD/RESET"}
}


export const setClientCardTC =
  (id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.getById(id);
      dispatch(setClintCardAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const changeClientBalanceTC =
  (data: clientBalanceFormType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.createBalance(data);
      dispatch(changeBalanceAC(data.balance));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(clientBalanceWindowAC({ value: false, client: null }));
      dispatch(setIsLoadingAC(false));
    }
  };

export const createWorkClientTC =
  (data: workClientRequestType, id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.createWork(data, id);
      dispatch(getClientWorksTC(id));
      dispatch(clientWorkWindowAC({ value: false, client: null }));
      dispatch(decBalanceAC(res.data.balance))
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getClientWorksTC =
  (clientId: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.getWorks(clientId);
      dispatch(getWorksAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const addClientCardPhotoTC =
  (
    file: File,
    contentType: string,
    fileName: string,
    clientId: string
  ): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.addPhotoClient(
        clientId,
        fileName,
        file,
        contentType
      );
      dispatch(addPhotoAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const addClientCommentTC =
  (clientId: string, comment: string): thunkActionRootType =>
  async (dispatch) => {
    try {
      const res = await apiClientPro.createComment(clientId, comment);
      dispatch(addCommentAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
    }
  };

export const getClientCardNotesTC =
  (clientId: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.getNotesForClientCard(clientId);
      dispatch(setClientCardNotesAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getQueueForClientCardTC =
  (clientId: string, page: number, pageSize: number): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.getQueueForClientCard(clientId,page,pageSize);
      dispatch(setQueueClientCardAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const editClientTC =
  (dto: editClientFormType, clientId: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiClientPro.editClient(dto, clientId);
      dispatch(editClientAC(dto));
      dispatch(editClientWindowAC({ value: false, client: null }));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export type clientCardAT =
  | setClientType
  | changeBalanceAT
  | getClientWorksAT
  | setClientCardNotesAT
  | setQueueClientCardAT
  | addCommentAT
  | addPhotoAT
  | editClientAT
  | changeQueuePageAT
  | resetClientCardAT
  | decBalanceAT

type setClientType = {
  type: "SET-CLIENT";
  client: clientCardType;
};
type resetClientCardAT = {
  type: "CLIENT-CARD/RESET"
}
type changeBalanceAT = {
  type: "CLIENT-CARD/CHANGE-BALANCE";
  balance: number;
};
type getClientWorksAT = {
  type: "CLIENT-CARD/GET-WORKS";
  payload: workClientType[];
};
type setClientCardNotesAT = {
  type: "CLIENT-CARD/SET-NOTES";
  payload: clientCardNotesType;
};
type setQueueClientCardAT = {
  type: "CLIENT-CARD/GET-QUEUE";
  payload: queueClientCardType;
};
type addCommentAT = {
  type: "CLIENT-CARD/ADD-COMMENT";
  comment: commentClientType;
};
type addPhotoAT = {
  type: "CLIENT-CARD/ADD-PHOTO";
  url: string;
};

type editClientAT = {
  type: "CLIENT-CARD/EDIT-CLIENT";
  payload: editClientFormType;
};

type changeQueuePageAT = {
  type: "CLIENT-CARD/QUEUE-CHANGE-PAGE",
  page: number
}

type decBalanceAT = {
  type: "CLIENT-CARD/DEC-BALANCE",
  balance: number
}


