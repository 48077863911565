import { AxiosError, isAxiosError } from "axios"
import { apiReminders } from "../apiServer/apiReminders"
import { clientReminderFormType } from "../apiTypes/formTypes/clientReminderFormType"
import { remindersStateType } from "../apiTypes/root/reminders.type"
import { setIsLoadingAC } from "./AppReducer"
import { remindWindowAC } from "./ModalWindowsReducer"
import { thunkActionRootType } from "./storeRedux"
import { logoutTC } from "./AuthReducer"



export type rootRemindersStateType = {
    clientReminders: remindersStateType[]
}
export type remindersAT = addReminderClientAT | getReminderClientAT
type addReminderClientAT = {
    type: "REMIND/ADD-REMIND-CLIENT",
    payload: remindersStateType
    
}
type getReminderClientAT = {
    type: "REMIND/GET-REMIND-CLIENT",
    payload: remindersStateType[]
    
}

const initState:rootRemindersStateType = {
    clientReminders: []
}

export const RemindersReducer = (state: rootRemindersStateType = initState, actions: remindersAT):rootRemindersStateType => {
    switch(actions.type){
        case "REMIND/ADD-REMIND-CLIENT":
            return {...state, clientReminders: [...state.clientReminders, actions.payload]}
        case "REMIND/GET-REMIND-CLIENT":
            return {...state, clientReminders: actions.payload}
        default:
            return state
    }
}

const addClientRemindAC = (payload: remindersStateType):addReminderClientAT => ({type: "REMIND/ADD-REMIND-CLIENT",payload})
const getClientsRemindersAC = (payload: remindersStateType[]):getReminderClientAT => ({type: "REMIND/GET-REMIND-CLIENT",payload})

export const addReminderForClientTC = (data: clientReminderFormType):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiReminders.createForClient(data)
        dispatch(addClientRemindAC(res.data))
        dispatch(getReminderClientTC(data.clientId))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(remindWindowAC({value: false, client: null}))
        dispatch(setIsLoadingAC(false))
    }
}

export const getReminderClientTC = (clientId: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiReminders.getForClient(clientId)
        dispatch(getClientsRemindersAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}

