

import axios from "axios";
import { tokenService } from "./tokenService";
import { apiAuth } from "./apiAuth";
import { adminClientType } from "../apiTypes/root/admin.type";



const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/sa`,
    withCredentials: true,
   
})


instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
        
    },
    (error) => Promise.reject(error)
)
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
           
        }
      } else {
        
      }
      return Promise.reject(error);
    }
  );

export const apiAdmin = {
   
    getClients(){
       return instance.get<Array<adminClientType>>("/users", {
       })
    },
    incBalance(userId: string, balance: number){
        return instance.put("/users/balance",{userId,balance})
    }
   
   
    
}