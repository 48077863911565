import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import {  createCategoryAC, createWorkWindowAC } from "../store/ModalWindowsReducer"
import { stateUserType, tariffType } from "../store/UserReducer"

import { FlexBox } from "../styles/components/FlexBox"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"
import { SuperButton } from "../styles/components/SuperButton"
import { Icon } from "../styles/components/Icon"
import { OpacityLayer } from "../styles/components/OpacityLayer"
import { DropDownContainer } from "../styles/components/DropDownContainer"
import { DropDownHeader } from "../styles/components/DropDownHeader"
import { ListItem } from "../styles/components/ListItem"
import { DropDownListContainer } from "../styles/components/DropDownListContainer"
import {  NavLink, Outlet, useLocation } from "react-router-dom"
import c from "./proSettings.module.css"
import { getWorkCategoriesTC, setFindWorkKeyAC } from "../store/ProsettingsReducer"

const selectProSettings = (state:AppRootState) => state.proSettings

export const ProSettings = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const location = useLocation()
    const proSettings = useSelector(selectProSettings)
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const [searchMode, setSearchMode] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>("")
    const [options, setOptions] = useState<boolean>(false)
    
    const permisTeamBtn = user.user?.tariff === tariffType.SuperPRO
    const linkDisabled = (val: boolean,e:React.MouseEvent<HTMLAnchorElement, MouseEvent>):void => {
        if(!val)  e.preventDefault()
        // e.preventDefault()
    }
    const defaultSearchValue = "Категория"
    const searchWorkHandler = (value: string | null) => {
        dispatch(setFindWorkKeyAC(value))
    }
    useEffect(() => {
        if (!proSettings.workCategories.length) dispatch(getWorkCategoriesTC())
    }, [])

    
    return (
        <FlexBox direction="column" width="100%" height="100%" >
            {
                searchMode && <OpacityLayer onClick={() => setSearchMode(prev => !prev)}/>
            }
            
           

           <FlexBox direction="row" width="100%" >
                <Text size={18} color={theme.colors.fonts.main} font="Inter" weight={600}>Настройки</Text>
           </FlexBox>
           <FlexBox gap={10} padding="0 10px" align="center" direction="row" width="100%" marginTop="20px" height="64px" justify="space-around" background={theme.colors.colorForm} borderRadius="8px">
            {
                searchMode
                ? <FlexBox padding="0 15px" style={{position: "relative",zIndex: 27}} direction="row" width="100%" height="36px" border="1px solid #4444" borderRadius="8px" justify="space-between" align="center" background="#fff">
                    <Icon svgID="search" width={18} transform="scale(1.2)" height={12}/>
                    <Text color={theme.colors.fonts.main} size={12} font="mont">Найдем нужное</Text>
                    <DropDownContainer>
                            <DropDownHeader svgHover="#000" hover="#f4f6fa" height={24} onClick={() => setOptions(!options)}>
                                <Text size={12} color={theme.colors.fonts.main} font="mont">{searchValue || defaultSearchValue}</Text>
                                <Icon svgID="row" width={16} height={3} />
                            </DropDownHeader>
                            {options &&
                                <DropDownListContainer>
                                    <>
                                    {proSettings.workCategories.map(el =>
                                        <ListItem key={el.id} >
                                            <button key={new Date().getTime().toString() + el} onClick={() => { setSearchValue(el.category);searchWorkHandler(el.category) ;setOptions(false) }}> {el.category}</button>
                                        </ListItem>

                                    )}
                                   {
                                    searchValue !== defaultSearchValue &&
                                        <ListItem key={new Date().getTime().toString()} >
                                        <button key={new Date().getTime().toString()} onClick={() => { setSearchValue(defaultSearchValue); searchWorkHandler(null);setOptions(false) }}> {defaultSearchValue}</button>
                                    </ListItem>
                                   } 
                                    </>
                        
                                </DropDownListContainer>
                            }
                        </DropDownContainer>
                </FlexBox>
                : <>
                    <SuperButton onClick={() => setSearchMode(prev => !prev)}  maxWidth="60px" height="36px" varinat="search">
                        <Icon svgID="search" width={18} transform="scale(1.2)" height={12}/>
                    </SuperButton>
                    <NavLink  className={(state) => state.isActive ? `${c.activeLink} ${c.link}` : `${c.link}`}  to={"priceList"}>Прайс-лист</NavLink>
                    <NavLink className={(state) => state.isActive ? `${c.activeLink} ${c.link}` : `${c.link}`}  to={"vidzhet"}>Виджет</NavLink>
                    <NavLink onClick={(e) => linkDisabled(permisTeamBtn,e)} className={(state) => state.isActive ? `${c.activeLink} ${c.link}` : `${c.link}`}  to={"team"}>Команда</NavLink>
                    <NavLink className={(state) => state.isActive ? `${c.activeLink} ${c.link}` : `${c.link}`}  to={"workcategory"}>Категории</NavLink>
                    <SuperButton onClick={() => dispatch(createCategoryAC(true))}  maxWidth="180px" hover hoverColor={theme.colors.fonts.main}  height="36px" varinat="contained">
                          <Text font="mont" color="#fff" size={12}>Добавить категорию работ</Text>
                    </SuperButton>
                </>
            }
                <FlexBox style={{position: "relative", zIndex: 27}} direction="row" width="180px">
                {
                        location.pathname === '/proSettings/team'
                        ? <SuperButton hover hoverColor={theme.colors.fonts.main} varinat="contained"   height="36px">
                            <Text font="mont" color="#fff" size={12}>Добавить сотрудника</Text>
                        </SuperButton>
                        : <SuperButton onClick={() => dispatch(createWorkWindowAC(true))} hover hoverColor={theme.colors.fonts.main}  height="36px" varinat="contained">
                          <Text font="mont" color="#fff" size={12}>Добавить работу</Text>
                        </SuperButton>
                    }
                </FlexBox>
           </FlexBox>
            <FlexBox  direction="row" height="100%" width="100%">
                <Outlet/>
            </FlexBox>
        </FlexBox>

    )
}