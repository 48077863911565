import styled, { css } from "styled-components";
import { tariffType } from "../../store/UserReducer";
import { theme } from "./Theme.styled";

type propsType = {
    tariff: tariffType,
    active?:boolean
}
export const TariffCard = styled.div<propsType>`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    border-radius: 16px;
    height: 550px;
    padding: 0 30px;
   ${props => props.active && css<propsType>`
        transform: translateY(-40px) !important;
        transition: 1s;
   `};
   ${props => !props.active && css<propsType>`
        top: 0;
    
   `};
  
    
    justify-content: space-around;
     ${props => props.tariff && css<propsType>`
        background: ${props => props.tariff === tariffType.FREE ? theme.colors.main : props.tariff === tariffType.PRO ? theme.colors.colorForm : theme.colors.fonts.main};
    `};
   
    
    @media screen and (max-width: 1545px) {
       padding: 0 35px;
    };
    @media screen and (max-width: 1490px) {
       padding: 0 30px;
       height: 480px;
    };
    @media screen and (max-width: 1345px) {
       height: 470px
    };
    @media screen and (max-width: 1290px) {
       padding: 0 20px;
       height: 420px;
    };
    @media screen and (max-width: 1200px) {
       padding: 0 10px;
    };
    @media screen and (max-width: 1140px) {
       height: 380px;
    };
    @media screen and (max-width: 768px) {
       width: 230px;
       height: 320px;
       min-height:320px;
       margin: 0px auto; 
    };
`