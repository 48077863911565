import React, { useEffect,  useState } from "react";

import {  useForm } from "react-hook-form";
import { addClientTC, clientsStateType } from "../../store/ClientsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState } from "../../store/storeRedux";
import { showNewClientWindowAC } from "../../store/ModalWindowsReducer";
import { Icon } from "../../styles/components/Icon";
import { FormBox } from "../../styles/components/FormBox";
import { Text } from "../../styles/components/Text";
import { theme } from "../../styles/components/Theme.styled";
import { FormInputContainer } from "../../styles/components/FormInputContainer";
import { TextField } from "../../styles/components/TextField";
import PhoneInput from "react-phone-input-2";
import { clientFormType } from "../../apiTypes/formTypes/clientFormType";
import { FlexBox } from "../../styles/components/FlexBox";
import { RadioInput } from "../../styles/components/Radio";
import { CheckBox } from "../../styles/components/CheckBox";
import { SuperButton } from "../../styles/components/SuperButton";
import { Avatar } from "../../styles/components/Avatar";


const selectModalState = (state: AppRootState) => state.ModalWindowReducer
export const NewClient = () => {

    const modalState = useSelector(selectModalState)
    const { register, handleSubmit, formState: { errors }, watch, getValues, setValue, setError, clearErrors } = useForm<clientFormType>()
    const [photoUrl, setPhotoUrl] = useState<any>()
    const { newClientWindow: state } = modalState
    const onSubmit = (data: clientFormType) => {
        if (!data.RadioGroup) {
            setError("RadioGroup", { message: "Выберите пол" })
            return
        }
        if (!data.phoneNumber) {
            setError("phoneNumber", { type: "required", message: "Введите телефон" })
            return
        }

        const existClient = state.clients.find(cl => cl.phone === data.phoneNumber)
        if (existClient) {
            setError("phoneNumber", { message: "Клиент с таким телефоном уже существует" })
            return
        }
        dispatch(addClientTC(data.avatar[0], (data.name[0].toUpperCase() + data.name.slice(1)), (data.surname[0].toUpperCase() + data.surname.slice(1)), (data.patronymic ? data.patronymic[0].toUpperCase() + data.patronymic.slice(1) : ''), data.phoneNumber, data.RadioGroup, data.birthday ? new Date(data.birthday) : new Date(), data.switch))
        

    }







    const dispatch = useDispatch()
    const [calendar, setCalendar] = useState(false)

    const exit = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(showNewClientWindowAC({ value: false, userID: "", clients: [] }))

    }

    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        if(fileReader.result){
            setPhotoUrl(fileReader.result)
        }
       
    }
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            if (type === 'change') {
                clearErrors()
            }
            if (name === "RadioGroup") {
                clearErrors("RadioGroup")
            }
            if (name === "phoneNumber") {
                clearErrors("phoneNumber")
            }
           
            if (value.avatar[0] && name ==="avatar") {
                fileReader.readAsDataURL(value.avatar[0])

            }
        })

        return () => subscription.unsubscribe();
    }, [watch])
    return (

        <FormBox component="newClient" onSubmit={handleSubmit(onSubmit)} as={"form"} width="453px" padding="24px 64px" gap={12}>
            <Text size={16} font="mont" color={theme.colors.fonts.main}>Создать нового клиента</Text>
            {
                !photoUrl
                    ? <SuperButton as={"label"} varinat="photo">
                        <Icon svgID="camera" width={24} height={24} />
                        <input  {...register("avatar")} type="file" />
                    </SuperButton>
                    : <label>
                        <Avatar size="userAvatar" src={photoUrl} />
                        <input  {...register("avatar")} type="file" />
                    </label>
            }

            <FormInputContainer>
                <Text font="mont" size={14} color={theme.colors.fonts.main}>Фамилия</Text>
                <TextField transform="capitalize" {...register("surname", { required: { value: true, message: "Обязательное поле" }, maxLength: { value: 20, message: "Фамилия обязательное поле не более 20 символов" } })} placeholder="Фамилия" />
                <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.surname?.message}</Text>
            </FormInputContainer>
            <FormInputContainer>
                <Text font="mont" size={14} color={theme.colors.fonts.main}>Имя</Text>
                <TextField transform="capitalize" {...register("name", { required: { value: true, message: "Обязательное поле" }, maxLength: { value: 15, message: "Имя обязательное поле не более 20 символов" } })} placeholder="Имя" />
                <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.name?.message}</Text>
            </FormInputContainer>
            <FormInputContainer>
                <Text font="mont" size={14} color={theme.colors.fonts.main}>Отчество</Text>
                <TextField transform="capitalize" {...register("patronymic")} placeholder="Отчество" />
            </FormInputContainer>
            <FormInputContainer>
                <Text font="mont" size={14} color={theme.colors.fonts.main}>Телефон</Text>
                <PhoneInput
                    inputStyle={{ height: '50px' }}
                    onlyCountries={['by', 'ru']}
                    country={'ru'}
                    value={getValues("phoneNumber")}
                    onChange={(v, f, e) => { if(e.target.value) setValue("phoneNumber", e.target.value) }}
                />
                <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.phoneNumber?.message}</Text>
            </FormInputContainer>
            <FlexBox justify="center" align="center" width="100%" direction="row">
                <FlexBox gap={10} width="100%" direction="row">
                    <RadioInput child={<input name="RadioGroup" onChange={() => setValue("RadioGroup", "male")} type="radio" />} label="М" />
                    <RadioInput child={<input name="RadioGroup" onChange={() => setValue("RadioGroup", "female")} type="radio" />} label="Ж" />

                </FlexBox>
                <FlexBox width="100%" direction="row" >
                    {
                        !calendar
                            ? <TextField style={{ display: "flex", gap: "8px", padding: "0 10px" }} onClick={() => setCalendar(true)} width="100%" as={"div"}>
                                {!getValues("birthday") && <Icon svgID="calendar" width={16} height={16} />}
                                {
                                    getValues("birthday")
                                        ? <Text size={14} color={theme.colors.fonts.main} font="mont">{ new Date((getValues("birthday"))).toLocaleDateString()}</Text>
                                        : <Text size={14} color={theme.colors.fonts.main} font="mont">День рождения</Text>
                                }


                            </TextField>
                            : <TextField max="2999-12-31" date-role="datebox" autoFocus  placeholder="Дата рождения" width="100%"  {...register('birthday', {onBlur: () => setCalendar(false)})} type="date" />
                    }

                </FlexBox>
            </FlexBox>
            {errors.RadioGroup?.message && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.RadioGroup?.message}</Text>}

            <FlexBox marginTop="12px" width="100%" justify="start" align="center" gap={10} direction="row">
                <CheckBox variant="switch" render={() => <input {...register("switch")} type="checkbox" />} value={watch("switch")} />
                <Text color={theme.colors.fonts.main} font="mont" size={14}>Согласие на SMS-уведомления</Text>
            </FlexBox>
            <FlexBox marginTop="12px" width="100%" direction="row" gap={12} height="48px">
                <SuperButton onClick={exit} varinat="outlined">Отменить</SuperButton>
                <SuperButton type="submit" varinat="contained">Создать</SuperButton>
            </FlexBox>
        </FormBox>
    )
}