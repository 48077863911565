import { FlexBox } from "../styles/components/FlexBox"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"



export const Vidzhet = () => {
    return(
        <FlexBox align="center" justify="center" direction="row" width="100%" height="100%">
            <Text color={theme.colors.fonts.main} font="mont" size={15}>Страница в разработки</Text>
             </FlexBox>
    )
}