import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import girl from "../assets/pictures/girl.png"
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux';
import { clientsStateType, getClientsTC, setClientsAC } from '../store/ClientsReducer';
// import { Sort } from './Sort';
import { searchStateType } from "../store/SearchReducer";
import { getNotesTC, notesStateType } from "../store/NotesReducer";
import { stateUserType, tariffType } from "../store/UserReducer";
// import { EditCard } from "./EditCard";
import { ModalWindowStateType, editCardAC, selectClientAC, setDateAndTimeAC, showNewClientWindowAC, showNewEntryAC, showPayWindowAC, showRemoveClientWindowAC, showadminPayWindowAC } from "../store/ModalWindowsReducer";
import { sessionsType } from "../store/SessionsReducer";
import theMan from "../assets/male.png"
import { NavLink } from "react-router-dom";
import { Icon } from "../styles/components/Icon";
import { Avatar } from "../styles/components/Avatar";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { SuperButton } from "../styles/components/SuperButton";
import { TextField } from "../styles/components/TextField";
import { Box } from "../styles/components/Box";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { TableRow } from "../styles/components/TableRow";
import { subDays } from "date-fns";
import { DeviceView } from "../store/AppReducer";
import { AdminStateType, getAdminClientsTC } from "../store/AdminReducer";
import { adminClientType } from "../apiTypes/root/admin.type";
import { appUtils } from "../utils/utils";
import { SortUsersWindow } from "./SortUsersWindow";


const selectAppState = (state: AppRootState) => state.appState
const selectAdmin = (state:AppRootState) => state.admin

const AdminPageComponent = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const appState = useSelector(selectAppState)
    const adminState = useSelector(selectAdmin)
    const [clients, setClients] = useState<Array<adminClientType>>(adminState.clients)

    const [sort, setSort] = useState(false)

    // const showNewClientWindow = () => dispatch(showNewClientWindowAC({ value: true, clients, userID: props.userId as string }))


   

   

useEffect(() => {
    dispatch(getAdminClientsTC())
    if(adminState.clients.length) setClients(adminState.clients)
},[adminState.clients.length, JSON.stringify(adminState.clients)])



  
const searchClients = (e: ChangeEvent<HTMLInputElement>) => {
    setClients(adminState.clients.filter(cl => (cl.lastName.toLowerCase().includes(e.currentTarget.value.toLowerCase()) || cl.phoneNumber.includes(e.currentTarget.value))))
}
    
    return (
        <FlexBox direction="column" height="100%" width="100%">

             <div style={{ position: "relative" }}>
                {sort && <SortUsersWindow state={adminState.clients} setClients={setClients} sort={sort} setSort={setSort} />}

            </div> 

            <FlexBox direction="column" height="100%" width="100%">
                <Text className="mainPageTitle" size={18} color={theme.colors.fonts.main} font="mont">Клиенты</Text>
                <FlexBox direction="row" background={theme.colors.colorForm} width="100%" marginTop="12px" padding="12px" justify="space-between">
                    <FlexBox className="clientStatsContainer" direction="row" height="100%" align="center" gap={8}>
                        <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Всего: {adminState.clients.length}</FlexBox>
                        {/* <FlexBox className="clientStats" width="120px" height="100%" direction="row" background="#fff" borderRadius="8px" align="center" justify="center">Новых: {newClientCount}</FlexBox> */}
                        <SuperButton className="sort" active={sort ? true : false} varinat="sort" onClick={(e) => setSort(prev => !prev)}>
                            <Icon svgID="sort" width={16} height={16} />
                        </SuperButton>
                        <Box className="clientSearch" width="max-content" component="search" position="relative">
                            <Icon svgID="search" width={13} height={12} />
                            <TextField width="283px" height="44px" kind="searchClient" onChange={searchClients} placeholder='Найти клиентов' type="text" />
                        </Box>
                    </FlexBox>
                    {/* <SuperButton className="newClientBtn" minWidth="200px" maxWidth="160px" onClick={() => showNewClientWindow()} varinat="add">
                        <Icon width={20} height={13} svgID="blMan" />
                        <span>Новый клиент</span>
                    </SuperButton> */}
                </FlexBox>
                <FlexBox direction="column" width="100%" height="100%" gap={16}>
                    <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">ФИО</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Телефон</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Тариф</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Кол-во сотрудников</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Кол-во клиентов</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Добавлен</Text>
                        </Column>
                        <Column className="payment" maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Оплачено</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Баланс</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Действие</Text>
                        </Column>
                        
                    </TableHeader>
                    <Box height="100%" width="100%" position="relative">
                        {/* {modalState.editCard && <EditCard setClients={setClients} editedClient={editedClient} />} */}
                        <Box gap={12} overFlowY="auto" position="absolute" width="100%" height="100%">
                            {
                               clients.map(cl =>
                                    <>
                                    
                                        <TableRow minHeight="64px" key={cl.id} component="Clients">
                                            <FlexBox gap={5} direction="row" align="center" justify="center" basis="100%">
                                                <FlexBox direction="row" basis="50%" align="center" justify="center">
                                                    
                                                        
                                                          
                                                                 {cl.avaUrl ? <Avatar size="newEntry" src={cl.avaUrl} /> :  <Avatar size="newEntry" src={theMan} />  }
                                                             
                                                            
                                                    
                                                </FlexBox>
                                                <FlexBox align="start" width="100%" direction="column">
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.lastName}</Text>
                                                    <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.firstName}</Text>
                                                    {/* <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.}</Text> */}
                                                </FlexBox>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.phoneNumber}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.tariff}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.teammateCount}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{cl.clientsCount}</Text>
                                            </FlexBox>
                                            <FlexBox className="payment" direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{new Date(cl.createdAt).toLocaleDateString()}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{appUtils.getMoneyStr(cl.paidFor)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{appUtils.getMoneyStr(cl.balance)}</Text>
                                            </FlexBox>
                                            
                                            <FlexBox direction="row" align="center" justify="center" basis="100%" gap={8}>
                                                {/* <SuperButton varinat="sort" onClick={(e) => editHandler(cl)}>
                                                    <Icon svgID="edit" width={18} height={16} />
                                                </SuperButton>
                                                <SuperButton varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                    <Icon svgID="delete" width={18} height={16} />
                                                </SuperButton> */}
                                                <SuperButton onClick={() => dispatch(showadminPayWindowAC({value: true, userId: cl.id}))} varinat="sort">
                                                <Icon fill="#fff" svgID="card" width={24} height={24} />
                                                </SuperButton>
                                            </FlexBox>
                                        </TableRow>
                                        {/* {
                                            appState.deviceView === DeviceView.MOBILE &&
                                            <FlexBox height="64px" padding="5px" gap={10} background={theme.colors.colorForm} marginTop="5px" direction="row" className="mobileClientRow">
                                                <Avatar size="large" src={cl.photoURL} />
                                                <FlexBox direction="column" gap={3}>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{`${cl.surname} ${cl.name} ${cl.patronymic}`}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>{cl.phone}</Text>
                                                    <Text font="mont" color={theme.colors.fonts.main} size={12}>Баланс: ****</Text>
                                                </FlexBox>
                                                <FlexBox style={{ marginLeft: "auto" }} direction="row" align="center" justify="center" gap={5}>
                                                    <SuperButton varinat="sort" onClick={(e) => editHandler(cl)}>
                                                        <Icon svgID="edit" width={18} height={16} />
                                                    </SuperButton>
                                                    <SuperButton varinat="sort" onClick={(e) => removeClient(cl.id)}>
                                                        <Icon svgID="delete" width={18} height={16} />
                                                    </SuperButton>
                                                </FlexBox>
                                            </FlexBox>
                                        } */}
                                    </>

                                )
                            }
                        </Box>
                    </Box>

                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}

export const AdminPage = React.memo(AdminPageComponent)