import { useForm } from "react-hook-form";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { Text } from "../../styles/components/Text";
import { FormInputContainer } from "../../styles/components/FormInputContainer";
import { FlexBox } from "../../styles/components/FlexBox";
import { CustomSelect } from "../../styles/components/CustomSelect";
import { Icon } from "../../styles/components/Icon";
import { Select } from "../../styles/components/Select";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { SuperButton } from "../../styles/components/SuperButton";
import { useEffect, useState } from "react";
import { TextField } from "../../styles/components/TextField";
import { clientWorkWindowAC } from "../../store/ModalWindowsReducer";
import CurrencyInput from "react-currency-input-field";
import { workServiceType } from "../../apiTypes/root/proSettings.type";
import { createWorkClientFormType } from "../../apiTypes/formTypes/createWorkClient.type";
import { SimpleCalendarWindow } from "./SimpleCalendarWindow";
import { createWorkClientTC } from "../../store/ClientCardReducer";


const selectProSettings = (state: AppRootState) => state.proSettings;
const selectUser = (state: AppRootState) => state.user;
const selectClientCard = (state:AppRootState) => state.clientCard
export const WorkClientWindow = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const proSettings = useSelector(selectProSettings);
  const user = useSelector(selectUser);
  const clientCard = useSelector(selectClientCard)
  const [profit, setProfit] = useState<number>(0);
  const [minutes, setMinutes] = useState("60");
  const [works, setWorks] = useState<Array<workServiceType>>([]);
  const [calendar, showCalendar] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const { handleSubmit, setValue, register, watch, getValues } =
    useForm<createWorkClientFormType>({
      defaultValues: {
        userId: user.user?.id,
        sale: 0,
      },
    });
  const counter = (e: any) => {
    e.preventDefault();
    setMinutes((prev) => (Number(prev) + 5).toString());
  };
  const decrement = (e: any) => {
    e.preventDefault();
    setMinutes((parseInt(minutes) - 5).toString());
  };
  const cancel = (e: any) => {
    e.preventDefault();
    dispatch(clientWorkWindowAC({ value: false, client: null }));
  };
  const saleInputHandler = () => {};
  const defineDate = (e: any) => {
    e.preventDefault();
    showCalendar(true);
  };
  const cost = works.reduce((prev, currr) => prev + currr.cost, 0) - getValues("sale");
  const onSubmit = (data: createWorkClientFormType) => {
    dispatch(createWorkClientTC({...data, servicesId: works.map(w => w.id)}, clientCard.client.id))
  };

  useEffect(() => {
    const isExist = works.find((w) => w.id === watch("servicesId"));
    const work = proSettings.workService.find(
      (w) => w.id === watch("servicesId")
    );
    if (work && isExist === undefined) {
      setWorks((prev) => [...prev, work]);
    }
  }, [watch("servicesId")]);

  useEffect(() => {
    if (works.length) {
      setProfit(
        works.reduce((prev, curr) => prev + curr.profit, 0)
      );
    }

  }, [works.length, watch("sale")]);

  useEffect(() => {
    if (minutes) setValue("spentTime", minutes);
    if (profit) setValue("totalProfit", profit);
    if (cost) setValue("totalCost", cost);
    if(date) setValue("date",new Date(date.toLocaleDateString().split(".").reverse().join("-")).toISOString())
  }, [minutes, profit, cost, date]);

  return (
    <Box
      overFlowY="auto"
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
      direction="column"
      width="453px"
      height="650px"
      padding="24px 52px"
      background={theme.colors.colorForm}
      borderRaduis="16px"
      boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)"
      position="fixed"
      top="50%"
      left="50%"
      transform="translateX(-50%) translateY(-50%)"
      zIndex={27}
      gap={15}
    >
      {calendar && (
        <SimpleCalendarWindow
          setDate={setDate}
          showCalendar={showCalendar}
          inModal
        />
      )}
      <Text color="black" font="mont" size={14}>
        Проведение работ -услуг
      </Text>
      <FormInputContainer>
        <Text color="black" font="mont" size={12}>
          Специалист, Дата и время
        </Text>
        <FlexBox direction="row" height="50px">
          <CustomSelect />
          <FlexBox
            onClick={defineDate}
            width="100%"
            background="#fff"
            direction="row"
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
            align="center"
            justify="space-around"
          >
            {date ? (
              <Text color="black" font="mont" size={13}>
                {date.toLocaleDateString()}
              </Text>
            ) : (
              <>
                <Icon svgID="calendar2" width={16} height={16} />
                <Text color={theme.colors.fonts.main} font="mont" size={14}>
                  Дата
                </Text>
              </>
            )}
          </FlexBox>
          {/* <FlexBox
            width="94px"
            background="#fff"
            direction="row"
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
            align="center"
            justify="space-around"
          >
            <Icon svgID="clock" width={22} height={22} transform="scale(0.7)" />
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Время
            </Text>
          </FlexBox> */}
          <TextField {...register("time")} type="time" />
        </FlexBox>
      </FormInputContainer>
      <FormInputContainer>
        <Text color={theme.colors.fonts.main} size={12} font="mont">
          <FlexBox direction="column" gap={8} width="100%">
            <Text color={theme.colors.fonts.main} size={15} font="mont">
              Услуга
            </Text>
            <Select
              isClear
              setValue={setValue}
              name="servicesId"
              state={proSettings.workService}
              placeholder="Выберите услугу"
              tooltip="Для тарифов PRO и PRO+"
            />
            {/* <Text color={theme.colors.fonts.errors} size={12} font="mont">{errors.service?.message as string}</Text> */}
            {works.map((w) => (
              <TextField as={"div"} key={w.id}>
                <Text
                  color="black"
                  size={14}
                  font="mont"
                >{`${w.name} - ${w.cost}р.`}</Text>
              </TextField>
            ))}
          </FlexBox>
        </Text>
      </FormInputContainer>
      <FormInputContainer>
        <Text color="black" font="mont" size={14}>
          Полная стоимость - Скидка
        </Text>
        <FlexBox direction="column">
          <FlexBox direction="row" width="100%" height="50px">
            <TextField width="100%" as={"div"}>
              <Text color="black" font="mont" size={12}>
                {`Стоимость ${cost}р.`}
              </Text>
            </TextField>
          </FlexBox>
          <FlexBox height="50px" width="100%" direction="row">
            <CurrencyInput
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                border: "1px solid rgba(228, 228, 228, 0.90)",
              }}
              placeholder="Скидка 00р."
              onChange={saleInputHandler}
              onValueChange={(value, name, values) =>
                setValue("sale", values!.float as number)
              }
              intlConfig={{ locale: "ru", currency: "RUR" }}
            />
            <TextField width="100%" as={"div"}>
              <Text
                color="black"
                font="mont"
                size={12}
              >{`Зароботок ${profit}р.`}</Text>
            </TextField>
          </FlexBox>
        </FlexBox>
      </FormInputContainer>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Затраченное время
        </Text>
        <FlexBox
          background="#fff"
          borderRadius="8px"
          direction="row"
          height="50px"
          align="center"
          justify="space-around"
        >
          <SuperButton
            varinat="counter"
            disabled={parseInt(minutes) <= 0}
            onClick={decrement}
          >
            <Text color="#fff" font="mont" size={10}>
              -5
            </Text>
          </SuperButton>
          <Text color="#262626" font="mont" size={16}>
            {Math.trunc(parseInt(minutes) / 60)
              .toString()
              .padStart(2, "0")}
            :{(parseInt(minutes) % 60).toString().padStart(2, "0")}
          </Text>
          <SuperButton onClick={counter} varinat="counter">
            <Text size={10} font="mont" color="#fff">
              +5
            </Text>
          </SuperButton>
        </FlexBox>
      </FlexBox>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Комментарии
        </Text>
        <TextField
          textIndent="10px"
          height="65px"
          as={"textarea"}
          {...register("comment")}
        ></TextField>
      </FlexBox>
      <FlexBox
        marginTop="15px"
        direction="row"
        gap={12}
        height="50px"
        width="100%"
      >
        <SuperButton onClick={cancel} varinat="outlined">
          Отменить
        </SuperButton>
        <SuperButton type="submit" varinat="contained">
          Создать
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
