import { css, styled } from "styled-components";
import { theme } from "./Theme.styled";

type propsType = {
    sort?: boolean,
    component?: "work"
    border?: string,
    borderRadius?: string,
    top?: number
}
export const DropDownListContainer = styled.ul<propsType>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
    border: ${props => props.border ? props.border : "none"};
    position: absolute;
    z-index: 10;
    top: ${props => props.top ? props.top + "px" : "45px"} ;
    font-size: 14px;
    ${props => props.borderRadius && css<propsType>`
        border-radius: ${props => props.borderRadius};
    `}
    li:hover{
        background: #444444;
        color: #fff;
        cursor: pointer;
    };
    li:first-child {
        border-radius: 8px 8px 0px 0px;
    };
    li:last-child{
        border-radius: 0px 0px 8px 8px;
    };
    ${props=> props.sort && css<propsType>`
        width: 120px;
        top: 100px;
        left: 230px;
        height: 115px;
        li:hover {
            background: #f8f8f8;
            border: 1px solid #262626;
            color: #262626;
        }
    `}
    ${props => props.component === "work" && css<propsType>`
        li:hover {
           
                background: ${theme.colors.colorForm};
                color: #262626;
            
        }
        li:hover button {
            color: #262626;
        }
    `}
`