import styled, { css } from "styled-components";
import sprite from "../../images/sprite.svg"
import { theme } from "./Theme.styled";
import { CalendarRow, TimeButtonRow } from "../animations/Animation";

type propsType = {
    type: "next" | "prev" | "up" | "down",
    component?: "calendar" | "timeButton"
    onClick?:() => void
}
export const NextPrevButton = (props: propsType) =>{
    return(
        <SvgBox onClick={props.onClick} component={props.component} type={props.type}>
            <svg >
                <use xlinkHref={`${sprite}#next`}/>
            </svg>
        </SvgBox>
        )
} 


const SvgBox = styled.div<propsType>`

width: 24px;
height: 24px;
background: #FFFFFF;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
&:hover{
    background: #444444;
    border: 1px solid #fff;
    
};
&:hover svg{
    fill: #ffffff;
};

svg {
    width: 5px;
    height: 8px;
    display: flex;
    align-items: center;
    ${props => props.type === "prev" && css<propsType>`
        transform: rotate(180deg);
    `};
    ${props => props.type === "up" && css<propsType>`
        transform: rotate(270deg);
    `};
    ${props => props.type === "down" && css<propsType>`
        transform: rotate(449deg);
    `};

   
};
${props => props.component === "calendar" && css<propsType>`
    width: 18px;
    height: 18px;
    border-radius: 6px;
   &:hover {
        animation: ${CalendarRow} .7s  ease-in-out forwards;
        cursor: pointer;
   } 
`};
${props => props.component === "timeButton" && css<propsType>`
    width: 18px;
    height: 18px;
    border-radius: 6px;
   &:hover {
        animation: ${TimeButtonRow} .7s  ease-in-out forwards;
        cursor: pointer;
   } 
`};


`