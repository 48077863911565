import { AxiosError, isAxiosError } from "axios"
import { apiUserPay } from "../apiServer/apiUserPay"
import { UserPayType, payInfoType } from "../apiTypes/root/userPay.type"
import { setIsLoadingAC } from "./AppReducer"
import { logoutTC } from "./AuthReducer"
import { showQrCodeWindowAC } from "./ModalWindowsReducer"
import { thunkActionRootType } from "./storeRedux"




const initState:UserPayType = {
    payInfo: {
        qrCode: null,
        order: ""
    }
} 

export type userPayAT = createPayAT

type createPayAT = {
    type: "USER-PAY/CREATE-PAY",
    data: payInfoType
}
export const UserPayReducer = (state: UserPayType = initState, action: userPayAT):UserPayType => {
    switch(action.type){
        case "USER-PAY/CREATE-PAY":
            return {...state, payInfo: action.data}
        default:
            return state
    }
}

const debitPayAC = (payInfo: payInfoType):createPayAT => {
    return {type: "USER-PAY/CREATE-PAY", data: payInfo}
}
// export const debitPayTC = (balance: number):thunkActionRootType => async dispatch => {
//    dispatch(setIsLoadingAC(true))
//     try {
//         const res = await apiUserPay.create(balance)
//        const blob = new Blob([res.data], {type: "image/png"})
//         const url = URL.createObjectURL(blob)
//         dispatch(debitPayAC({qrCode: url, order: ""}))
//         dispatch(showQrCodeWindowAC(true))
       
//     } catch (error) {
        
//     } finally {
//         dispatch(setIsLoadingAC(false))
//     }
// }
export const debitPayTC = (balance: number):thunkActionRootType => async dispatch => {
   dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPay.create(balance)
       const blob = new Blob([res.data], {type: "image/png"})
        const url = URL.createObjectURL(blob)
        dispatch(debitPayAC({qrCode: url, order: ""}))
        dispatch(showQrCodeWindowAC(true))
       
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}