import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalWindowStateType, showRmNoteWindowAC } from "../../store/ModalWindowsReducer";
import { deleteNoteTC } from "../../store/NotesReducer";
import { AppRootState } from "../../store/storeRedux";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { FlexBox } from "../../styles/components/FlexBox";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";



export const RemoveNoteWindow = () => {
    const dispatch = useDispatch()
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)

    const removeNote = () => {

        dispatch(deleteNoteTC(modalState.noteID))
        dispatch(showRmNoteWindowAC(false, ''))
    }
    const cancel = () => dispatch(showRmNoteWindowAC(false, ''))
    return (
        <Box className="removeNote" width="453px" height="218px" background={theme.colors.colorForm} position="absolute" borderRaduis="8px" direction="row" top="33%" left="33%" zIndex={37}>
            <FlexBox direction="column">
                <FlexBox direction="column" gap={4}>
                    <Text className="quest" font="mont" color={theme.colors.fonts.main} size={18}>
                        Вы уверены, что хотите удалить запись?
                    </Text>
                    <Text className="warn" font="mont" color={theme.colors.fonts.main} size={14}>
                        У вас не будет возможности восстановить эти даннные
                    </Text>

                </FlexBox>
                <FlexBox marginTop="12px" width="100%" direction="row" gap={12} height="48px">
                    <SuperButton onClick={removeNote} varinat="outlined">Удалить</SuperButton>
                    <SuperButton onClick={cancel} varinat="contained">Отменить</SuperButton>
                </FlexBox>
            </FlexBox>

        </Box>
    )
}