import { css, styled } from "styled-components";


type propsType = {
    active: boolean,
    navComponent?: "proSettings" ,
    width?: number
}

export const MenuLink = styled.a<propsType>`
   width: ${props => props.width ? props.width + "px" : "212px"} ;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 16px;
    gap: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    ${props => props.active && css<propsType>`
        background: #5d5fef;
        border-radius: 8px;
    `}
    
    &:hover {
        background: #5d5fef;
        border-radius: 8px;
       
    };
    &:last-child:hover {
        background: red;
        border-radius: 8px;
    };

`