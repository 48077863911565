import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { calendarStateType, getWorkDaysTC } from '../store/CalendarReducer';
import { clientsStateType, getClientsTC } from '../store/ClientsReducer';
import { AppRootState, thunkDispatchRootType } from '../store/storeRedux';
import { stateUserType } from '../store/UserReducer';
import classes from "./analytic.module.css"
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { getAnalyticNoteTC } from '../store/AnalyticReducer';

type clientDataType = { start: Date, end: Date, regularCount: number, newCount: number, month: string }


const selectAnalyticState = (state: AppRootState) => state.analyticState

export const Analytic = () => {



    const dispatch: thunkDispatchRootType = useDispatch()
    const clients = useSelector<AppRootState, clientsStateType[]>(state => state.client)
    const userState = useSelector<AppRootState, stateUserType>(state => state.user)
    const [clientsData, setClientsData] = useState<clientDataType[]>([])
    const analyticState = useSelector(selectAnalyticState)
    
    const startDate = startOfMonth(subMonths(new Date(), 10))
    const endDate = endOfMonth(new Date())

    const clientHandler = () => {
        const date = new Date()
        let arr: clientDataType[] = []
        let beginPeriod = subMonths(startOfMonth(new Date()), 10)
        while (beginPeriod <= date) {
            arr.push({
                start: new Date(beginPeriod.getFullYear(), beginPeriod.getMonth(), 1),
                end: new Date(beginPeriod.getFullYear(), beginPeriod.getMonth() + 1, 0),
                regularCount: 0,
                newCount: 0,
                month: new Date(beginPeriod.getFullYear(), beginPeriod.getMonth(), 1).toLocaleString("ru", { month: "short" })
            })
            beginPeriod = addMonths(beginPeriod, 1)

        }
        const income = clients.map(el => ({ addedDate: el.addedDate }))
        arr.map((el) => {
            income.forEach(cl => {
                if (new Date(cl.addedDate) < el.start) {
                    el.regularCount = el.regularCount + 1
                }
                else if (new Date(cl.addedDate) >= el.start && new Date(cl.addedDate) <= el.end) {
                    el.newCount = el.newCount + 1

                }
            })
        })
        setClientsData(arr)

    }

   
    useEffect(() => {
        if (clients.length === 0) {
            dispatch(getClientsTC())
        }
        clientHandler()
    }, [JSON.stringify(clients)])
    useEffect(() => {
       dispatch(getAnalyticNoteTC(startDate,endDate)) 
    },[])
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                    <h3>Аналитика</h3>
            </div>
            <div className={classes.graphics}>
                <div className={classes.graphic}>
                    <h2>Записи</h2>
                    <ResponsiveContainer debounce={200} className={classes.graphicsContainer}  aspect={1.2}>
                        <ComposedChart
                            width={600}
                            height={380}
                            data={analyticState.notesAnalyticItems.sort((a,b) => a.startDate > b.startDate ? 1 : -1)}
                            margin={{
                                top: 15,
                                right: 10,
                                left: 0,
                                bottom: 10,
                            }}
                        >
                            <XAxis dataKey="shorMonth" padding={{ left: 30 }} tickMargin={20} axisLine={false} tickLine={false} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Bar dataKey="Iphone" barSize={50} background={{ fill: "#F6F7F7" }} fill="#F6F7F7" />

                            <Tooltip contentStyle={{ backgroundColor: "#8884d8", color: "#fff" }} itemStyle={{ color: "#fff" }} cursor={false} />
                            <Line isAnimationActive={false} type="monotone" dataKey="noteCount" stroke="#000000" strokeWidth="5" dot={{ fill: "#292D30", stroke: "#ffffff", strokeWidth: 3, r: 6 }} activeDot={{ fill: "#2e4355", stroke: "#8884d8", strokeWidth: 5, r: 10 }} />
                            <Line isAnimationActive={false} type="monotone" dataKey="shorMonth" stroke="pink" strokeWidth="5" dot={{ fill: "#292D30", stroke: "#ffffff", strokeWidth: 3, r: 6 }} activeDot={{ fill: "#2e4355", stroke: "#8884d8", strokeWidth: 5, r: 10 }} />

                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                <div className={classes.graphic}>
                    <h2>Клиенты</h2>
                    <ResponsiveContainer debounce={200} className={classes.graphicsContainer}  aspect={1.2}>
                        <ComposedChart
                            width={600}
                            height={380}
                            data={clientsData}
                            margin={{
                                top: 15,
                                right: 10,
                                left: 0,
                                bottom: 10,
                            }}
                        >
                            <XAxis dataKey="month" padding={{ left: 30 }}  tickMargin={20} axisLine={false} tickLine={false} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Bar dataKey="Iphone" barSize={50} background={{ fill: "#F6F7F7" }} fill="#F6F7F7" />

                            <Tooltip contentStyle={{ backgroundColor: "#8884d8", color: "#fff" }} itemStyle={{ color: "#fff" }} cursor={false} />
                            <Line isAnimationActive={false} type="monotone" dataKey="regularCount" stroke="#000000" strokeWidth="5" dot={{ fill: "#292D30", stroke: "#ffffff", strokeWidth: 3, r: 6 }} activeDot={{ fill: "#2e4355", stroke: "#8884d8", strokeWidth: 5, r: 10 }} />
                            <Line isAnimationActive={false} type="monotone" dataKey="newCount" stroke="pink" strokeWidth="5" dot={{ fill: "#292D30", stroke: "#ffffff", strokeWidth: 3, r: 6 }} activeDot={{ fill: "#2e4355", stroke: "#8884d8", strokeWidth: 5, r: 10 }} />

                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>






        </div>
    )
}