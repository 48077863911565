import { css, styled } from "styled-components";
import { theme } from "./Theme.styled";


type propsType = {
    width?: number,
    height?: number
}
export const SimpleForm = styled.form<propsType>`
    padding: 24px 64px;
    background: ${theme.colors.colorForm};
    border-radius: ${theme.radius.big};
    color: ${theme.colors.main};
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 17;
    width: ${props => props.width ? props.width + "px" : "453px"};
    align-items: center;
    font-family: ${theme.fonts.primary};
    ${props => props.height && css<propsType>`
        height: ${props.height + "px"};
    `}
`