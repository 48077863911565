import axios from "axios"


const instance = axios.create({
    baseURL: "/api/notification",
    
})



export const apiNotification = {
    addTeammate (payload: any, owner: string) {
      return  instance.post("/add/teammate", {payload, owner})
    },
    getTeamMateRequest (id: string){
        return instance.get("/get/teammate", {
            params: {
                id: id
            }
        })
    },
    acceptTeamMate (owner: string, requestID: string){
        return instance.post("/accept/teammate", {requestID,owner})
    },
    declineTeamMate (owner:string, requestID: string, teamReqID: string){
        return instance.post("/decline/teammate", {owner, requestID, teamReqID})
    },
    createTeamMate (owner: string,data: FormData){
        debugger
       
        return instance.post("/create/teammate", data, {
            headers: {
                'Content-type': 'multipart/form-data'
            },
            params: {
                owner: owner
            }
        })
    }
   
}
