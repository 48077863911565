import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import Assist from "../../assets/assistlogomonochromevert.png"
import styled from "styled-components"


const Btn = styled.button`
    width: 25%;
    height: 5%;
    background-color: rgba(89, 136, 102, 0.80);
    border: inherit;
    border-radius: 5px;
    margin-top: 1%;
    color: rgb(243, 243, 243);
    font-weight: 600;
    align-self: center;
`

const Time = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-self: end;
    justify-content: end;
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid rgb(213, 213, 213);
    border-bottom: 1px solid rgb(213, 213, 213);
    padding: 7px 0;
    margin-bottom: 1%;
`
const Plata = styled.div`
    display: flex;
    justify-content: space-between;
`
const SecondRow = styled.div`
    border-top: 1px solid rgb(213, 213, 213);
    border-bottom: 1px solid rgb(213, 213, 213);
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
`
const Qr = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5%;
`


const selectUserPayState = (state:AppRootState) => state.userPayState
export const QrCodeWindow = () => {
    const userPayState = useSelector(selectUserPayState)
    const dispatch:thunkDispatchRootType = useDispatch()
    return( 
        <Box padding="20px 20px" borderRaduis="20px" direction="column" width="90vh" height="90vh" background="rgb(243, 243, 243)" position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex={27} font-size='14px' color='#4e4e4e'>
            <FlexBox direction="row" width="100%" height="20%" align="center" justify="space-between" color="rgb(78, 128, 78)" font-weight="600" margin-bottom="2%" padding="0 10px">
                <img style={{width: "60px",height: "60px"}} src={Assist}/>
                <Time>02 мин. 01 сек.</Time>
            </FlexBox>
            <Info>
                <Plata>
                    <span>Предприятие</span>
                    <span>СБП</span>
                </Plata>
                <SecondRow>
                    <span>Номер заказа</span>
                    <span>FS7654334567_97</span>
                </SecondRow>
                <Plata>
                    <span>Сумма платежа</span>
                    <span>65.35 RUB</span>
                </Plata>
            </Info>
            <Qr>
                <p>Система быстрых платежей</p>
                <img style={{width: "40%"}} src={userPayState.payInfo.qrCode}/>
            </Qr>
            <div>
                Перейдите в раздел переводов через СБП в мобильном приложении своего банка и сканируйте QR-код.
            </div>
            <Btn type="submit">Продолжить</Btn>
        </Box>
    )
}
