

import * as React from 'react';
import Container from '@mui/material/Container/Container';
import { Link } from 'react-router-dom';
import { greetingStyles } from './greetingStyles';
import { AppRootState } from '../store/storeRedux';
import { useSelector } from 'react-redux';

const selectUser = (state:AppRootState) => state.user
export default function Greeting() {
  const user = useSelector(selectUser)
  const classes = greetingStyles()
  

  const link = user.isAuth ? "/main" : "/login"
  return (
    <div className={classes.bg1} >
      <div className={classes.content1}>
        <div className={classes.textContent}>
          <div className={classes.title}>Добро пожаловать!</div>
          <div className={classes.text}>Спасибо, что выбрали нас, желаем Вам приятного использования сервисом Ipluse</div>
        </div>

        <Link className={classes.link} to={link}><button className={classes.btn}>Начать работу</button></Link>
      </div>

    </div>
  )
}
