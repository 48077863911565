import { FlexBox } from "../styles/components/FlexBox"
import { Icon } from "../styles/components/Icon"
import { Text } from "../styles/components/Text"


type propsType = {
    setFocusBtn: (v: boolean) => void,
    title: string,
    price: string,
    subtitle?: string
}

export const TariffInfoBlock = (props: propsType) => {
    return (
        <FlexBox gap={7}  padding="15px 10px" onMouseLeave={() => props.setFocusBtn(false)} width="223px" minWidth="195px" height="72px" borderRadius="8px" background="#598866" direction="column">
            <FlexBox  justify="space-around" direction="row">
                <Text color="#fff" size={16} weight={900} font="mont">Тариф</Text>
                <FlexBox  direction="row" align="center" justify="center" gap={2}>
                    <Icon width={20} height={19} svgID="diamond" />
                    <Text weight={900} size={15} font="mont" color="#fff">{props.title}</Text>
                   {props.subtitle && <Text weight={900} size={12} font="mont" color="#fff">{props.subtitle}</Text> }
                </FlexBox>
            </FlexBox>
            <FlexBox gap={3} justify="start" align="start"  direction="row" width="100%">
                <Text opacity={0.7} color="#fff" weight={300} size={11} font="mont">Ежедневное списание</Text>
                <Text color="#fff" weight={900} size={13} font="mont">{props.price}</Text>
                <Text opacity={0.7} color="#fff" size={11} font="mont">рублей</Text>
            </FlexBox>
        </FlexBox>
    )
}