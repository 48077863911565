import { AxiosError, isAxiosError } from "axios"
import { apiNotes } from "../apiServer/apiNote"
import { setIsLoadingAC } from "./AppReducer"
import { logoutTC } from "./AuthReducer"
import { clientsStateType } from "./ClientsReducer"
import { notesStateType } from "./NotesReducer"
import { thunkActionRootType } from "./storeRedux"

export type searchStateType = {
    term: string,
    clients: clientsStateType[] | null
    notes: {last: notesStateType[], future: notesStateType[]} | null
}

export type searchActionType = setClientsAT | setSearchingNotesAT | setTermAT


type setClientsAT = {
    type: 'SET-SEARCHING-CLIENTS',
    clients: clientsStateType[] | null

}
type setSearchingNotesAT = {
    type: 'SET-SEARCHING-NOTES',
    notes: {last: notesStateType[], future: notesStateType[]}
}
type setTermAT = {
    type: "SEARCH/SET-TERM",
    term: string
}


const initState:searchStateType = {
    term: "",
    clients: [],
    notes: null
}

export const SearchReducer = ( state: searchStateType = initState, action: searchActionType):searchStateType => {
    switch(action.type){
        
        case "SEARCH/SET-TERM":
            return {...state,term: action.term}
        case 'SET-SEARCHING-CLIENTS' : 
            return {...state, clients: action.clients}
        
        case 'SET-SEARCHING-NOTES' : 
            return {...state, notes: action.notes}
        
        default :
            return state
    }
}


export const setSearchingClientsAC = (clients: clientsStateType[] | null):setClientsAT => {
    return {type: 'SET-SEARCHING-CLIENTS', clients}
}
export const setSearchingNotesAC = (notes: {last: notesStateType[], future: notesStateType[]}):setSearchingNotesAT => {
    return {type: 'SET-SEARCHING-NOTES', notes}
}
export const setSearchTermAC = (term: string):setTermAT => {
    return {type : "SEARCH/SET-TERM", term}
}


export const getNotesBySearchClientTC = (clientId: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiNotes.getBySearchClientId(clientId)
        dispatch(setSearchingNotesAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))
    }
}