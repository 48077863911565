import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { clientsStateType, getClientsTC } from "../../store/ClientsReducer";
import { useForm } from "react-hook-form";
import { Status, addNoteTC, changeNoteStatusTC, getNotesTC, noteStatuses, updateNoteTC } from "../../store/NotesReducer";
import { stateUserType, tariffType } from "../../store/UserReducer";
import theMan from "../../assets/pictures/theMan.png";
import {
  calendarStateType,
  getWorkDaysTC,
  workTimeStateType,
} from "../../store/CalendarReducer";
import {
  sessionsType,
  setDateAndTimeForNoteAC,
} from "../../store/SessionsReducer";
import {
  addNewClientCardAC,
  clientBalanceWindowAC,
  editNoteWindowAC,
  editSelectTimeAC,
  ModalWindowStateType,
  selectClientAC,
  showErrorWindowAC,
  showNewEntryAC,
  showQueueAC,
  showTimesWindowAC,
} from "../../store/ModalWindowsReducer";
import { TimesWindow } from "../../timeTable/TimesWindow";
import { ErrorWindow } from "../../timeTable/ErrorWindow";
import ReactDOM from "react-dom";
import { CustomSelect } from "../../styles/components/CustomSelect";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { Icon } from "../../styles/components/Icon";
import { Avatar } from "../../styles/components/Avatar";
import { Select } from "../../styles/components/Select";
import { FakeInput } from "../../styles/components/FakeInput";
import { TextField } from "../../styles/components/TextField";
import { ListItem } from "../../styles/components/ListItem";
import { FlexBox } from "../../styles/components/FlexBox";
import { Box } from "../../styles/components/Box";
import { noteInputType } from "../../apiTypes/responses/notesResponsesType";
import { FreeTimesCalendar } from "../../timeTable/FreeTimeCalendar";
import { theme } from "../../styles/components/Theme.styled";
import { getWorkServicesTC } from "../../store/ProsettingsReducer";
import { Grid } from "../../styles/components/Grid";
import { setSuccessMessageChangeStatusAC } from "../../store/AppReducer";

const selectModalState = (state: AppRootState) => state.ModalWindowReducer;
const selectProSettings = (state: AppRootState) => state.proSettings;
const selectClients = (state: AppRootState) => state.client;
const selectUser = (state:AppRootState) => state.user 
const selectAppState = (state: AppRootState) => state.appState

export const EditNote = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const modalState = useSelector(selectModalState);
    const user = useSelector(selectUser)
    const appState = useSelector(selectAppState)
  const { note } = modalState.editNoteState;

  const [selectDate, setSelectDate] = useState(false);
  const [statusList, setStatusList] = useState<boolean>(false);
  const [time, setTime] = useState<string>(note!.time);
  const [date, setDate] = useState<string>(note!.date);
  const [minutes, setMinutes] = useState(note?.duration || "60");
  const clients = useSelector(selectClients);
  const proSettings = useSelector(selectProSettings);
  const cancel = () => {
    dispatch(editNoteWindowAC({ value: false, note: null }));
  };

  const defineDate = (e: any) => {
    e.preventDefault();
    setSelectDate(true);
  };

  const showTimesWindow = () => {
    dispatch(showTimesWindowAC(true));
  };
  const counter = (e: any) => {
    e.preventDefault();
    setMinutes((prev) => (Number(prev) + 5).toString());
  };
  const decrement = (e: any) => {
    e.preventDefault();
    setMinutes((parseInt(minutes) - 5).toString());
  };
  const duplicateEntry = () => {
    const client = clients.find((c) => c.phone === note?.phone);
    if (!client) {
      return;
    } else {
      dispatch(showNewEntryAC({value: true, fromQueue: false}));
      dispatch(selectClientAC(client));
    }
  };
  const queueHandler = () => {
    const client = clients.find((c) => c.phone === note?.phone);
    if (!client) {
      return;
    } else {
      dispatch(showQueueAC({ value: true, client }));
    }
  };
  const clientBalanceHandler = () => {
    const client = clients.find((c) => c.phone === note?.phone);
    if (!client) {
      return;
    } else {
      dispatch(clientBalanceWindowAC({ value: true, client }));
    }
  };
  const statusListHandler = (e:any) => {
    e.preventDefault()
    setStatusList(prev => !prev)
  }
  const changeStatus = (e:any,noteId: string, status: Status) => {
    e.preventDefault()
    dispatch(changeNoteStatusTC(status,noteId))
    setStatusList(false)
  }
  const { register, handleSubmit, getValues, setValue, watch } = useForm<noteInputType>({
    defaultValues: {
        userId: user.user?.id,
        clientId: clients.find(c => c.phone === note?.phone)?.id || "",
        date: date,
        time: time,
        duration: minutes,
        clientAvatar: note?.clientAvatar,
        comments: note?.comments,
        
    }
  });
  const onSubmit = (data: noteInputType) => {
   const result:noteInputType = {...data, date,time, duration: minutes}
  if(note) dispatch(updateNoteTC(result, note.id))
  };

  useEffect(() => {
    if (!proSettings.workService.length) {
      dispatch(getWorkServicesTC());
    }
    if(proSettings.workService.length){
      const work = proSettings.workService.find(w => w.name === note?.work)
      if(work) setValue("serviceId",work.id)
  
     }
    if (!clients.length) {
      dispatch(getClientsTC());
    }
  }, [proSettings.workService.length, clients.length]);
  useEffect(() => {
    if(appState.noteChangeStatusMessage){
       setTimeout(() => {
        dispatch(setSuccessMessageChangeStatusAC(""))
       },2000)
      
    }
  },[appState.noteChangeStatusMessage])
  return (
    <Box
      padding="30px 40px"
      zIndex={27}
      background={theme.colors.colorForm}
      borderRaduis="8px"
      width="423px"
      height="max-content"
      position="fixed"
      transform="translate(-50%,-50%)"
      top="50%"
      left="50%"
      gap={20}
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      {selectDate && (
        <FreeTimesCalendar
          setMinutes={setMinutes}
          setTime={setTime}
          setSelectDate={setSelectDate}
          setDate={setDate}
        />
      )}
      <FlexBox direction="row" width="100%" align="center" justify="center">
        <Text font="mont" size={18} color={theme.colors.fonts.main}>
          Редактировать прием
        </Text>
      </FlexBox>
      <Box height="max-content" position="relative">
        {statusList && (
          <Box
            direction="column"
            position="absolute"
            zIndex={29}
            background="#fff"
            width="200px"
            right="0px"
            top="58px"
            height="max-content"
            borderRaduis="8px"
            
          >
            {noteStatuses.map((n) => (
              <FlexBox onClick={(e) => changeStatus(e,note!.id,n.status)} padding="7px 10px" className="statusList"  background="#fff"  key={n.id} direction="row" height="36px">
                <FlexBox align="center" justify="center" direction="row" width="100%">
                  <Text color={theme.colors.fonts.main} font="mont" size={12}>
                    {n.title}
                  </Text>
                </FlexBox>
                <FlexBox align="center" justify="center" direction="row"  width="25px">
                  <Icon stroke={theme.colors.main}  fill="#fff" svgID={n.svgId} width={24} height={24} />
                </FlexBox>
              </FlexBox>
            ))}
          </Box>
        )}
        <FakeInput active={true}>
          <FlexBox direction="row" align="center" justify="center" width="40px">
            <Avatar
              size="newEntry"
              src={modalState.editNoteState.note?.clientAvatar}
            />
          </FlexBox>
          <FlexBox direction="column" width="100%">
            <Text
              color="#fff"
              size={14}
              font="mont"
            >{`${note?.surname} ${note?.name} ${note?.patronymic}`}</Text>
            <Text color="#fff" size={14} font="mont">
              {note?.phone}
            </Text>
          </FlexBox>
          <Box position="relative" width="30px">
            <SuperButton onClick={(e) => statusListHandler(e)} varinat="selector">
              <Icon fill="#fff" svgID="row" width={6} height={4}></Icon>
            </SuperButton>
          </Box>
        </FakeInput>
        <Text size={12} color="green" font="mont">{appState.noteChangeStatusMessage}</Text>
      </Box>

      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={15} font="mont">
          Услуга
        </Text>
        <Select
          setValue={setValue}
          name="serviceId"
          state={proSettings.workService}
          placeholder="Выберите услугу"
          defaultValue={watch("serviceId")}
        />
      </FlexBox>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Дата и время
        </Text>
        <FlexBox
          className="newEntryDateAndTime"
          direction="row"
          height="50px"
          width="100%"
          justify="space-between"
          gap={8}
        >
          <CustomSelect />

          {date && !selectDate ? (
            <FlexBox
              width="100%"
              background="#fff"
              direction="row"
              border="1px solid rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              align="center"
              justify="center"
              onClick={defineDate}
            >
              {new Date(date).toLocaleDateString()}
            </FlexBox>
          ) : (
            <FlexBox
              onClick={defineDate}
              width="94px"
              background="#fff"
              direction="row"
              border="1px solid rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              align="center"
              justify="space-around"
            >
              <Icon svgID="calendar2" width={16} height={16} />
              <Text color={theme.colors.fonts.main} font="mont" size={14}>
                Дата
              </Text>
            </FlexBox>
          )}

          {time && !selectDate ? (
            <FlexBox
              width="100%"
              background="#fff"
              direction="row"
              border="1px solid rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              align="center"
              justify="space-around"
              onClick={showTimesWindow}
            >
              {time}
            </FlexBox>
          ) : (
            <FlexBox
              width="94px"
              background="#fff"
              direction="row"
              border="1px solid rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              align="center"
              justify="space-around"
            >
              <Icon
                svgID="clock"
                width={22}
                height={22}
                transform="scale(0.7)"
              />
              <Text color={theme.colors.fonts.main} size={14} font="mont">
                Время
              </Text>
            </FlexBox>
          )}

          {modalState.timesWindow && (
            <>
              <TimesWindow
                setMinutes={setMinutes}
                setTime={setTime}
                date={date}
              />
            </>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Продолжительность
        </Text>
        <FlexBox
          background="#fff"
          borderRadius="8px"
          direction="row"
          height="50px"
          align="center"
          justify="space-around"
        >
          <SuperButton
            varinat="counter"
            disabled={parseInt(minutes) <= 0}
            onClick={decrement}
          >
            <Text color="#fff" font="mont" size={10}>
              -5
            </Text>
          </SuperButton>
          <Text color="#262626" font="mont" size={16}>
            {Math.trunc(parseInt(minutes) / 60)
              .toString()
              .padStart(2, "0")}
            :{(parseInt(minutes) % 60).toString().padStart(2, "0")}
          </Text>
          <SuperButton onClick={counter} varinat="counter">
            <Text size={10} font="mont" color="#fff">
              +5
            </Text>
          </SuperButton>
        </FlexBox>
      </FlexBox>
      <Grid width="100%" height="max-content" columns="1fr 1fr" gap={10}>
        <SuperButton
          onClick={clientBalanceHandler}
          varinat="outlined-secondary"
        >
          <Text color={theme.colors.fonts.main} size={12} font="mont">
            Пополнить счет
          </Text>
          <Icon fill="#fff" svgID="card" width={24} height={24} />
        </SuperButton>
        <SuperButton onClick={duplicateEntry} varinat="outlined-secondary">
          <Text color={theme.colors.fonts.main} size={12} font="mont">
            Дублировать
          </Text>
          <Icon fill="#fff" svgID="users" width={24} height={24} />
        </SuperButton>
        <SuperButton onClick={queueHandler} varinat="outlined-secondary">
          <Text color={theme.colors.fonts.main} size={12} font="mont">
            В очередь
          </Text>
          <Icon fill="#fff" svgID="users-more" width={24} height={24} />
        </SuperButton>
        <SuperButton varinat="outlined-secondary">
          <Text color={theme.colors.fonts.main} size={12} font="mont">
            Отправить SMS
          </Text>
          <Icon fill="#fff" svgID="message" width={24} height={24} />
        </SuperButton>
      </Grid>
      <FlexBox
        marginTop="15px"
        direction="row"
        gap={12}
        height="50px"
        width="100%"
      >
        <SuperButton onClick={cancel} varinat="outlined">
          Отменить
        </SuperButton>
        <SuperButton type="submit" varinat="contained">
          Создать
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
