import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { acceptTeamMateTC, declineTeamMateTC, getTeamMateRequestTC, notificationStateType } from "../store/NotificationReducer"
import { AppRootState } from "../store/storeRedux"
import { stateUserType } from "../store/UserReducer"
import { Box } from "../styles/components/Box";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { Text } from "../styles/components/Text";
import { FlexBox } from "../styles/components/FlexBox";
import { TableRow } from "../styles/components/TableRow";
import { SuperButton } from "../styles/components/SuperButton";
import { Icon } from "../styles/components/Icon";

export const Notification = () => {
    const dispatch = useDispatch()
    const notifications = useSelector<AppRootState, notificationStateType>(state => state.notifications)
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    useEffect(() => {
        dispatch(getTeamMateRequestTC(user.user?.id as string))
    }, [])
    const accept = ( owner: string, requestID: string) => {
        dispatch(acceptTeamMateTC( owner, requestID))
    }
    const decline = (owner: string, requestID: string, teamReqID: string) => {
        dispatch(declineTeamMateTC(owner, requestID, teamReqID))
    }
    return (
        <Box>
            <FlexBox direction="column" width="100%" gap={16}>
                <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
                    <Column maxWidth="100%" axis="column" component="TimeTable">
                        <Text size={13} color="#fff" font="mont">Клиенты</Text>
                    </Column>
                    <Column maxWidth="100%" axis="column" component="TimeTable">
                        <Text size={13} color="#fff" font="mont">Команда</Text>
                    </Column>
                    <Column maxWidth="100%" axis="column" component="TimeTable">
                        <Text size={13} color="#fff" font="mont">Оплата</Text>
                    </Column>
                    <Column maxWidth="100%" axis="column" component="TimeTable">
                        <Text size={13} color="#fff" font="mont">Записи</Text>
                    </Column>
                </TableHeader>
            </FlexBox>
            <Box height="100%" width="100%" position="relative">
                <TableRow minHeight="80px">
                    <FlexBox align="center" width="25%" height="100%" direction="row" background="#A32964" borderRadius="8px" padding="5px 10px" justify="space-between">
                        <FlexBox align="start" direction="column">
                            <Text size={14} font="mont" color="white">Запись нового клиента:</Text>
                            <Text size={12} font="mont" color="white">Никитина Галина</Text>
                        </FlexBox>
                        <SuperButton className="trash" varinat="sort">
                            <Icon svgID="delete" width={18} height={16} />
                        </SuperButton>
                    </FlexBox>
                    <FlexBox align="center" width="25%" height="100%" direction="row" background="#295CA3" borderRadius="8px" padding="5px 10px" justify="space-between">
                        <FlexBox align="start" direction="column">
                            <Text size={14} font="mont" color="white">У вас новый сотрудник:</Text>
                            <Text size={12} font="mont" color="white">Никитина Галина</Text>
                        </FlexBox>
                        <SuperButton className="trash" varinat="sort">
                            <Icon svgID="delete" width={18} height={16} />
                        </SuperButton>
                    </FlexBox>
                    <FlexBox align="center" width="25%" height="100%" direction="row" background="#29A370" borderRadius="8px" padding="5px 10px" justify="space-between">
                        <FlexBox align="start" direction="column">
                            <Text size={14} font="mont" color="white">Поступила оплата:</Text>
                            <Text size={12} font="mont" color="white">Никитина Галина</Text>
                        </FlexBox>
                        <SuperButton className="trash" varinat="sort">
                            <Icon svgID="delete" width={18} height={16} />
                        </SuperButton>
                    </FlexBox>
                    <FlexBox align="center" width="25%" height="100%" direction="row" background="#9229A3" borderRadius="8px" padding="5px 10px" justify="space-between">
                        <FlexBox align="start" direction="column">
                            <Text size={14} font="mont" color="white">У вас новая запись:</Text>
                            <Text size={12} font="mont" color="white">Никитина Галина</Text>
                        </FlexBox>
                        <SuperButton className="trash" varinat="sort">
                            <Icon svgID="delete" width={18} height={16} />
                        </SuperButton>
                    </FlexBox>
                </TableRow>
            </Box>
        </Box>
        
    )
}