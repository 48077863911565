import { styled } from "styled-components";


type propsType = {
    width?: number
}
export const ButtonContainer = styled.div<propsType>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    gap: 12px;
    width: ${props => props.width ? props.width + "px" : "100%"};
`