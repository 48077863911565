import { css, styled } from "styled-components";


type avaPropsType = {
    size: "small" | "medium" | "large" | "extraSmall" | "selectAvatar" | "extralarge" | "userAvatar"
    | "newEntry" | "adaptive" 
}
export const Avatar = styled.img<avaPropsType>`
    border-radius: 50%;
    object-fit: cover;
    ${props => props.size === "small" && css<avaPropsType>`
        width: 32px;
        height: 32px;
    `};
    ${props => props.size === "medium" && css<avaPropsType>`
        width: 34px;
        height: 34px;
    `};
    ${props => props.size === "extraSmall" && css<avaPropsType>`
        width: 25px;
        height: 25px;
    `};
    ${props => props.size === "selectAvatar" && css<avaPropsType>`
        height: 28px;
        width: 28px;
`};
${props => props.size === "extralarge" && css<avaPropsType>`
    height: 72px;
    width: 72px;
`};
${props => props.size === "large" && css<avaPropsType>`
    height: 50px;
    width: 50px;
`};
${props => props.size === "userAvatar" && css<avaPropsType>`
    height: 64px;
    width: 64px;
`};
${props => props.size === "newEntry" && css<avaPropsType>`
    height: 40px;
    width: 40px;
`};
${props => props.size === "adaptive" && css<avaPropsType>`
    height: 100%;
    width: 100%;
`};

`