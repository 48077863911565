import { Dispatch } from "redux"
import { apiNotification } from "../apiServer/apiNotification"
import { stateUserType } from "./UserReducer"

export type notificationStateType = {
    teamRequest: teamRequestType[],
    isActiveCount: number | null
}
type teamRequestType = {
    _id: string,
    friendPhoto: string,
    theme: string
    name: string,
    surname: string,
    isActive: boolean,
    owner: string,
    requestID: string
}
type actionsType = addTeamMateAT | getTeamMateAT | setActiveCount | removeTeamReqAT
type addTeamMateAT = {
    type: "NOTIFICATION/SEND-TEAM-REQUEST",
    payload: teamRequestType
}
type getTeamMateAT = {
    type: "NOTIFICATION/GET-TEAM-REQUEST",
    payload: teamRequestType[]
}
type setActiveCount = {
    type: "NOTIFICATION/SET-ACTIVE-COUNT",
    activeCount: number
}
type removeTeamReqAT = {
    type: "NOTIFICATIONS/REMOVE-TEAM-REQUEST",
    id: string,
    activeCount: number
}
const initState: notificationStateType = {
    teamRequest: [],
    isActiveCount: null
}
export const NotificationReducer = (state: notificationStateType = initState, action: actionsType): notificationStateType => {
    switch (action.type) {
        case "NOTIFICATION/SEND-TEAM-REQUEST":
            return { ...state, teamRequest: [...state.teamRequest, action.payload] }
        case "NOTIFICATION/GET-TEAM-REQUEST":
            return { ...state, teamRequest: [...action.payload] }
        case "NOTIFICATION/SET-ACTIVE-COUNT":
            return { ...state, isActiveCount: action.activeCount }
        case "NOTIFICATIONS/REMOVE-TEAM-REQUEST":
            return { ...state, teamRequest: state.teamRequest.filter(el => el._id !== action.id), isActiveCount: action.activeCount }
        default:
            return state
    }
}

const sendTeamRequestAC = (payload: teamRequestType): addTeamMateAT => {
    return { type: "NOTIFICATION/SEND-TEAM-REQUEST", payload }
}
const getTeamMateAC = (payload: teamRequestType[]): getTeamMateAT => {
    return { type: "NOTIFICATION/GET-TEAM-REQUEST", payload }
}
const setActiveCountAC = (count: number): setActiveCount => {
    return { type: "NOTIFICATION/SET-ACTIVE-COUNT", activeCount: count }
}
const removeTeamReqAC = (id: string, activeCount: number): removeTeamReqAT => {
    return { type: "NOTIFICATIONS/REMOVE-TEAM-REQUEST", id, activeCount }
}
export const sendTeamRequestTC = (payload: any, owner: string) => {
    return (dispatch: Dispatch) => {
        apiNotification.addTeammate(payload, owner)
            .then(res => {

            })
    }
}
export const getTeamMateRequestTC = (id: string) => {
    return (dispatch: Dispatch) => {
        apiNotification.getTeamMateRequest(id)
            .then(res => {
                dispatch(getTeamMateAC(res.data.notifications))
                dispatch(setActiveCountAC(res.data.count))
            })
    }
}
export const acceptTeamMateTC = (owner: string, requestID: string) => {
    return (dispatch: Dispatch) => {
        apiNotification.acceptTeamMate(owner, requestID)
            .then(res => {
                debugger
            })
            .catch((e) => {
                debugger
            })
    }
}
export const declineTeamMateTC = (owner: string, requestID: string, teamReqID: string) => {
    return (dispatch: Dispatch) => {
        apiNotification.declineTeamMate(owner, requestID, teamReqID)
            .then(res => {
                dispatch(removeTeamReqAC(res.data._id, res.data.activeCount))
            })
    }
}