import { css, styled } from "styled-components";

type cellPropsType = {
variant: "simple" | "action" | "avatar",
height?: number
}
export const Cell = styled.div<cellPropsType>`
width: 100%;
height: ${props => props.height ? props.height + "px" : "100%" } ;
display: flex;
align-items: center;
justify-content: center;
${props => props.variant === "action" && css<cellPropsType>`
    gap: 6px;
`};
${props => props.variant === "avatar" && css<cellPropsType>`
    gap: 12px;
    
`}
`