import { keyframes } from "styled-components";
import { theme } from "../components/Theme.styled";


export const CalendarRow = keyframes`
    from {
        background: #444444;
    }
    to {
        background: ${theme.colors.main};
    }
`
export const TimeButtonRow = keyframes`
    from {
        background: #444444;
    }
    to {
        background: #598866;

;
    }
`
