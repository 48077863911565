import { Table } from "../styles/components/Table";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { Cell } from "../styles/components/Cell";
import { rightsType } from "../store/TeamMateReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { useEffect } from "react";
import { Box } from "../styles/components/Box";
import styled from "styled-components";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { getPayHistoryTC, getTarifHistoryTC } from "../store/UserPro.reducer";
import { userProHelper } from "../utils/userPro/userPro.helper";
import { appUtils } from "../utils/utils";

const WorkItem = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const selectUserProState = (state: AppRootState) => state.userProState;
const selectTeamMates = (state: AppRootState) => state.teamMates;
export const PaymentHistory = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const teamMates = useSelector(selectTeamMates);
  const userProState = useSelector(selectUserProState);
  const mainColumn = [
    { title: "Пополнение" },
    { title: "Списание" },
    { title: "Смена тарифа" },
  ];

  const firstColumn = [
    { title: "Дата" },
    { title: "Сотрудник" },
    { title: "Сумма" },
  ];

  const secondColumn = [
    { title: "Дата" },
    { title: "Тариф" },
    { title: "Сумма" },
  ];

  const thirdColumn = [
    { title: "Дата" },
    { title: "Сотрудник" },
    { title: "Тариф" },
  ];

  
  const getRightElement = (
    name: "clients" | "timetable" | "settings" | "analytic",
    rights: rightsType[]
  ) => {
    const result = rights.find((item) => item.name === name);
    return result;
  };
  useEffect(() => {}, [JSON.stringify(teamMates)]);
  useEffect(() => {
    dispatch(getTarifHistoryTC());
    dispatch(getPayHistoryTC())
  }, []);
  return (
    <WorkItem>
      <Box height="100%" gap={20} direction={"row"}>
        <Table>
          <TableHeader type="static" offset="small">
            <TableHeader type="static" offset="small">
              <Column component="any">{mainColumn[0].title}</Column>
            </TableHeader>
          </TableHeader>
          <Cell height={48} variant="simple">
            {firstColumn.map((el) => (
              <Column component="any">{el.title}</Column>
            ))}
          </Cell>
          <FlexBox direction="column" gap={5}>
            {userProState.payHistory.map((p) => (
              <FlexBox
              key={p.id}
                direction="row"
                width="100%"
                height="36px"
                background="#dadada"
                justify="space-around"
                align="center"
                borderRadius="8px"
              >
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {new Date(p.date).toLocaleDateString()}
                </Text>
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {p.userName}
                </Text>
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {appUtils.getMoneyStr(p.sum)}
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        </Table>
        <Table>
          <TableHeader type="static" offset="small">
            <TableHeader type="static" offset="small">
              <Column component="any">{mainColumn[1].title}</Column>
            </TableHeader>
          </TableHeader>
          <Cell height={48} variant="simple">
            {secondColumn.map((el) => (
              <Column component="any">{el.title}</Column>
            ))}
          </Cell>
        </Table>
        <Table>
          <TableHeader type="static" offset="small">
            <TableHeader type="static" offset="small">
              <Column component="any">{mainColumn[2].title}</Column>
            </TableHeader>
          </TableHeader>
          <Cell height={48} variant="simple">
            {thirdColumn.map((el) => (
              <Column component="any">{el.title}</Column>
            ))}
          </Cell>
          <FlexBox direction="column" gap={5}>
            {userProState.tarifHistory.items.map((h) => (
              <FlexBox
              key={h.id}
                direction="row"
                width="100%"
                height="36px"
                background="#dadada"
                justify="space-around"
                align="center"
                borderRadius="8px"
              >
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {new Date(h.dateOfChange).toLocaleDateString()}
                </Text>
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {h.userName}
                </Text>
                <Text size={13} font="mont" color={theme.colors.fonts.main}>
                  {userProHelper.getTarifName(h.presentTarif)}
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        </Table>
      </Box>
    </WorkItem>
  );
};
