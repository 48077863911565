import { css, styled } from "styled-components";

type propsType = {
    variant?: "column" | "row",
    justify?:"start" | "end" | "center"
}
export const FormInputContainer = styled.div<propsType>`
    display: flex;
    flex-direction: ${props => props.variant === "row" ? "row" : "column"};
    gap: 8px;
    width: 100%;
    ${props => props.justify && css<propsType>`
        justify-content: start;
    `}
    input {
        width: 100%;
    };
    div {
        display: flex;
        gap: 5px;
    };
    .react-tel-input .form-control {
        height: 50px;
        padding-left: 30px;
    }
`