import axios from "axios"
import { useEffect, useState } from "react"


export const ChargeOff = () => {
    const [file, setFile] = useState<any>("")
    const [edit, setEdit] = useState<boolean>(false)
    const [url, setUrl] = useState<any>("")
    const sendFile = (file: any) => {
        const formData = new FormData()
        formData.append("file", file)
        axios.post(`/api/files/upload`, formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
        )
    }
    const getFile = () => {
        axios.get(`http://82.146.55.48:3002/api/files/upload/${'1676846411242-any-name-About.png'}`,
        {responseType: "blob"}
        )
        .then ( res => {
            const resUrl = URL.createObjectURL(res.data)
            setUrl(resUrl)
        })
        
    }
    useEffect( () => {
        axios.get(`http://82.146.55.48:3002/api/files/upload/${'1676846411242-any-name-About.png'}`,
        {responseType: "blob"}
        )
        .then ( res => {
            const resUrl = URL.createObjectURL(res.data)
            setUrl(resUrl)
        })
    }, [])
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <button onClick={() => sendFile(file)}>post</button>
            <button onClick={() => setEdit(true)}>загрузить</button>
            {
                edit && <label style={{ border: '1px solid red', height: '180px', width: '80px', display: 'flex' }} >
                    <input onChange={(e) => {
                        setFile(e?.currentTarget?.files?.[0])
                    }} type="file" />


                </label>

            }
            <button onClick={getFile}>get</button>
            <img src={url} alt="" />
        </div>
    )
}