import {   useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { stateUserType } from "../../store/UserReducer"
import { ProSettingsType, createWorkServiceTC, editWorkServiceTC, getWorkCategoriesTC } from "../../store/ProsettingsReducer"
import { createWorkWindowAC, editWorkServiceWindowAC } from "../../store/ModalWindowsReducer"
import { SuperButton } from "../../styles/components/SuperButton"
import { Box } from "../../styles/components/Box"
import { theme } from "../../styles/components/Theme.styled"
import { FlexBox } from "../../styles/components/FlexBox"
import { CheckBox } from "../../styles/components/CheckBox"
import { Text } from "../../styles/components/Text"
import { FormInputContainer } from "../../styles/components/FormInputContainer"
import { TextField } from "../../styles/components/TextField"
import { DropDownContainer } from "../../styles/components/DropDownContainer"
import { DropDownHeader } from "../../styles/components/DropDownHeader"
import { Icon } from "../../styles/components/Icon"
import { DropDownListContainer } from "../../styles/components/DropDownListContainer"
import { ListItem } from "../../styles/components/ListItem"
import { useEffect, useState } from "react"
import { createWorkFormType } from "../../apiTypes/formTypes/createWorkFormType"
import CurrencyInput from "react-currency-input-field"
import { convertNumberToTimeString } from "../../utils/utils"


const selectModalState = (state:AppRootState) => state.ModalWindowReducer
export const EditWorkWindow = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const [options, setOptions] = useState<boolean>(false)
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const modalState = useSelector(selectModalState)
    const proSettings = useSelector<AppRootState, ProSettingsType>(state => state.proSettings)
    const {workService} = modalState.editWorkServiceState
    const hideModal = (e: any) => {
        e.preventDefault()
        dispatch(editWorkServiceWindowAC({value: false, workService: null}))
    }
    const optionPlaceholder = "Категория работ"
    const {  handleSubmit, register, getValues,setValue, watch, formState: {errors}, setError, clearErrors } = useForm<createWorkFormType>({
        defaultValues: {
            isActive: workService?.isActive,
            isVidzhet: workService?.isVidzhet,
            isFree: workService?.isFree,
            categoryId: proSettings.workCategories.find(c => c.category === workService?.category)?.id,
            profit: workService?.profit,
            cost: workService?.cost
        }
    })
    const onSubmit = (data: createWorkFormType) => {
        if(!data.categoryId) {
            setError("categoryId",{message: 'Выберите категорию'})
            return
        }
        if(!data.cost){
            setError("cost",{message: "Введите стоимость"})
            return
        }
        if(!data.profit){
            setError("profit", {message: "Ведите сумму заработка"})
            return
        }
        if(data.profit > data.cost){
            setError("profit", {message: "Сумма заработка не может быть больше общей стоимости"})
            return
        }

        if(workService?.id)  dispatch(editWorkServiceTC(workService?.id, data))
    }

   
    const currencyInputHandler = () => {
        clearErrors("cost")
        clearErrors("profit")
    }
    
    const categoryHandler = (id: string) => {
        setValue("categoryId",id)
        setOptions(false)
        clearErrors("categoryId") 
    }
   
    useEffect(() => {
      if(!proSettings.workCategories.length)  dispatch(getWorkCategoriesTC())
    },[proSettings.workCategories.length])
    return (
        <>
            
            <Box as={"form"} direction="column" width="353px" height="max-content" padding="24px 32px" background={theme.colors.colorForm} borderRaduis="16px" boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)" position="fixed" top="50%" left="50%" transform="translateX(-50%) translateY(-50%)" zIndex={27} gap={20} onSubmit={handleSubmit(onSubmit)} >
                <h4>Редактирование работ - услуг</h4>
                <FlexBox align="center" direction="row" width="100%" gap={10}>
                    <CheckBox value = {watch("isActive")} render={() => ( <input   {...register("isActive")} type="checkbox"/> )} variant="switch"/>
                    <Text font="Inter"  color={theme.colors.fonts.main} size={14}>Статус услуги при добавлении</Text>
                </FlexBox>
                <FormInputContainer>
                    <Text color={theme.colors.fonts.main} size={14} font="Inter" >Название</Text>
                    <TextField defaultValue={workService?.name} {...register("name", {required: {value: true, message: "Введите название услуги"}})} placeholder="Название работ - услуг"/>
                    <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.name?.message}</Text>
                </FormInputContainer> 
                <FormInputContainer>
                    <Text color={theme.colors.fonts.main} size={14} font="Inter" >Категория и время выполнения</Text>
                    <FlexBox direction="row">
                    <DropDownContainer>
                            <DropDownHeader hover={theme.colors.colorForm} component = "work"  height={50} onClick={() => setOptions(prev => !prev)}>
                                <Text color={theme.colors.fonts.main} size={12} font="mont">{proSettings.workCategories.find(el => el.id === getValues("categoryId"))?.category  || optionPlaceholder}</Text>
                                <Icon svgID="row" width={16} height={3} />
                            </DropDownHeader>
                            {options &&
                                <DropDownListContainer component="work" borderRadius="8px" top={52} border="1px solid #444">
                                    {proSettings.workCategories.map(el =>
                                        <ListItem key={el.id} >
                                            <button key={el.id}  onClick={() => categoryHandler(el.id)}> {el.category}</button>
                                        </ListItem>

                                    )}
                                </DropDownListContainer>
                            }
                        </DropDownContainer>
                        <Box direction="row" position="relative">
                            <Box width="min-content" top="14px" left="10px" position="absolute">
                            <Icon transform="scale(0.6)" svgID="clock" width={22} height={22}/>
                            </Box>
                            <TextField defaultValue={convertNumberToTimeString(workService?.duration || 0)} paddinLeft={35} {...register("duration", {required: {value: true, message: "Необходимо ввести продолжительность сеанса"}})} type="time"/>
                        </Box>
                    </FlexBox>
                    <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.duration?.message}</Text>
                    <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.categoryId?.message}</Text>
                </FormInputContainer> 
                <FormInputContainer>
                    <Text color={theme.colors.fonts.main} size={14} font="Inter" >Стоимость работ - услуг и заработок</Text>
                    <FlexBox direction="row">
                        < CurrencyInput defaultValue={workService?.cost} style={{width: "100%", height: "50px", borderRadius: "8px", border:"1px solid rgba(228, 228, 228, 0.90)"}} placeholder="Стоимость 00р."   onChange={currencyInputHandler}   onValueChange={(value: any,name: any,values: any) => setValue("cost",values!.float as number)}  intlConfig={{ locale: 'ru', currency: 'RUR' }} />
                        <CurrencyInput defaultValue={workService?.profit} style={{width: "100%", height: "50px", borderRadius: "8px", border:"1px solid rgba(228, 228, 228, 0.90)"}} placeholder="Заработок 00р."  onChange={currencyInputHandler}   onValueChange={(value: any,name: any,values: any) => setValue("profit",values!.float as number)}  intlConfig={{ locale: 'ru', currency: 'RUR' }} />
                    
                    </FlexBox>
                    <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.cost?.message}</Text>
                    <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.profit?.message}</Text>
                </FormInputContainer> 
                <FlexBox direction="column" gap={10}>
                    <FlexBox height="20px" align="center" direction="row" gap={15}>
                        <CheckBox  render={() => <input {...register("isVidzhet")} type="checkbox" />} variant="primary" value={watch("isVidzhet")} />
                        <Text color={theme.colors.fonts.main} font="mont" size={14}> Использовать на виджите</Text>
                    </FlexBox>
                    <FlexBox height="20px" align="center" direction="row" gap={15}>
                        <CheckBox  render={() => <input {...register("isFree")} type="checkbox" />} variant="primary" value={watch("isFree")} />
                        <Text color={theme.colors.fonts.main} font="mont" size={14}> Бесплатная услуга</Text>
                    </FlexBox>
                </FlexBox>
                <FlexBox gap={10} direction="row" width="100%" height="42px">
                    <SuperButton varinat="outlined" onClick={hideModal}>Отменить</SuperButton>
                    <SuperButton varinat="contained" type="submit">Сохранить</SuperButton>
                </FlexBox>
            </Box>
        </>

    )
}


