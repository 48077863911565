import { css, styled } from "styled-components";

type propsType = {
    height?: number,
    hover?: string,
    svgHover?: string,
    component?: "work"
}
export const DropDownHeader = styled.div<propsType>`
    width: 120px;
height: ${props => props.height ? props.height + "px" : '36px'} ;

background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 8px;
font-size: 13px;
display: flex;
align-items: center;
justify-content: space-around;
    padding-left: 18px;

&:hover {
    background: ${props => props.hover ? props.hover : "#444"} ;
    color:  #fff;
    svg {
        fill: ${props => props.svgHover ? props.svgHover : "#fff"} ;
    }
};
${props => props.component && css<propsType>`
    &:hover {
        svg {
            fill: #262626;
        }
    }
`}
@media screen and (max-width: 768px) {
               display: none;
       };
`