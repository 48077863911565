import { addDays, endOfWeek, format, isBefore, startOfWeek } from "date-fns"
import { ru } from "date-fns/locale"



export type weekPeriodType = {
    date: Date,
    localeDate: string,
    dayName: string
}
export const timeTableHelper = {
    weekParser(date: Date):Array<weekPeriodType>{
        let arr:weekPeriodType[] = []
        let start = startOfWeek(date, {locale: ru})
        const end = endOfWeek(date, {locale: ru})
        
        while(isBefore(start, end)){
            arr.push({date: start, localeDate: start.toLocaleDateString(), dayName: format(start, "EEEE", {locale: ru})})
            start = addDays(start,1)
        }
        return arr

    },
    getMonthFromNumber(val: number):string{
        if(val === 1) {
            return "январь"
        } else if(val === 2){
            return "февраль"
        } else if(val === 3){
            return "март"
        } else if(val === 4){
            return "апрель"
        } else if(val === 5){
            return "май"
        } else if(val === 6){
            return "июнь"
        } else if(val === 7){
            return "июль"
        } else if(val === 8){
            return "август"
        } else if(val === 9){
            return "сентябрь"
        } else if(val === 10){
            return "октябрь"
        }else if(val === 11){
            return "ноябрь"
        }
        return "декабрь"
    }
}