import styled from "styled-components";
import { css } from "styled-components";

type propsType = {
    component?: "TimeTable" | "MobileTimeTable" | "any"
}

export const Table = styled.div<propsType>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
    ${props => props.component === "TimeTable" && css<propsType>`
        gap: 2px;
        @media screen and (max-width: 768px) {
                display: none;    
            }
    `};
    ${props => props.component === "MobileTimeTable" && css<propsType>`
    display: none;
    gap: 5px;
        @media screen and (max-width: 768px) {
                display: flex;    
            }
    `};
       
`