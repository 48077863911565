import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"
import { FlexBox } from "../styles/components/FlexBox"
import { TextField } from "../styles/components/TextField"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"
import { SuperButton } from "../styles/components/SuperButton"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { passwordRecoveryTC } from "../store/AuthReducer"
import { NavLink } from "react-router-dom"


const selectError = (state: AppRootState) => state.errorState
const selectAppState = (state: AppRootState) => state.appState
export const ForgotPassword = () => {
    const errorState = useSelector(selectError)
    const appState = useSelector(selectAppState)
    const dispatch: thunkDispatchRootType = useDispatch()
    const [email, setEmail] = useState('')
    const restorePassword = () => {
        dispatch(passwordRecoveryTC(email))
    }
    return (
        <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
            {
                appState.successMessagePassRecovery
                    ? <FlexBox direction="column">
                        <Text color="green" size={14} font="mont">{appState.successMessagePassRecovery}</Text>
                        <NavLink to={"/login"}>На главную</NavLink>
                    </FlexBox>
                    : <FlexBox align="center" justify="center" direction="column" width="325px" height="306px" gap={32}>
                        <FlexBox width="100%" align="start" direction="column" gap={14}>
                            <Text size={28} color={theme.colors.fonts.main} font="mont"> Забыли пароль?</Text>
                            <Text size={14} color={theme.colors.fonts.main} font="mont">Не переживайте - мы легко восстановим! </Text>
                        </FlexBox>
                        <FlexBox align="start" justify="start" width="100%" direction="column" gap={12}>
                            <Text font="mont" color={theme.colors.fonts.main} size={14}>Email</Text>
                            <TextField width="100%" value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="email" />
                        </FlexBox>
                        <FlexBox width="100%" align="center" justify="center" direction="column" gap={24}>
                            <SuperButton varinat="contained" height="36px" hover hoverColor={theme.colors.fonts.main} onClick={restorePassword}>Восстановить</SuperButton>
                            <div>
                                <Text font="mont" size={14} color={theme.colors.fonts.main}>Вспомнили? <Link to={'/login'}>Войдите</Link> </Text>
                            </div>
                        </FlexBox>
                        <Text color={theme.colors.fonts.errors} size={14} font="mont">{errorState.authEmailError}</Text>
                    </FlexBox>
            }

        </FlexBox>
    )
}