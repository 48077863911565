import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { Box } from "../styles/components/Box"
import { Column } from "../styles/components/Column"
import { FlexBox } from "../styles/components/FlexBox"
import { TableHeader } from "../styles/components/TableHeader"
import {Text} from "../styles/components/Text"
import { useEffect } from "react"
import { getWorkCategoriesTC } from "../store/ProsettingsReducer"
import { TableRow } from "../styles/components/TableRow"
import { theme } from "../styles/components/Theme.styled"
import { SuperButton } from "../styles/components/SuperButton"
import { Icon } from "../styles/components/Icon"
import { deleteWorkCategoryAC, editWorkCategoryWindowAC } from "../store/ModalWindowsReducer"
import { workCategoryType } from "../apiTypes/root/proSettings.type"


const selectWorkCategories = (state:AppRootState) => state.proSettings
export const WorkCategory = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const proSettings = useSelector(selectWorkCategories)

    const editHandler = (category: workCategoryType) => {
       dispatch(editWorkCategoryWindowAC({value: true, category})) 
    }
    const deleteHandler = (id: string) => {
        dispatch(deleteWorkCategoryAC({value: true,categoryId: id}))
    }
    useEffect(() => {
        if(!proSettings.workCategories.length) {
            dispatch(getWorkCategoriesTC())
        }
    },[proSettings.workCategories.length])
    return (
        <FlexBox gap={15}  direction="column" width="100%" height="100%" >
        <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
              
               <Column maxWidth="100%" axis="column" component="TimeTable">
                   <Text size={13} color="#fff" font="mont">Название категории</Text>
               </Column>
               <Column maxWidth="100%" axis="column" component="TimeTable">
                   <Text size={13} color="#fff" font="mont">Действие</Text>
               </Column>
               
           </TableHeader>
       
           <Box height="100%" width="100%" position="relative" >
               <Box gap={12} overFlowY="auto" position="absolute" width="100%" height="100%">
                   {
                       proSettings.workCategories.map(w =>
                          
                           
                               <TableRow minHeight="64px" key={w.id} component="Clients">
                                   
                                   
                                   <FlexBox direction="row" align="center" justify="center" basis="100%">
                                       <Text size={12} font="mont" color={theme.colors.fonts.main}>{w.category}</Text>
                                   </FlexBox>
                                   
                                   <FlexBox direction="row" align="center" justify="center" basis="100%" gap={8}>
                                       <SuperButton varinat="sort" onClick={() => editHandler(w)}>
                                           <Icon svgID="edit" width={18} height={16} />
                                       </SuperButton>
                                       <SuperButton varinat="sort" onClick={() => deleteHandler(w.id)}>
                                           <Icon svgID="delete" width={18} height={16} />
                                       </SuperButton>
                                   </FlexBox>
                               </TableRow>
                              
                       )
                   }
               </Box>
           </Box>
   </FlexBox>
    )
}