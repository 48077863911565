import { Navigate, Outlet } from "react-router-dom";
import { AppRootState } from "./store/storeRedux";
import { useSelector } from "react-redux";

const selectUser = (state: AppRootState) => state.user

export default function ProtectedRoutes() {
  const user = useSelector(selectUser)
 
  return (user.isAuth && user.user?.completeRegisterData.firstLevel &&  user.user.completeRegisterData.secondLevel) 
  ? <Outlet/>
  : <Navigate to="/" />;
}