import styled, { css } from "styled-components";

type propsType = {
    width?:string,
    height?:string,
    minHeight?:string,
    padding?:string,
    gap?:number,
    position?: "relative" | "absolute" | "static",
    noneTransform?: boolean,
    top?:string,
    left?:string,
    component?: "newClient" | "addNewClientClient" | "addNewClientSign" | "search",
    activeComponent?:boolean
}

export const FormBox = styled.div<propsType>`
    width: ${props => props.width ? props.width : "auto"};
    height: ${props => props.height ? props.height : "auto"};
    ${props => props.minHeight && css<propsType>`
        min-height: ${props => props.minHeight};
    `};
    ${props => props.component === "newClient" && css<propsType>`
        @media screen and (max-width: 768px) {
            width: 340px;
            padding: 23px;
            gap: 5px;
         };
            
    `};
    ${props => props.component === "search" && css<propsType>`
        @media screen and (max-width: 768px) {
            height: 420px;
            width: 360px;
         };
            
    `};
   
    background: #f8f8f8;
    padding: 10px;
    position: ${props => props.position ? props.position : "fixed"};
    top: ${props => props.top ? props.top : "50%"};
    left: ${props => props.left ? props.left : "50%"};
    transform: ${props => props.noneTransform ? "none" : "translateX(-50%) translateY(-50%)"} ;
    z-index: 50;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${props => props.padding && css<propsType>`
        padding: ${props => props.padding};
    `};
    ${props => props.gap && css<propsType>`
        gap: ${props => props.gap + "px"};
    `};
    .btnNext {
            display:none
        };
        .rowBack {
            display: none;
        }
        .addNewClientErrorClient {
            opacity: 0
        }
       
    @media screen and (max-width: 768px) {
        ${props => ( props.component === "addNewClientSign") && css<propsType>`
            min-height: 660px;
            width: 360px;
        `}
        ${props => (props.component === "addNewClientClient") && css<propsType>`
            width: 340px;
            padding: 23px;
            gap: 5px;
        `}
        .btnNext {
            display:flex
        };
        .addNewClientErrorClient {
            opacity: 1
        };
        .rowBack {
            display: flex;
            height: 16px;
            svg {
                fill:#1C2E45;
                fill-opacity:0.6
            }
             &:hover button{
                background:#444;
                border-radius:4px;
                height: 12px;
                cursor: pointer;
                svg {
                    fill: #fff;
                    fill-opacity:1
                }
            }
        }
           ${props => props.component === "addNewClientSign" && css<propsType>`
                display: ${props  => props.activeComponent ? "flex" : "none"};
           `}
           ${props => props.component === "addNewClientClient" && css<propsType>`
                display: ${props  => props.activeComponent ? "flex" : "none"};
           `}
         };
   
`