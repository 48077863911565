import { useState } from "react";
import { Icon } from "../styles/components/Icon";
import { SuperButton } from "../styles/components/SuperButton";
import c from "./notif.module.css";
import { NotifSettings } from "./NotifSettings";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "../styles/components/Avatar";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { FlexBox } from "../styles/components/FlexBox";
import { deactivateClientReminderTC } from "../store/UserPro.reducer";

export type btnsArrayType = {
  title: string;
  cb: () => void;
};

type showSettingType = {
    id: string,
    value: boolean
}
const selectUserPro = (state: AppRootState) => state.userProState;
export const NotifBlock = () => {
    const dispatch:thunkDispatchRootType =  useDispatch()
  const [isSetting, setIsSetting] = useState<showSettingType>({value: false, id: ""});
  const userPro = useSelector(selectUserPro);
  const deactivateNotif = () => {
    dispatch(deactivateClientReminderTC(isSetting.id))
  };
  const btnsArray: btnsArrayType[] = [
    { title: "Удалить уведомление", cb: deactivateNotif },
  ];

  const settingHandler = (id: string) => {
    if(isSetting.value && (isSetting.id === id)){
        setIsSetting({value: false, id: ""})
    } else {
        setIsSetting({value: true, id})
    }
  }
  return (
    <div
      style={{
        marginTop: "70px",
        zIndex: "37",
        position: "absolute",
        width: "590px",
        height: "619px",

        left: "40%",
        top: "0",
        borderRadius: "8px",
        background: "rgb(255, 255, 255)",
        boxShadow: "1px 2px 9px #c6c6c6",
        padding: "50px 35px",
      }}
    >
      {userPro.notifications.clientReminders.map((r) => (
        <div
          className={c.notifBox}
          style={{
            width: "100%",
            height: "87px",
            borderRadius: "4px",
            background: "#f8f8f8",
            padding: "7px",
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          {isSetting && r.id === isSetting.id && <NotifSettings data={btnsArray} />}
          <div
            style={{
              width: "15%",
              height: "auto",
              boxSizing: "border-box",
              border: "4px solid rgb(177, 81, 129)",
              borderRadius: "93px",
              background: "url()",
            }}
          >
            <Avatar src={r.client.photoURL} size="adaptive" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "60%",
            }}
          >
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Напоминание на {new Date(r.eventDate).toLocaleDateString()}
            </Text>
            <Text color={theme.colors.fonts.main} size={14} font="mont">
              Клиент:{" "}
              {`${r.client.surname} ${r.client.name} ${r.client.patronymic}`}
            </Text>
            <FlexBox style={{wordBreak: "break-all", flexWrap: "wrap"}}  width="100%" direction="row" >
              <Text  color={theme.colors.fonts.main} size={13} font="mont">{r.comment}</Text>
            </FlexBox>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <SuperButton
              onClick={() => settingHandler(r.id)}
              isAllowed
              varinat="moreOptions"
            >
              <Icon svgID="3points" width={36} height={36} />
            </SuperButton>
          </div>
        </div>
      ))}
    </div>
  );
};
