import { useDispatch, useSelector } from "react-redux"
import { calendarStateType } from "../../store/CalendarReducer"
import { showModalForUpdateDayAC } from "../../store/ModalWindowsReducer"
import { AppRootState } from "../../store/storeRedux"
import { ButtonsForChange } from "./ButtonsForChange"
import { Box } from "../../styles/components/Box"
import { ModalLayer } from "../../styles/components/ModalLayer"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"
import { theme } from "../../styles/components/Theme.styled"


const selectModalState = (state: AppRootState) => state.ModalWindowReducer
export const ChangeWindow = () => {
    const dispatch = useDispatch()
    const modalState = useSelector(selectModalState)
    const date = useSelector<AppRootState, calendarStateType>(state => state.calendarState)
    return (

        <>
            {
                !modalState.timePickerWindow &&
                <>
                    <ModalLayer onClick={() => dispatch(showModalForUpdateDayAC(false))} />
                    <Box className="btnForChange" position="absolute" zIndex={30} height="max-content" width="max-content" top="0" left="0px">

                        <FlexBox direction="column" width="350px" height="211px" background="#fff" borderRadius="16px" align="center" padding="10px">
                            <FlexBox direction="column" gap={5} align="center">
                                <Text font="mont" size={14} color={theme.colors.fonts.main}>Изменить график работы на </Text>
                                <Text font="mont" size={14} color={theme.colors.fonts.main}>{date.date ? new Date(date.date).toLocaleString("ru", { day: "2-digit", month: "long", year: "numeric" }) : ""}</Text>
                            </FlexBox>


                            <ButtonsForChange />



                        </FlexBox>
                    </Box>
                </>
            }
        </>




    )
}