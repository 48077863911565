import axios from "axios";
import { clientsStateType } from "../store/ClientsReducer";
import { clientUpdateRequestType, clientUpdateViewType } from "../apiTypes/responses/clientResponsesType";
import { tokenService } from "./tokenService";
import { apiAuth } from "./apiAuth";



const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/clients`,
    withCredentials: true,
   
})


instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
        
    },
    (error) => Promise.reject(error)
)
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
           
        }
      } else {
        
      }
      return Promise.reject(error);
    }
  );

export const apiClients = {
    createClient(photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean){
        const formData = new FormData()
        formData.append('clientAvatar', photo)
        formData.append('name', name)
        formData.append('surname', surname)
        formData.append('patronymic', patronymic)
        if (phone) formData.append('phone', phone)
        formData.append('gender', gender)
        if (birthday) formData.append('birthday', birthday?.toISOString())
        formData.append('agreement', JSON.stringify(agreement))
        return instance.post<clientsStateType>("/add", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    },
    getClients(){
       return instance.get<Array<clientsStateType>>("/", {
       })
    },
    removeClient(id: string){
        return instance.delete<string>(`/delete/${id}`)
    },
    updateClient(id:string, data: clientUpdateRequestType ) {
        return instance.put<clientUpdateViewType>(`/update/${id}`, data)
    }
   
    
}