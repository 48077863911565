import { useDispatch, useSelector } from "react-redux"
import { ModalWindowStateType } from "../store/ModalWindowsReducer"
import { stateUserType } from "../store/UserReducer"
import { AppRootState } from "../store/storeRedux"
import {  fetchTeamMatesTC, rightsType } from "../store/TeamMateReducer"
import { useEffect } from "react"
import { Table } from "../styles/components/Table"
import { TableHeader } from "../styles/components/TableHeader"
import { Column } from "../styles/components/Column"
import { TableRowBox } from "../styles/components/TableRowBox"
import { TableRow } from "../styles/components/TableRow"
import { Cell } from "../styles/components/Cell"
import { Avatar } from "../styles/components/Avatar"
import { DragDown } from "../styles/components/DragDown"
type propsType = {
    // modalState:ModalWindowStateType | null
    // user: stateUserType
}

const selectTeamMates = (state:AppRootState) => state.teamMates

export const TeamMates = () => {
    const dispatch = useDispatch()
    const teamMates = useSelector(selectTeamMates)

    const columnItems = [
        {title: "ФИО"},
        {title: "Клиенты"},
        {title: "Расписание"},
        {title: "Аналитика"},
        {title: "Настройки"},
        {title: "Действие"},
    ]
   
    const getRightElement = (name: "clients" | "timetable" | "settings" | "analytic", rights:rightsType[]) => {
        const result = rights.find(item => item.name === name)
        return result
    }
    useEffect(() =>{
        // if(props.user.user?._id) dispatch(fetchTeamMatesTC(props.user.user._id as string))
 
     }, [JSON.stringify(teamMates)])
   
    return (
        <div >
            {/* {props.modalState && props.modalState.modeModalTeamMate && <WindowForTeamMate />} */}
           <h1>team</h1>
            <Table>
                <TableHeader type="static" offset="small">
                    {columnItems.map(el => <Column  component="any">{el.title}</Column>)}
                </TableHeader>
            </Table>
            <TableRowBox>
                {
                    teamMates.teamMates.map(el =>
                        <TableRow>
                            <Cell variant="avatar">
                                <Avatar src={el.user.avatarUrl} size="small"/>
                                <div>
                                    <span>{el.user.lastName}</span>
                                    <span>{el.user.firstName}</span>
                                    <span>{el.user.thirdName}</span>
                                </div>
                                {/* <DragDown/> */}
                                 <DragDown teamMateID={el.user._id} element={getRightElement("clients",el.rights)}/>
                                 <DragDown teamMateID={el.user._id} element={getRightElement("timetable",el.rights)}/>
                                 <DragDown teamMateID={el.user._id} element={getRightElement("analytic",el.rights)}/>
                                 <DragDown teamMateID={el.user._id} element={getRightElement("settings",el.rights)}/>
                            </Cell>
                        </TableRow>
                        )
                }
            </TableRowBox>

           
        </div>
    )

}