import { useForm } from "react-hook-form"
import { ButtonContainer } from "../../styles/components/ButtonContainer"
import { SimpleForm } from "../../styles/components/SimpleForm"
import { SuperButton } from "../../styles/components/SuperButton"
import { TextField } from "../../styles/components/TextField"
import { Title } from "../../styles/components/Title"
import { TSearchTeammateForm } from "../../apiTypes/formTypes/searchTeammateType"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useEffect, useState } from "react"
import { addTeamMateAC, showCreateTeammateAC } from "../../store/ModalWindowsReducer"
import sprite from "../../images/sprite.svg"
import { IconContainer } from "../../styles/components/IconContainer"
import { TextContainer } from "../../styles/components/TextContainer"
import { Avatar } from "../../styles/components/Avatar"
import { sendTeamRequestTC } from "../../store/NotificationReducer"
import { Box } from "../../styles/components/Box"
import { theme } from "../../styles/components/Theme.styled"
import {  searchUserForTeamAC, searchUserForTeamTC } from "../../store/ProsettingsReducer"
import { socketService } from "../../apiServer/socketService"


const selectProSettings = (state: AppRootState) => state.proSettings
const selectUser = (state:AppRootState) => state.user
export const SearchTeamMateWindow = () => {
    const user = useSelector(selectUser)
    const proSettingsState = useSelector(selectProSettings)
    const [searchMode, setSearchMode] = useState<boolean>(true)
    const dispatch:thunkDispatchRootType = useDispatch()
    const { register, handleSubmit } = useForm<TSearchTeammateForm>()
    const showCreatingTeammateWindow = (e: any) => {
        e.preventDefault()
        dispatch(addTeamMateAC(false))
        dispatch(showCreateTeammateAC(true))
    }

    const search = (data: TSearchTeammateForm) => {
        dispatch(searchUserForTeamTC(data.profileID))
    }
    const sendMessage = (e:any) => {
        e.preventDefault()
        socketService.inviteUser(proSettingsState.searchUser?.id as string)
    }
    useEffect(() => {
       
      return  () => {
        dispatch(searchUserForTeamAC(null))
    } 
    }, [])
    useEffect(() => {
        if(proSettingsState.searchUser){
            setSearchMode(false)
        } 
    },[proSettingsState.searchUser])
    return (
        <Box as={"form"} onSubmit={handleSubmit(search)} width="362px" height="244px" borderRaduis="16px" background={theme.colors.colorForm} direction="column" position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex={29} style={{alignItems:"center",justifyContent:"start"}} padding="34px 24px" gap={20} >
            <Title size={14}>Введите ID Сотрудника</Title>
            <>
                {
                    proSettingsState.searchUser
                        ? <>
                            <TextField gap={20} height="64px" width="320px" as="div">
                                
                                    <Avatar size="large" src={proSettingsState.searchUser.avatarUrl} />
                    
                                <TextContainer width={207}>
                                    <Title size={18}>{`${proSettingsState.searchUser.firstName} ${proSettingsState.searchUser.lastName} ${proSettingsState.searchUser.thirdName}`}</Title>
                                </TextContainer>
                            </TextField>
                            <ButtonContainer width={100}>
                                <SuperButton onClick={sendMessage} varinat="contained">Добавить</SuperButton>
                            </ButtonContainer>
                        </>
                        : searchMode
                            ? <>
                                <TextField {...register("profileID")} width="100%" placeholder="123-ABS" />
                                <ButtonContainer width={100}>
                                    <SuperButton type="submit" varinat="contained">Найти</SuperButton>
                                </ButtonContainer>
                            </>
                            : <>
                                <TextField gap={20} height="64px" width="320px" as="div">
                                    <IconContainer width={51} height={51}>
                                        <use xlinkHref={`${sprite}#refuse`}></use>
                                    </IconContainer>
                                    <TextContainer width={154}>
                                        <Title size={18}>Сотрудника нет в системе</Title>
                                    </TextContainer>
                                </TextField>
                                <ButtonContainer width={161}>
                                    <SuperButton onClick={showCreatingTeammateWindow} varinat="contained">Добавить нового</SuperButton>
                                </ButtonContainer>
                            </>
                }
            </>

        </Box>
    )
}