import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useForm } from "react-hook-form";

import { remindWindowAC } from "../../store/ModalWindowsReducer";
import { CustomSelect } from "../../styles/components/CustomSelect";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { Icon } from "../../styles/components/Icon";
import { Avatar } from "../../styles/components/Avatar";
import { FakeInput } from "../../styles/components/FakeInput";
import { TextField } from "../../styles/components/TextField";
import { FlexBox } from "../../styles/components/FlexBox";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { FormInputContainer } from "../../styles/components/FormInputContainer";
import { CheckBox } from "../../styles/components/CheckBox";
import { RadioInput } from "../../styles/components/Radio";
import { clientReminderFormType } from "../../apiTypes/formTypes/clientReminderFormType";
import { SimpleCalendarWindow } from "./SimpleCalendarWindow";
import { addReminderForClientTC } from "../../store/RemindersReducers";

const selectModalState = (state: AppRootState) => state.ModalWindowReducer;
const selectUser = (state: AppRootState) => state.user;
export const RemindWindow = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const [date, setDate] = useState<Date | null>(null);
  const [calendar, showCalendar] = useState<boolean>(false);
  const modalState = useSelector(selectModalState);
  const { client } = modalState.remindWindowState;

  const user = useSelector(selectUser);
  const cancel = (e: any) => {
    e.preventDefault();
    dispatch(remindWindowAC({ value: false, client: null }));
  };

  const {
    watch,
    handleSubmit,
    setValue,
    register,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<clientReminderFormType>({
    defaultValues: {
      clientId: client?.id,
      userId: user.user!.id,
    },
  });

  const defineDate = () => {
    showCalendar(true);
  };
  const onSubmit = (data: clientReminderFormType) => {
    if (!data.clientId) {
      setError("clientId", { message: "Выберите клиента" });
      return;
    }
    if (!data.userId) {
      setError("userId", { message: "Выберите мастера" });
      return;
    }
    if (!data.eventDate) {
      setError("eventDate", { message: "Выберите дату" });
      return;
    }
    if (!data.reminderDate) {
      setError("reminderDate", { message: "Выберите дату напоминания" });
    }
    if(new Date(data.eventDate) <= new Date()){
      setError("eventDate", {message: "Дата напоминания должна быть позже текущей даты"})
    }
    dispatch(addReminderForClientTC(data))
  };

  useEffect(() => {
    const sub = watch((value, { name, type }) => {
      if (value || name || type) {
        clearErrors();
        return;
      }
    });
    return () => sub.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if(date) setValue("eventDate", new Date(date.toLocaleDateString().split(".").reverse().join("-")).toISOString() )
  },[date])
  return (
    <Box
      padding="30px 40px"
      zIndex={27}
      background={theme.colors.colorForm}
      borderRaduis="8px"
      width="423px"
      height="max-content"
      position="fixed"
      transform="translate(-50%,-50%)"
      top="50%"
      left="50%"
      gap={20}
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      {calendar && (
        <SimpleCalendarWindow
          setDate={setDate}
          showCalendar={showCalendar}
          inModal
        />
      )}
      <FlexBox direction="row" width="100%" align="center" justify="center">
        <Text font="mont" size={18} color={theme.colors.fonts.main}>
          Добавить Напоминание
        </Text>
      </FlexBox>
      <FakeInput active={true}>
        <FlexBox direction="row" align="center" justify="center" width="40px">
          <Avatar size="newEntry" src={client?.photoURL} />
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Text
            color="#fff"
            size={14}
            font="mont"
          >{`${client?.surname} ${client?.name} ${client?.patronymic}`}</Text>
          <Text color="#fff" size={14} font="mont">
            {client?.phone}
          </Text>
        </FlexBox>
      </FakeInput>
      <FormInputContainer>
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Дата события
        </Text>
        <FlexBox direction="row" justify="space-between">
          <FlexBox direction="row" gap={10}>
            <RadioInput
              label="За день"
              child={
                <input
                  onChange={() => setValue("reminderDate", 1)}
                  name="reminderDate"
                  type="radio"
                />
              }
            />
            <RadioInput
              label="За два"
              child={
                <input
                  name="reminderDate"
                  onChange={() => setValue("reminderDate", 2)}
                  type="radio"
                />
              }
            />
          </FlexBox>
          <FlexBox
            onClick={defineDate}
            width="104px"
            height="50px"
            background="#fff"
            direction="row"
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
            align="center"
            justify="space-around"
          >
            {date ? (
              <Text
                color={theme.colors.fonts.main}
                size={13}
                font="mont"
              >
                {date.toLocaleDateString()}
              </Text>
            ) : (
              <>
                <Icon svgID="calendar2" width={16} height={16} />
                <Text color={theme.colors.fonts.main} font="mont" size={14}>
                  Дата
                </Text>
              </>
            )}
          </FlexBox>
        </FlexBox>
      </FormInputContainer>
      <FormInputContainer>
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Специалист и услуга
        </Text>
        <FlexBox width="100%" direction="row" justify="space-between">
          <FlexBox justify="center" align="center" direction="row" gap={20}>
            <CheckBox
              value={watch("isPersonal")}
              variant="primary"
              render={() => (
                <input
                  checked={watch("isPersonal")}
                  onChange={(e) =>
                    setValue("isPersonal", e.currentTarget.checked)
                  }
                  type="checkbox"
                />
              )}
            />
            <Text color={theme.colors.fonts.main} size={13} font="mont">
              Личное напоминание
            </Text>
          </FlexBox>
          <CustomSelect />
        </FlexBox>
      </FormInputContainer>
      <FormInputContainer>
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Комментарии
        </Text>
        <TextField
          textIndent="10px"
          height="85px"
          as={"textarea"}
          {...register("comment")}
        ></TextField>
      </FormInputContainer>
      <FlexBox
        marginTop="15px"
        direction="row"
        gap={12}
        height="50px"
        width="100%"
      >
        <SuperButton onClick={cancel} varinat="outlined">
          Отменить
        </SuperButton>
        <SuperButton type="submit" varinat="contained">
          Создать
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
