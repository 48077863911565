import CurrencyInput from "react-currency-input-field";
import { Avatar } from "../../styles/components/Avatar";
import { Box } from "../../styles/components/Box";
import { FakeInput } from "../../styles/components/FakeInput";
import { FlexBox } from "../../styles/components/FlexBox";
import { FormInputContainer } from "../../styles/components/FormInputContainer";
import { Text } from "../../styles/components/Text";
import { theme } from "../../styles/components/Theme.styled";
import { SuperButton } from "../../styles/components/SuperButton";
import { useForm } from "react-hook-form";
import { RadioInput } from "../../styles/components/Radio";
import { clientBalanceFormType, methodPay } from "../../apiTypes/formTypes/clientBalanceForm.type";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { clientBalanceWindowAC } from "../../store/ModalWindowsReducer";
import { changeClientBalanceTC } from "../../store/ClientCardReducer";


const selectModalState = (state: AppRootState) => state.ModalWindowReducer
export const ClinetBalanceWindow = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const modalState = useSelector(selectModalState)

    const {client} = modalState.clientBalanceWindow
  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<clientBalanceFormType>({
    defaultValues: {
        clientId: client?.id
    }
  });

  const currencyInputHandler = () => clearErrors("balance")


  const onSubmit = (data: clientBalanceFormType) => dispatch(changeClientBalanceTC(data))
  const hideModal = (e:any) => {
    e.preventDefault()
    dispatch(clientBalanceWindowAC({value: false, client: null}))
  } 
  return (
    <Box
      as={"form"}
      direction="column"
      width="353px"
      height="max-content"
      padding="24px 32px"
      background={theme.colors.colorForm}
      borderRaduis="16px"
      boxShadow="0px 12px 24px rgba(0, 0, 0, 0.12)"
      position="fixed"
      top="50%"
      left="50%"
      transform="translateX(-50%) translateY(-50%)"
      zIndex={27}
      gap={20}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4>Пополнение баланса</h4>

      <FormInputContainer>
        <FakeInput active={true}>
          <FlexBox direction="row" align="center" justify="center" width="40px">
            <Avatar size="newEntry" src={client?.photoURL} />
          </FlexBox>
          <FlexBox direction="column" width="100%">
            <Text
              color="#fff"
              size={14}
              font="mont"
            >{`${client?.surname} ${client?.name} ${client?.patronymic}`}</Text>
            <Text color="#fff" size={14} font="mont">
              {client?.phone}
            </Text>
          </FlexBox>
        </FakeInput>
      </FormInputContainer>

      <FormInputContainer>
        <Text color={theme.colors.fonts.main} size={14} font="Inter">
          Укажите сумму и метод пополнения
        </Text>
        <FlexBox direction="row">
          <CurrencyInput
            style={{
              width: "100%",
              height: "50px",
              borderRadius: "8px",
              border: "1px solid rgba(228, 228, 228, 0.90)",
            }}
            placeholder="Стоимость 00р."
            onChange={currencyInputHandler}
            onValueChange={(value: any, name: any, values: any) =>
              setValue("balance", values!.float as number)
            }
            intlConfig={{ locale: "ru", currency: "RUR" }}
          />
        </FlexBox>
        <FlexBox gap={10} width="100%" direction="row">
        <FlexBox width="100%" padding="10px 5px" background="#fff" direction="row" borderRadius="8px" border="1px solid rgba(228, 228, 228, 0.9)">
        <RadioInput
        labelSize={12}
         width={18}
         height={18}
         variant="square"
            child={
              <input
                name="method"
                onChange={() => setValue("methodPay", methodPay.CASH)}
                type="radio"
              />
            }
            label="наличными"
          />
        </FlexBox>

        <FlexBox width="100%" padding="10px 5px" background="#fff" direction="row" borderRadius="8px" border="1px solid rgba(228, 228, 228, 0.9)">
        <RadioInput
        labelSize={12}
         width={18}
         height={18}
         variant="square"
            child={
              <input
                name="method"
                onChange={() => setValue("methodPay", methodPay.CARD)}
                type="radio"
              />
            }
            label="картой"
          />
        </FlexBox>
          
        <FlexBox width="100%" padding="10px 5px" background="#fff" direction="row" borderRadius="8px" border="1px solid rgba(228, 228, 228, 0.9)">
        <RadioInput
        labelSize={12}
        width={18}
        height={18}
        variant="square"
            child={
              <input
                name="method"
                onChange={() => setValue("methodPay", methodPay.ONLINE)}
                type="radio"
              />
            }
            label="онлайн"
          />
        </FlexBox>

         
        </FlexBox>
        <Text color={theme.colors.fonts.errors} font="mont" size={12}>
          {errors.methodPay?.message}
        </Text>
      </FormInputContainer>

      <FlexBox gap={10} direction="row" width="100%" height="42px">
        <SuperButton varinat="outlined" onClick={hideModal}>
          Отменить
        </SuperButton>
        <SuperButton varinat="contained" type="submit">
          Сохранить
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
