
import ReactDOM from 'react-dom';
import { ModalLayer } from '../styles/components/ModalLayer';
import { Preloader } from '../styles/components/PreLoader';
import { Box } from '../styles/components/Box';


export default function ProgressBar() {
  return ReactDOM.createPortal(
    <>
      <ModalLayer />
      <Box position='fixed' zIndex={30} height='100%' top='50%' left='50%' transform='translate(-50%,-50%)'>
        <Preloader />
      </Box>

    </>, document.body

  );
}