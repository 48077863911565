

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalWindowStateType, removeWorkServiceWindowAC, showRmNoteWindowAC } from "../../store/ModalWindowsReducer";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { FlexBox } from "../../styles/components/FlexBox";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { deleteWorkServiceTC } from "../../store/ProsettingsReducer";



export const RemoveWorkServiceWindow = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)

    const remove = () => {

        dispatch(deleteWorkServiceTC(modalState.removeWorkState.id))
        dispatch(removeWorkServiceWindowAC({value: false, id: ""}))
    }
    const cancel = () =>  dispatch(removeWorkServiceWindowAC({value: false, id: ""}))

    return (
        <Box className="removeNote" width="453px" height="218px" background={theme.colors.colorForm} position="absolute" borderRaduis="8px" direction="row" top="33%" left="33%" zIndex={37}>
            <FlexBox direction="column">
                <FlexBox direction="column" gap={4}>
                    <Text className="quest" font="mont" color={theme.colors.fonts.main} size={18}>
                        Вы уверены, что хотите удалить работу - услугу?
                    </Text>
                    <Text className="warn" font="mont" color={theme.colors.fonts.main} size={14}>
                        У вас не будет возможности восстановить эти даннные
                    </Text>

                </FlexBox>
                <FlexBox marginTop="12px" width="100%" direction="row" gap={12} height="48px">
                    <SuperButton onClick={remove} varinat="outlined">Удалить</SuperButton>
                    <SuperButton onClick={cancel} varinat="contained">Отменить</SuperButton>
                </FlexBox>
            </FlexBox>

        </Box>
    )
}