
export type stateSettingsType = {
    needMonth: Array<currentMonthDataType>,
    needPeriod: string
}

export type currentMonthDataType = {
    dayOrder: number,
    dateCurrentDay: string
}

type actionsSettingsType = setMonthObjectType 
type setMonthObjectType = {
    type: 'SET-MONTH-WORK',
    daysArray: currentMonthDataType,
    needPeriod: string
}



const initState = null

export const SettingReducer = ( state: stateSettingsType | null = initState, action: actionsSettingsType) => {
    switch(action.type) {
        case 'SET-MONTH-WORK': 
            return {...state, needMonth: action.daysArray, needPeriod: action.needPeriod }
       
        default :
            return state
    }
        
}

export const setMonthForSettingsAC = (daysArray: currentMonthDataType[], needPeriod: string) => {
    return {type: 'SET-MONTH-WORK',  daysArray, needPeriod}
}

