import { css } from "styled-components";
import { styled } from "styled-components";
import { theme } from "./Theme.styled";


type propsType = {
    component?:"newEntry" | "newEntry-client",
    border?:string,
    height?: number,
    borderRadius?: number,
    padding? :string,
    disabled?:boolean,
    justify?: "center" | "end" | "start" | "space-between",
}
export const ListItem = styled.li<propsType>`
    display: flex;
    list-style: none;
    width: 100%;
    height: ${props => props.height ? props.height + "px" : "36px"};
    font-family: 'mont';
    background: #fff;
    font-size: 12px;
    align-items: center;
    &:hover {
        button {
                color: #fff;
            }
    }  
    .tooltip {
        display:none
    };
    justify-content: ${props => props.justify ? props.justify : "center"};
    ${props => props.disabled && css<propsType>`
            .tooltip {
                width: 112px;
                height: 30px;
                background: #5d5ffe;
                position: absolute;
                border-radius: 8px;
                z-index: 5;
                left: 124px;
                display: none;
                font-size: 10px;
            };
            &:hover {
                .tooltip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .tooltip::after {
                content: "";
                background: #5d5ffe;
                position: absolute;
                transform: rotate(45deg);
                width: 10px;
                height: 11px;
                top: 23px;
                left: 92px;
                z-index: -1;
                color: #fff;
            }
        `}
    ${props => props.border && css<propsType>`
        border: ${props => props.border};
    `};
    ${props => props.padding && css<propsType>`
        padding: ${props => props.padding};
    `};
    ${props => props.borderRadius && css<propsType>`
        border-radius: ${props => props.borderRadius +"px"};
    `};
    ${props => props.component === "newEntry" && css<propsType>`
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(68, 68, 68, 1);
        height: 24px;
        font-size: 12px;
        gap: 20px;
        padding: 10px 0;
        position: relative;
       
        svg{
            fill: #444444;
        };
        &:hover  {
            background: ${theme.colors.main};
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            
            color: #fff;
            cursor: pointer;
            svg {
                fill: white;
            }
           
        }
    `};
    ${props => props.component === "newEntry-client" && css<propsType>`
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding-left: 6px;
        gap: 6px;
        justify-content: flex-start;
       
        &:hover {
            background: rgba(38, 38, 38, 0.5);
            
        };
        &:active{
            background: blue;
        }
    `}

`