import { AxiosError, isAxiosError } from "axios"
import { apiFreeTime } from "../apiServer/apiFreeTimes"
import { workTimeRenderType } from "../apiTypes/state/workTime-type"
import { logoutTC } from "./AuthReducer"
import { notesStateType } from "./NotesReducer"
import { thunkActionRootType } from "./storeRedux"
import { setIsLoadingAC } from "./AppReducer"
import { apiWorkTimes } from "../apiServer/apiWorkTimes"
import { apiUserPro } from "../apiServer/apiUserPro"


export type dateAndTimeForQueueType = {date: string, time: string, duration: number }
export type sessionsType = {
    start: string,
    end: string,
    date: string,
    selectedDate: string,
    selectedTime: string,
    selectedDuration: string,
    freeTimeForWeek: workTimeRenderType[],
    freeTimeForDay: workTimeRenderType[],
    endOfEntryState: maxEndOfEntryState | null,
    firstDateAndTimeForQueue: dateAndTimeForQueueType

}
export type maxEndOfEntryState = {
    endOfDay:number, beginNextEntry: number
}
export type actionSessionType = setSessionType | setFreeTimeForWeekAT | setDateAndTimeForNoteAT | setFreeTimeForDayAT | setEndOfDay
 | setFirstFreeTimeAndDateForNoteAT
type setSessionType = {
    type: 'SET-SESSION',
    notes: notesStateType[]
}
type setFreeTimeForWeekAT = {
    type: "SESSION/FREE-TIME-WEEK",
    items: workTimeRenderType[]
}
type setFreeTimeForDayAT = {
    type: "SESSION/FREE-TIME-DAY",
    items: workTimeRenderType[]
}
type setDateAndTimeForNoteAT = {
    type: "SESSION/DATE-TIME-FOR-NOTE",
    date: string,
    time: string,
    duration: string
}
type setEndOfDay = {
    type: "SESSION/SET-END-OF-DAY",
    payload: maxEndOfEntryState | null
}
type setFirstFreeTimeAndDateForNoteAT = {
    type: "SESSION/SET-FIRST-FREE-DATE-AND-TIME-FOR-NOTE",
    payload: dateAndTimeForQueueType
}
const initState:sessionsType = {
    start: "",
    end: "",
    date: "",
    selectedDate: "",
    selectedTime: "",
    selectedDuration: "",
    freeTimeForWeek: [],
    freeTimeForDay: [],
    endOfEntryState: null,
    firstDateAndTimeForQueue: {date: "", time: "", duration: 0}
}
export const SessionsReducer = (state: sessionsType = initState, action: actionSessionType):sessionsType => {
    switch (action.type) {
        case 'SET-SESSION': 
        // let arr:sessionsType[] = []
            
        //      action.notes.map((n: notesStateType) => arr.push({ start: n.time, end: n.duration, date: n.date }))
        //      state = [...arr]
             return state
        case "SESSION/FREE-TIME-WEEK":
            return {...state, freeTimeForWeek: action.items}
        case "SESSION/FREE-TIME-DAY":
            return {...state, freeTimeForDay: action.items}
        case "SESSION/DATE-TIME-FOR-NOTE":
            return {...state, selectedDate: action.date, selectedTime: action.time, selectedDuration: action.duration}
        case "SESSION/SET-END-OF-DAY":
            return {...state, endOfEntryState: action.payload}
        case "SESSION/SET-FIRST-FREE-DATE-AND-TIME-FOR-NOTE":
            return {...state, firstDateAndTimeForQueue: action.payload}
        default:  
            return state
    }
}

export const setSessionsAC = (notes: notesStateType[]): setSessionType => {  
    return{type: 'SET-SESSION', notes}
}
export const setDateAndTimeForNoteAC = (date: string, time: string,duration: string):setDateAndTimeForNoteAT => {
    return {type: "SESSION/DATE-TIME-FOR-NOTE",date, time,duration}
}
 const setFreeTimeForDayAC = (items: workTimeRenderType[]):setFreeTimeForDayAT => {
    return {type: "SESSION/FREE-TIME-DAY", items}
}
const setFreeTimeForWeekAC = (items: workTimeRenderType[]):setFreeTimeForWeekAT => {
    return {type: "SESSION/FREE-TIME-WEEK",items}
}
export const setFirstFreeTimeAndDateForNoteAC = (payload: dateAndTimeForQueueType):setFirstFreeTimeAndDateForNoteAT => {
    return {type: "SESSION/SET-FIRST-FREE-DATE-AND-TIME-FOR-NOTE",payload}
}
export const setEndOfDayAC = (payload: maxEndOfEntryState):setEndOfDay => {
    return {type: "SESSION/SET-END-OF-DAY",payload}
}

export const getFreeTimesForWeekTC = (userId: string,start: string, end: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiFreeTime.getForWeek(userId, start,end)
        dispatch(setFreeTimeForWeekAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {

    }
}
export const getFreeTimesForDayTC = (userId: string,date: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiFreeTime.getForDay(userId,date)
        dispatch(setFreeTimeForDayAC(res.data))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {

    }
}
export const getMaxEndOfEntryTC = (userId: string, date: string,time: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiWorkTimes.getMaxEndOfEntry(userId,date, time)
        dispatch(setEndOfDayAC({endOfDay: +res.data.endOfDay,beginNextEntry: +res.data.beginNextEntry}))
    } catch (error: AxiosError | any) {
        if (isAxiosError(error)) {
          if (error.response?.status === 401) {
            dispatch(logoutTC());
          }
        }
      } finally {
        dispatch(setIsLoadingAC(false))

    }
}

export const getFirstDateAndTimeForNoteTC = ():thunkActionRootType => async dispatch => {
   dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getFirstFreeDate()
        dispatch(setFirstFreeTimeAndDateForNoteAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))

    }
}
   