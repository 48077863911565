import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { showErrorWindowAC } from "../store/ModalWindowsReducer"
import classes from "./errorWindow.module.css"

export const ErrorWindow = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        setTimeout(() => {
            dispatch(showErrorWindowAC(false))
        }, 2000)
    }, [])
    return (
        <div className={classes.wrapper}>
            <p className={classes.text}>
                {`
                Ошибка! Для создания записи должны быть выбраны клиент, дата, время, продолжительность сеанса
                `}
            </p>
            <button onClick={() => dispatch(showErrorWindowAC(false))} className={classes.btn}>ok</button>
        </div>

    )
}