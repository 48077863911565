
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { FlexBox } from "../styles/components/FlexBox"
import {Text} from "../styles/components/Text"
import { TableHeader } from "../styles/components/TableHeader"
import { TableRow } from "../styles/components/TableRow"
import { Column } from "../styles/components/Column"
import {theme} from "../styles/components/Theme.styled"
import {Box} from "../styles/components/Box"
import { CheckBox } from "../styles/components/CheckBox"
import { SuperButton } from "../styles/components/SuperButton"
import { Icon } from "../styles/components/Icon"
import { useEffect, useState } from "react"
import { changeWorkActiveStatusTC, changeWorkVidzhetStatusTC, getWorkCategoriesTC, getWorkServicesTC } from "../store/ProsettingsReducer"
import { editWorkServiceWindowAC, removeWorkServiceWindowAC } from "../store/ModalWindowsReducer"
import { workServiceType } from "../apiTypes/root/proSettings.type"
import { convertNumberToTimeString } from "../utils/utils"

const selectWorks = (state: AppRootState) => state.proSettings
export const PriceList = () => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const proSettings = useSelector(selectWorks)
    const [workServices, setWorkServices] = useState<workServiceType[]>(proSettings.workService)

    const formatter = new Intl.NumberFormat("ru", {style:"currency", currency: "RUR"})
    const statusHandler = (id: string, status: boolean) => {
        dispatch(changeWorkActiveStatusTC(id,status))
    }
    const vidzhetHandler = (id: string, status: boolean) => {
        dispatch(changeWorkVidzhetStatusTC(id,status))
    }
    useEffect(() => {
        dispatch(getWorkServicesTC())
        if(proSettings.workCategories.length){
            setWorkServices(proSettings.workService)
        }
    },[JSON.stringify(proSettings.workService),proSettings.workCategories.length])

    useEffect(() => {
        if(proSettings.findWorkKey){
            setWorkServices(proSettings.workService.filter(w => w.category === proSettings.findWorkKey))
        }
        if(!proSettings.findWorkKey){
            setWorkServices(proSettings.workService)
        }
    },[proSettings.findWorkKey])
    return(
        <FlexBox gap={15}  direction="column" width="100%" height="100%" >
                 <TableHeader className="clientTableHeader" width="100%" type="dynamic" offset="medium">
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Статус</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Работы-услуги</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Категория</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Время</Text>
                        </Column>
                        <Column  maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Стоимость</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Заработок</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Виджет</Text>
                        </Column>
                        <Column maxWidth="100%" axis="column" component="TimeTable">
                            <Text size={13} color="#fff" font="mont">Действие</Text>
                        </Column>
                        
                    </TableHeader>
                
                    <Box height="100%" width="100%" position="relative" >
                        <Box gap={12} overFlowY="auto" position="absolute" width="100%" height="100%">
                            {
                                workServices.map(w =>
                                   
                                    
                                        <TableRow minHeight="64px" key={w.id} component="Clients">
                                            <FlexBox gap={5} direction="row" align="center" justify="center" basis="100%">
                                                
                                                <FlexBox align="start" width="100%" direction="column">
                                                    <CheckBox variant="switch" value= {w.isActive} render={() => <input checked={w.isActive} onChange={(e) => statusHandler(w.id, e.currentTarget.checked)}  type="checkbox"/>}/>
                                                </FlexBox>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{w.name}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{w.category}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{convertNumberToTimeString(w.duration) }</Text>
                                            </FlexBox>
                                            <FlexBox  direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{formatter.format(w.cost)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <Text size={12} font="mont" color={theme.colors.fonts.main}>{formatter.format(w.profit)}</Text>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%">
                                                <CheckBox variant="primary" value={w.isVidzhet} render={() => <input checked = {w.isVidzhet} onChange={(e) => vidzhetHandler(w.id,e.currentTarget.checked)} type="checkbox"/>}/>
                                            </FlexBox>
                                            <FlexBox direction="row" align="center" justify="center" basis="100%" gap={8}>
                                                <SuperButton varinat="sort" onClick={(e) => dispatch(editWorkServiceWindowAC({value: true, workService: w}))}>
                                                    <Icon svgID="edit" width={18} height={16} />
                                                </SuperButton>
                                                <SuperButton varinat="sort" onClick={(e) =>dispatch(removeWorkServiceWindowAC({value: true, id: w.id}))}>
                                                    <Icon svgID="delete" width={18} height={16} />
                                                </SuperButton>
                                            </FlexBox>
                                        </TableRow>
                                       
                                )
                            }
                        </Box>
                    </Box>
            </FlexBox>
    )
}
