import { styled } from "styled-components";


export const OpacityLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0;
    z-index: 26;
    height: 100vh;
    width: 100vw;

`