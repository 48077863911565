import styled from "styled-components";


export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    .content {
      overflow: hidden;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .content {
          padding: 10px 0;
        }
      };
`