import axios, { AxiosError } from "axios"
import { apiAuth } from "../apiServer/apiAuth"
import { tokenService } from "../apiServer/tokenService"
import { setIsLoadingAC, setSuccesMessagePassRecoveryAC, setSuccesRestorePassMessageAC } from "./AppReducer"
import { logoutAC, userResetAC } from "./UserReducer"
import { thunkActionRootType } from "./storeRedux"
import { setPassRecoveryAC, setPasswordCodeErrorAC, setRestorePassErrorAC } from "./ErrorMessagesReducer"
import { deviceSessionResponseType } from "../apiTypes/responses/deviceSessionResponseType"
import { apiDeviceSessions } from "../apiServer/apiDeviceSessions"

export type stateRegisterType = {
    isRegister: boolean,
    userId: string,
    deviceSessions:Array<deviceSessionResponseType>
}
export type authAT = actionRegisterType | setUserRegister | getLastSessionsAT

type actionRegisterType = {
    type: 'REGISTER-TYPE',
    isRegister: boolean,
    }
type setUserRegister = {
    type: 'SET-REGISTER-USER',
    userId: string
}
type getLastSessionsAT = {
    type: "AUTH/GET-LAST-SESSIONS",
    sessions: deviceSessionResponseType[]
}


const initState:stateRegisterType = {
    isRegister: false,
    userId: '',
    deviceSessions: []
}

export const AuthReducer = ( state: stateRegisterType = initState, action: authAT):stateRegisterType => {
        
        switch(action.type) {
            case 'REGISTER-TYPE':
                return {...state,isRegister: action.isRegister}
            case 'SET-REGISTER-USER':
                return {...state, userId: action.userId}
            case "AUTH/GET-LAST-SESSIONS":
                return {...state, deviceSessions: action.sessions}
            default:
                return state
        }
}

export const registerAC = (isRegister: boolean):actionRegisterType => {
    return  {type: 'REGISTER-TYPE', isRegister}
}

export const setRegisterUserAC = (userId: string):setUserRegister => { 
    return { type: 'SET-REGISTER-USER', userId}
}

const getLastSessionsAC = (sessions: deviceSessionResponseType[]):getLastSessionsAT => {
    return {type :"AUTH/GET-LAST-SESSIONS", sessions} 
}
export const logoutTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        await apiAuth.logout()
        tokenService.removeToken()
        dispatch(logoutAC())
    } catch (error) {
        tokenService.removeToken()
        dispatch(userResetAC())
    } finally{
        dispatch(setIsLoadingAC(false))
    }
}
export const passwordRecoveryTC = (email: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiAuth.passwordRecovery(email)
        dispatch(setSuccesMessagePassRecoveryAC("На вашу почту вам отправлена инструкция по восстановлению пароля"))
    } catch (error:AxiosError | any) {
        if(axios.isAxiosError(error)){
            if(error.response?.status === 404){
                dispatch(setPassRecoveryAC(error.response.data.message))
            }
        }
    } finally {

    }
}

export const restorePasswordTC = (code: string, userId: string, password: string):thunkActionRootType => async dispatch => {
    try {
        const res = await apiAuth.checkRecoveryCode(code, userId, password)
        dispatch(setSuccesRestorePassMessageAC("Пароль успешно изменен"))
    } catch (error: AxiosError | any) {
            if(axios.isAxiosError(error)){
                if(error.response?.status === 400){
                    dispatch( setRestorePassErrorAC("Пароль должен быть не менее 8 символов"))
                }
                if(error.response?.status === 403){
                    dispatch(setPasswordCodeErrorAC("Срок действия ссылки истек"))
                }
            }
    } finally {

    }
}

export const getLastDeviceSessionsTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiDeviceSessions.getLastSessions()
        dispatch(getLastSessionsAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
} 