


import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { clientsStateType, getClientsTC } from "../../store/ClientsReducer";
import { useForm } from "react-hook-form";
import { addNoteTC, getNotesTC } from "../../store/NotesReducer";
import { stateUserType, tariffType } from "../../store/UserReducer";
import theMan from "../../assets/pictures/theMan.png";

import {
  
    queueRescheduleWindowAC,
  showQueueAC,
} from "../../store/ModalWindowsReducer";

import { CustomSelect } from "../../styles/components/CustomSelect";
import { SuperButton } from "../../styles/components/SuperButton";
import { Text } from "../../styles/components/Text";
import { Avatar } from "../../styles/components/Avatar";
import { Select } from "../../styles/components/Select";
import { FakeInput } from "../../styles/components/FakeInput";
import { FlexBox } from "../../styles/components/FlexBox";
import { Box } from "../../styles/components/Box";
import { theme } from "../../styles/components/Theme.styled";
import { getWorkServicesTC } from "../../store/ProsettingsReducer";
import { addMonths } from "date-fns";
import { queueFormType } from "../../apiTypes/formTypes/queueForm.type";
import { addQueuTC, rescheduleQueueTC } from "../../store/QueueReducer";
import { resetClientCardAC, setClientCardTC } from "../../store/ClientCardReducer";
import { setQueueRescheduleMessageAC } from "../../store/AppReducer";

const selectModalState = (state: AppRootState) => state.ModalWindowReducer;
const selectProSettings = (state: AppRootState) => state.proSettings;
const selectUser = (state:AppRootState) => state.user
const selectClient = (state:AppRootState) => state.clientCard
const selectAppState = (state:AppRootState) => state.appState
type activeMonthType = {
    year: number,
    month: number,
    label: string
}
export const QueueRescheduleWindow = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const modalState = useSelector(selectModalState);
const [monthes, setMonthes] = useState<Array<activeMonthType>>([])
const [activeMonth,setActiveMonth]= useState<activeMonthType | null>(null)
  const client  = useSelector(selectClient);
  const appState = useSelector(selectAppState)
  const {year, month,workServiceId,clientId, id:queueId} = modalState.queueRescheduleState
  const proSettings = useSelector(selectProSettings);
  const user = useSelector(selectUser)
  const cancel = (e: any) => {
    e.preventDefault()
   dispatch(queueRescheduleWindowAC({value: false,month: 0, year: 0,workServiceId: "",clientId: "",id: ""}))
  };

  
  
  
  const { watch ,handleSubmit,  setValue, setError,formState: {errors}, clearErrors } = useForm<queueFormType>({
    defaultValues: {
        clientId: client.client.id,
        userId: user.user!.id
    }
  });
  const monthHandler = (value: activeMonthType, e:any) => {
    e.preventDefault()
    setActiveMonth(value)
    setValue("month",value.month)
    setValue("year",value.year)
  }
  const onSubmit = (data: queueFormType) => {
   
    if(!data.month){
        setError("month", {message: "Выберите месяц"})
        return
    }
    if(!data.workServiceId){
        setError("workServiceId", {message: "Выберите услугу"})
    }
   
    dispatch(rescheduleQueueTC(data.year,data.month,queueId,data.workServiceId))
    
  };

  useEffect(() => {
    if (!proSettings.workService.length) {
      dispatch(getWorkServicesTC());
    }
   if(proSettings.workService.length){
    const work = proSettings.workService.find(w => w.name === workServiceId)
    if(work) setValue("workServiceId",work.id)

   }
  }, [proSettings.workService.length]);

  useEffect(() => {
    const sub = watch((value, {name, type}) => {
        if(value || name || type){
            clearErrors()
            return
        }

    })
    return () => sub.unsubscribe()
  },[watch])

  useEffect(() => {
    let arr:activeMonthType[] = []
    let startPeriod = new Date()
    let endPeriod = addMonths(startPeriod,6)
    while(startPeriod < endPeriod){
        arr.push( {label: startPeriod.toLocaleString("ru", {month:"long"}),year: startPeriod.getFullYear(), month: Number(startPeriod.toLocaleString("ru", {month: "numeric"}))})
        startPeriod = addMonths(startPeriod,1)
    }
    setMonthes(arr)
    
  },[])

  useEffect(() => {
    const date = new Date(year,month-1,1)
    const data:activeMonthType = {year, month, label: date.toLocaleString("ru", {month: "long"})} 
    setActiveMonth(data)
  },[])
  useEffect(() => {
        if(clientId){
            dispatch(setClientCardTC(clientId))
        }
  },[clientId])
  useEffect(() => {
    if(appState.queueRescheduleMessage){
        setTimeout(() => {
            dispatch(setQueueRescheduleMessageAC(""))
        },3000)
    }
  },[appState.queueRescheduleMessage])
  useEffect(() => {
    return () => {
        dispatch(resetClientCardAC())
        dispatch(queueRescheduleWindowAC({value: false,month: 0, year: 0,workServiceId: "",clientId: "",id: ""}))
        dispatch(setQueueRescheduleMessageAC(""))
    }
  },[])
  return (
    <Box
      padding="30px 40px"
      zIndex={27}
      background={theme.colors.colorForm}
      borderRaduis="8px"
      width="423px"
      height="max-content"
      position="fixed"
      transform="translate(-50%,-50%)"
      top="50%"
      left="50%"
      gap={20}
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      
      <FlexBox direction="row" width="100%" align="center" justify="center">
        <Text font="mont" size={18} color={theme.colors.fonts.main}>
          Перенос очереди
        </Text>
      </FlexBox>
      <FakeInput active={true}>
        <FlexBox direction="row" align="center" justify="center" width="40px">
          <Avatar
            size="newEntry"
            src={client.client.photoURL}
          />
        </FlexBox>
        <FlexBox direction="column" width="100%">
          <Text
            color="#fff"
            size={14}
            font="mont"
          >{`${client.client.surname} ${client.client.name} ${client.client.patronymic}`}</Text>
          <Text color="#fff" size={14} font="mont">
            {client.client.phone}
          </Text>
        </FlexBox>
      </FakeInput>
      <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.clientId?.message}</Text>

      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={15} font="mont">
         Специалист и услуга
        </Text>
        <FlexBox direction="row" width="100%" gap={10}>
        <CustomSelect />
        <Select
          setValue={setValue}
          name="workServiceId"
          state={proSettings.workService}
          defaultValue={watch("workServiceId")}
          placeholder="Выберите услугу"
        />
        </FlexBox>
        <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.workServiceId?.message}</Text>

      </FlexBox>
      <FlexBox direction="column" gap={8} width="100%">
        <Text color={theme.colors.fonts.main} size={14} font="mont">
          Месяц
        </Text>
       <FlexBox direction="row" height="50px" width="100%" background="#fff" borderRadius="8px">
        {
            monthes.map(el => <SuperButton active = {activeMonth?.label === el.label} onClick={(e) => monthHandler(el,e)} varinat="month-queue">{el.label}</SuperButton>)
        }
       </FlexBox>
       <Text color={theme.colors.fonts.errors} font="mont" size={12}>{errors.month?.message}</Text>
        {appState.queueRescheduleMessage && <Text color="green" size={12} font="mont">{appState.queueRescheduleMessage}</Text>}
      </FlexBox>
      
     
      <FlexBox
        marginTop="15px"
        direction="row"
        gap={12}
        height="50px"
        width="100%"
      >
        <SuperButton onClick={cancel} varinat="outlined">
          Отменить
        </SuperButton>
        <SuperButton type="submit" varinat="contained">
          Сохранить
        </SuperButton>
      </FlexBox>
    </Box>
  );
};
