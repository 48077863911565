import { createNoteViewType } from "../../apiTypes/responses/notesResponsesType";
import { notesStateType } from "../NotesReducer";


export const notesHelper = {
    addNote(note: createNoteViewType):notesStateType{
        const res:notesStateType = {
            id: note.id,
            userId: note.userId,
            date: note.date,
            time:note.time,
            duration: note.duration,
            name:note.name,
            surname:note.surname,
            patronymic: note.patronymic,
            clientAvatar: note.clientAvatar,
            phone: note.phone,
            comments:note.comments,
            status: note.status
        }
        return res
    }
}