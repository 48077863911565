import React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 30;
  height: 100%;
`;

 const Loader = styled.div`
  /* border-width: 0.5rem;
  border-style: solid;
  border-color: purple purple purple purple;
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 50%; */
  /* border: 2px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-animation: spin 1.5s infinite;
  animation: spin 1.5 infinite;

 

  @keyframes spin {
    
    100% {
      transform: rotate(360deg);
    }
  }
`;

//Create functional component
export function Preloader() {

  return (
    <Container>
         <Loader>
            <Icon svgID="spinner" transform="scale(2.8)" width={24} height={24}/>
         </Loader>
    </Container>
  );

}