import styled, { css } from "styled-components";


type propsType = {
    active?: boolean
}
export const FakeInput = styled.div<propsType>`
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
   gap: 20px;
    padding-left: 15px;
    ${props => props.active && css<propsType>`
        background: #5d5ffe;
        color: white;
    `};
    svg {
        transform:scale(1.2)
    };
    
`