
export const theme = {
    colors: {
        colorForm: "#f8f8f8",
        main: "#5d5ffe",
        fonts: {
            main: "#262626",
            secondary: "#444",
            errors: "#ff2424"
        }
    },
    radius: {
        big: "16px",
        medium: "8px",
        small: "4px"
    },
    fonts: {
        primary: "mont",
        secondary: "Inter"
    }
}