import { css, styled } from "styled-components";


type propsType = {
    offset?: "small" | "medium",
    type: "static" | "dynamic",
    width?: string,
    padding?:string,
    justify?: "start" | "end" | "space-between" | "space-around"
}
export const TableHeader = styled.div<propsType>`
    display: flex;
flex-direction: row;
align-items: center;
justify-content: ${props => props.justify ? props.justify : "center"};
padding: 10px;
gap: 12px;
background: #262626;
border-radius: 8px;
color: #fff;
height: 48px;
${props => props.padding && css<propsType>`
   padding : ${props => props.padding};
`};
${props => props.offset === "medium" && css<propsType>`
margin-top: 24px;
`};
${props => props.width && css<propsType>`
    width: ${props => props.width};
 `};
 .payment {
     @media screen and (max-width: 1400px) {
         display: none;
     }
 };
 .weekTitle {
         @media screen and (max-width: 768px) {
                 display: none;
                 width: 0;
                 height: 0;
             }
 }
 @media screen and (max-width: 768px) {
         background:#fff;
         margin-top:0;
         align-items:center;
         justify-content:space-between;
         .timeTableHeaderContent {
             display: none
         };
         .btnDateContainer {
                     max-width:auto;
                    width:auto
                }
    }
    `