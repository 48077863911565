import styled, { css } from "styled-components"
import { AppRootState } from "../../store/storeRedux"
import { useSelector } from "react-redux"
import { Text } from "./Text"
import { useEffect, useState } from "react"
import { Icon } from "./Icon"
import { FieldValues, UseFormSetValue } from "react-hook-form"
import { tariffType } from "../../store/UserReducer"
import { workServiceType } from "../../apiTypes/root/proSettings.type"

type propsType = {
    state: Array<workServiceType>
    placeholder: string
    setValue: UseFormSetValue<FieldValues> | UseFormSetValue<any>,
    name: string,
    tooltip?: string,
    isClear?: boolean,
    defaultValue?: string
   
}

type selectPropsType = {
    edirMode: boolean,
    tariff: tariffType
}
const selectUser = (state:AppRootState) => state.user


export const Select = (props: propsType) => {
    const [editMode,setEditMode] = useState(false)
    const [value, setValue] = useState<string>("")
    const [title, setTitle] = useState<string>("")
    const user = useSelector(selectUser)
    const handleSelect = (id: string) => {
        const work = props.state.find(w => w.id === id)
       if(work) setTitle(work.name)
        setValue(id)
        props.setValue(props.name, id)
    }
    const handleMode = (e:React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setEditMode(prev => !prev)
    }
    useEffect(() => {
        if(props.defaultValue) {
            handleSelect(props.defaultValue)
        }
    },[props.defaultValue])
    return(
        <Container tariff={user.user?.tariff as tariffType} as={"button"} disabled = {user.user?.tariff === tariffType.FREE} onClick={handleMode} edirMode = {editMode}>
            <div className={"block"}>
                <Text size={12} color="#fff" font="mont">{props.tooltip}</Text>
            </div>
            <Text font="mont" size={13} color="#262626">{title && !props.isClear ? title : props.placeholder}</Text>
            <Icon  svgID="row" width={6} height={4}/>
            {
                editMode && <StateContainer>
                    {
                        props.state.map(el => 
                            <Option active = {el.id === value} onClick={() => handleSelect(el.id)} key={el.id}>{el.name}</Option>
                            )
                    }
                </StateContainer>
            }
        </Container>
    )
}

const Container = styled.div<selectPropsType>`
    border-radius: 8px;
border: 1px solid rgba(228, 228, 228, 0.90);
background: #FFF;
width: 100%;
height: 50px;
display: flex;
text-align: center;
align-items: center;
justify-content: space-between;
padding: 0 15px;
position: relative;


span {
    position: relative;
    z-index: 5;
};
.block {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 33px;
    width: 160px;
    background: #555;
    border-radius: 4px;
    left: 50%;
    margin-left: -5px;
    text-align: center;
    align-items: center;
    color: #fff;
    font-size: 12px;

};
&:hover .block {
    display: ${props => props.tariff === tariffType.FREE ? "flex" : "none"};
}
.block::after {
    content: "";
    display: inline-block;
    border: 18px solid transparent;
    border-top: 27px solid #555;
    position: absolute;
    left: 0;
    top: 15px;
    z-index: 1
}
${props => props.edirMode && css<selectPropsType>`
   svg {
    transform: rotate(180deg);
    transition: 40ms;
   }
`}
`

const StateContainer = styled.ul`
    background: #fff;
    width: 100%;
    min-height: 18px;
    border: 0.5px solid ;
    border-color: internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
`

const Option = styled.li<{active?: boolean}>`
    width: 100%;
    color: #262626;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background: blue;
        color: #fff;
    };
    ${props => props.active && css`
        background: blue;
        color: #fff;
    `}
`