import { styled } from "styled-components";


export const DropDownContainer = styled.div`
   position: relative;
   button {
      background: 0;
      border: none;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 768px) {
               display: none;
       };
   }
`