

export type clientBalanceFormType = {
    clientId: string,
    balance: number,
    methodPay: methodPay
}

export enum methodPay {
    ONLINE = "Онлайн",
    CARD = "Картой",
    CASH = "Наличными"
}