import { ModalLayer } from '../styles/components/ModalLayer';
import { Box } from '../styles/components/Box';
import { Grid } from '../styles/components/Grid';
import { FlexBox } from '../styles/components/FlexBox';
type propsType = {
    setColorWindow: (show: boolean) => void
    setColor: (color: string) => void
}
export const SelectColor = (props: propsType) => {
    const colors = [
        { color: 'blue' },
        { color: 'green' },
        { color: 'yellow' },
        { color: 'orange' },
        { color: 'grey' },
        { color: 'brown' },
        { color: 'violet' },
        { color: 'red' },
        { color: 'pink' },
    ]
    const changeColor = (color: string) => {
        props.setColor(color)
        props.setColorWindow(false)
    }
    return (
        <>
            <ModalLayer onClick={() => props.setColorWindow(false)}/>
            <Box padding='10px' width='300px' height='300px' zIndex={50} position='fixed' borderRaduis='15px' top='50%' left='50%' background='#fff' transform='translateX(-50%) translateY(-50%)'>

                <Grid columns='1fr 1fr 1fr' gap={10} width='100%' height='100%' pading='15px'>
                    {
                        colors.map(el =>
                            <FlexBox borderRadius='8px' direction='row' align='center' justify='center' onClick={ () => changeColor(el.color)} style={
                                {background: el.color}
                            }></FlexBox>
                        )
                    }
                </Grid>


            </Box>
        </>
    )
}