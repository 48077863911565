import { css } from "styled-components";
import { styled } from "styled-components";
import { theme } from "./Theme.styled";

type propsType = {
    width?: string,
    minWidth?: string,
    maxWidth?: string,
    height?: string,
    as?: "div" | undefined,
    gap?: number,
    justify?:"start" | "end" | "center",
    border?: string,
    textIndent?: string,
    kind?: "search" | "searchClient" | "fake",
    active?: boolean,
    error?: boolean,
    transform?: "capitalize",
    paddinLeft?: number
}
export const TextField = styled.input<propsType>`
    border-radius: 8px;
    color: ${theme.colors.fonts.main};
    border: ${props => props.border ? props.border : "1px solid rgba(228, 228, 228, 0.90)"} ;
    background: #FFF;
    height: ${props => props.height ? props.height : "50px"};
    text-indent: ${props => props.textIndent ? props.textIndent : 0};
    padding-left: ${props => props.paddinLeft ? props.paddinLeft + "px" : 0};
    ${props => props.error && css<propsType>`
    border: 1px solid;
    border-color: ${theme.colors.fonts.errors};
    `}
    ${props => props.transform && css<propsType>`
    text-transform: ${props => props.transform};
    `}
    ${props => props.as === "div" && css<propsType>`
        display:flex;
        align-items:  center;
        padding-left:15px;
        justify-content: ${props => props.justify ? props.justify : "center"} ;
        gap: ${props => props.gap + "px"};

`};
${props => props.width && css<propsType>`
    width: ${props => props.width};
`};
${props => props.kind === "search" && css<propsType>`
        
        color:#262626;
        height: 100%;
        background-size: 10%;
        background-position: 0 50%;
        background: #F4F6FA;
        box-shadow: 0px 6px 12px 0px rgba(248, 248, 248, 0.25);
        border-radius: 8px;
        border: none
`};
${props => props.kind === "searchClient" && css<propsType>`
        
        color:#262626;
        height: 100%;
        background-size: 10%;
        background-position: 0 50%;
        background: #FFF;
        border-radius: 8px;
        border: none;
        
        
`};
${props => props.kind === "fake" && css<propsType>`
    height: 100%;
    width: 100%;
    color: ${props => props.active ? "#fff" : "#262626"};
    border: none;
    background: 0;
    outline: none;
    
`};

${props => props.minWidth && css<propsType>`
    min-width: ${props => props.minWidth};
`};
${props => props.maxWidth && css<propsType>`
    max-width: ${props => props.maxWidth};
`};
`