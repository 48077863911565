import { LabelFile } from "./LabelFile"
import sprite from "../../images/sprite.svg"
import { UseFormRegister } from "react-hook-form"
import { TCreateTeammateForm } from "../../apiTypes/formTypes/fillModeFormTypes"
import { Avatar } from "./Avatar"

type propsType = {
    register: UseFormRegister<TCreateTeammateForm>,
    photoUrl: string
}
export const FileInputComponent = (props: propsType) => {
    return (
        <LabelFile>
            <>
                <input {...props.register("image")} hidden type="file" />
                {
                    props.photoUrl
                        ? <Avatar src={props.photoUrl} size="extralarge" />
                        : <svg>
                            <use xlinkHref={`${sprite}#camera`}></use>
                        </svg>
                }

            </>

        </LabelFile>
    )
}