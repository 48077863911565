import { tariffType } from "../../store/UserReducer";


export const userProHelper = {
    getTarifName(tarif: tariffType):string{
        if(tarif === tariffType.PRO){
            return "Pro"
        } else if(tarif === tariffType.SuperPRO){
            return "Pro plus"
        }
        return "Бесплатный"
    }
}