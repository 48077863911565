import { useEffect, useState } from "react"
import { css, styled } from "styled-components"
import { changeTeamMateRighTC, rightsType } from "../../store/TeamMateReducer"
import { useDispatch, useSelector } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { AppActionsType, AppRootState } from "../../store/storeRedux"

type dragEvtType = React.DragEvent<HTMLDivElement>
type dragEl = "A" | "B" | "C"
type propsType = {
    element: rightsType | undefined,
    teamMateID: string
}
const selectAppState = (state: AppRootState) => state.appState
export const DragDown = (props: propsType) => {
    const dispatch: ThunkDispatch<AppRootState, unknown, AppActionsType> = useDispatch()

    const appState = useSelector(selectAppState)
    const [activeName, setActiveName] = useState<dragEl>(props?.element?.value as dragEl)
    const [position, setPosition] = useState<number>(0)
    const [show, setShow] = useState(false)
    const [draggableEl, setDraggableEl] = useState<dragEl | null>(null)
    const dragStartHandler = (el: dragEl, e: dragEvtType) => {
        setDraggableEl(el)
        setPosition(e.pageX)
    }
    const dropHandler = (el: dragEl, e: dragEvtType) => {
        dispatch(changeTeamMateRighTC(props.teamMateID as string, { _id: props?.element?._id as string, name: props?.element!.name, value: el }))
       
    }
    const dragOverHandler = (el: dragEl, e: dragEvtType) => {
        e.preventDefault()
        e.pageX < position ? setShow(true) : setShow(false)
       
    }
   
   
    const dragEndHandler = (e: dragEvtType) => {
       
        setDraggableEl(null)
        setShow(false)
    }
    useEffect(() => {
        setActiveName(props?.element!.value)
    }, [props.element?.value])
    return (
        <DragDownWrapper>
            {
                appState.enableSwitch &&
                <DragDownContainer  activeName={activeName} show={show} dragStartEl={draggableEl}>
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={"A"} draggable={true} onDragStart={(e) => dragStartHandler("A", e)} onDrop={(e) => dropHandler("A", e)} onDragOver={(e) => dragOverHandler("A", e)} onDragEnd={dragEndHandler} />
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={"B"} draggable={true} onDragStart={(e) => dragStartHandler("B", e)} onDrop={(e) => dropHandler("B", e)} onDragOver={(e) => dragOverHandler("B", e)} onDragEnd={dragEndHandler} />
                    <Circle dragStartEl={draggableEl} activeName={activeName} name={"C"} show={show} draggable={true} onDragStart={(e) => dragStartHandler("C", e)} onDrop={(e) => dropHandler("C", e)} onDragOver={(e) => dragOverHandler("C", e)} onDragEnd={dragEndHandler} />
                </DragDownContainer>
            }

        </DragDownWrapper>

    )
}

const DragDownWrapper = styled.div`
    display: flex;
    width: 66px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    height: 6px;
`
const DragDownContainer = styled.div<{ dragStartEl: dragEl | null, activeName: dragEl, show: boolean }>`
    height: 6px;
    background: #5D5FEF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props => (props.dragStartEl === "A" || props.dragStartEl === "B") && css<{ dragStartEl: dragEl | null }>`
        gap: 14px;
    `};
    ${props => props.activeName === "B" && css<{ activeName: dragEl }>`
        gap: 14px;
    `};
    ${props => props.activeName === "C" && css<{ activeName: dragEl, show: boolean }>`
        gap:  14px;
    `};
    
`
const Circle = styled.div<{ activeName: dragEl, name: dragEl, dragStartEl: dragEl | null, show?: boolean }>`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #5D5FEF;
    display: none;
   
    ${props => props.dragStartEl === "A" && css<{ dragStartEl: dragEl | null, name: dragEl }>`
        ${props => props.name === "B" && css<{ dragStartEl: dragEl | null, name: dragEl }>`
            display: block;
        `}
    `};
    ${props => props.dragStartEl === "B" && css<{ dragStartEl: dragEl | null, name: dragEl, show?: boolean }>`
        ${props => props.name === "C" && css<{ dragStartEl: dragEl | null, name: dragEl, show?: boolean }>`
            display: ${props => props.show ? "none" : "block"};
            
        `}
    `}
    ${props => props.name === "A" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        display: block;
        
    `};
    ${props => props.activeName === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        ${props => props.name === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        
    `};
    ${props => props.activeName === "C" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
        ${props => props.name === "C" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        ${props => props.name === "B" && css<{ activeName: "A" | "B" | "C", name: "A" | "B" | "C" }>`
         display: block;
        `};
        
    `};
`