import { css, styled } from "styled-components";

type propsType = {
    isAuth: boolean
}
export const NavbarContainer = styled.div<propsType>`
    display: flex;
    flex-direction: column;
    width: 300px;
    background: #262626;
    padding-top: 40px;
    align-items: center;
    ${props => !props.isAuth && css<propsType>`
        justify-content: center;
    `};
    .mobileNav {
        display: none
    };
    .mobileMenu {
        display: none;
    }
    @media screen and (max-width: 768px) {
        width:100%;
        height: 64px;
        padding-top: 0;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        padding:  15px;
        /* gap: 15px; */
        .mobileNav {
            display: flex;
            gap: 10px;
            margin-left: 20px;
        };
        .desktopMenu {
            display: none
        };
        .field {
            width: 100%;
            max-width: 100%;
        };
        .fieldBox {
            max-width: 100%
        };
        .mobileMenu {
            display: flex
        }
      };
`