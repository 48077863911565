import { styled } from "styled-components"
import { Avatar } from "./Avatar"
import { Text } from "./Text"
import { Link } from "react-router-dom"

type propsType = {
    avatarUrl: string,
    firstName: string,
    lastName: string,
    position: string
}
type textContainerPropsType = {
    marginTop: number
}
export const ProfileInfo = (props: propsType) => {
    return (
        <Box>
            <Link to={'/userProfile'}>
                <Avatar size="userAvatar" src={props.avatarUrl} />
            </Link>
            <TextContainer marginTop={16}>
                <Text color="#fff" font="mont" size={14}>{props.firstName} {props.lastName}</Text>
            </TextContainer>
            <TextContainer marginTop={4}>
                <Text color="#fff" font="mont" size={14}>{props.position}</Text>
            </TextContainer>
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    @media screen and (max-width: 768px) {
        display: none;
      };
`
const TextContainer = styled.div<textContainerPropsType>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: ${props => props.marginTop + "px"};
`