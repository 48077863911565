import { styled } from "styled-components";
import { theme } from "./Theme.styled";

type propsType = {
    size:number
}
export const Title = styled.span<propsType>`
    font-size: ${props => props.size ? props.size + "px" : "14px"};
    color: ${theme.colors.fonts.main};
    font-weight: 600;
`