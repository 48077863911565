import axios, { AxiosError, isAxiosError } from "axios";
import { tariffInfoType } from "../apiTypes/responses/tariffRespons.type";
import { rootUserType } from "./UserReducer";
import { thunkActionRootType } from "./storeRedux";
import { apiUser } from "../apiServer/apiUser";
import { setIsLoadingAC } from "./AppReducer";
import {
  workCategoryType,
  workServiceType,
} from "../apiTypes/root/proSettings.type";
import { apiProSettings } from "../apiServer/apiProSettings";
import { createWorkFormType } from "../apiTypes/formTypes/createWorkFormType";
import {
  createWorkWindowAC,
  editWorkCategoryWindowAC,
  editWorkServiceWindowAC,
} from "./ModalWindowsReducer";
import { convertTimeToNumber } from "../utils/utils";
import { logoutTC } from "./AuthReducer";

export type ProSettingsType = {
  tariffInfo: tariffInfoType[];
  searchUser: rootUserType | null;
  createNewUserMode: boolean;
  workCategories: Array<workCategoryType>;
  workService: Array<workServiceType>;
  findWorkKey: string | null;
};
export type prosettingsAT =
  | searchUserForTeamAT
  | createCategoryAT
  | getCategoryAT
  | createWorkServiceAT
  | getWorkServicesAT
  | changeWorkActiveStatusAT
  | changeWorkVidzhetStatusAT
  | deleteWorkServiceAT
  | setFindWorkKeyAT
  | editWorkServiceAT
  | removeWorkCategoryAT
  | editWorkCategoryAT

type searchUserForTeamAT = {
  type: "PRO-SETTINGS/SEARCH-USER";
  user: rootUserType | null;
};

const initState: ProSettingsType = {
  tariffInfo: [],
  searchUser: null,
  createNewUserMode: false,
  workCategories: [],
  workService: [],
  findWorkKey: null,
};
type createCategoryAT = {
  type: "PRO-SETTINGS/CREATE-CATEGORY";
  payload: workCategoryType;
};
type getCategoryAT = {
  type: "PRO-SETTINGS/GET-CATEGORY";
  payload: workCategoryType[];
};
type createWorkServiceAT = {
  type: "PRO-SETTINGS/CREATE-WORK-SERVICE";
  payload: workServiceType;
};
type getWorkServicesAT = {
  type: "PRO-SETTINGS/GET-WORK-SERVICE";
  payload: workServiceType[];
};
type changeWorkActiveStatusAT = {
  type: "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS";
  status: boolean;
  id: string;
};
type changeWorkVidzhetStatusAT = {
  type: "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS";
  status: boolean;
  id: string;
};
type deleteWorkServiceAT = {
  type: "PRO-SETTINGS/DELETE-WORK";
  id: string;
};
type setFindWorkKeyAT = {
  type: "PRO-SETTINGS/SET-WORK-FIND-KEY";
  value: string | null;
};

type editWorkServiceAT = {
  type: "PRO-SETTINGS/EDIT-WORK-SERVICE";
  id: string;
  payload: createWorkFormType;
};

type removeWorkCategoryAT = {
  type: "PRO-SETTINGS/REMOVE-WORK-CATEGORY",
  id: string
}
type editWorkCategoryAT = {
  type: "PRO-SETTINGS/EDIT-WORK-CATEGORY",
  payload: {id: string, name: string}
}

export const ProsettingsReducer = (
  state: ProSettingsType = initState,
  action: prosettingsAT
): ProSettingsType => {
  switch (action.type) {
    case "PRO-SETTINGS/CREATE-CATEGORY":
      return {
        ...state,
        workCategories: [...state.workCategories, action.payload],
      };
    case "PRO-SETTINGS/GET-CATEGORY":
      return { ...state, workCategories: action.payload };
    case "PRO-SETTINGS/CREATE-WORK-SERVICE":
      return { ...state, workService: [...state.workService, action.payload] };
    case "PRO-SETTINGS/GET-WORK-SERVICE":
      return { ...state, workService: action.payload };
    case "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS":
      return {
        ...state,
        workService: state.workService.map((w) =>
          w.id === action.id ? { ...w, isActive: action.status } : w
        ),
      };
    case "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS":
      return {
        ...state,
        workService: state.workService.map((w) =>
          w.id === action.id ? { ...w, isVidzhet: action.status } : w
        ),
      };
    case "PRO-SETTINGS/DELETE-WORK":
      return {
        ...state,
        workService: state.workService.filter((w) => w.id !== action.id),
      };
    case "PRO-SETTINGS/SET-WORK-FIND-KEY":
      return { ...state, findWorkKey: action.value };
    case "PRO-SETTINGS/EDIT-WORK-SERVICE":
      const category = state.workCategories.find(
        (c) => c.id === action.payload.categoryId
      );
      if (!category) {
        return state;
      } else {
        const categoryName = category.category;
        const newData: workServiceType = {
          ...action.payload,
          id: action.id,
          category: categoryName,
          duration: convertTimeToNumber(action.payload.duration),
        };
        return {
          ...state,
          workService: state.workService.map((w) =>
            w.id === action.id ? newData : w
          ),
        };
      }
      case "PRO-SETTINGS/REMOVE-WORK-CATEGORY":
        return {...state, workCategories: state.workCategories.filter( w => w.id !== action.id)}
      case "PRO-SETTINGS/EDIT-WORK-CATEGORY":
        return {...state, workCategories: state.workCategories.map(w => w.id === action.payload.id ? {...w, category: action.payload.name} : w)}
    default:
      return state;
  }
};

export const searchUserForTeamAC = (
  user: rootUserType | null
): searchUserForTeamAT => {
  return { type: "PRO-SETTINGS/SEARCH-USER", user };
};
export const setFindWorkKeyAC = (value: string | null): setFindWorkKeyAT => {
  return { type: "PRO-SETTINGS/SET-WORK-FIND-KEY", value };
};

const createCategoryAC = (payload: workCategoryType): createCategoryAT => {
  return { type: "PRO-SETTINGS/CREATE-CATEGORY", payload };
};
const createWorkServiceAC = (
  payload: workServiceType
): createWorkServiceAT => ({
  type: "PRO-SETTINGS/CREATE-WORK-SERVICE",
  payload,
});
const getCategoryAC = (payload: workCategoryType[]): getCategoryAT => ({
  type: "PRO-SETTINGS/GET-CATEGORY",
  payload,
});
const getWorkServicesAC = (payload: workServiceType[]): getWorkServicesAT => ({
  type: "PRO-SETTINGS/GET-WORK-SERVICE",
  payload,
});
const changeWorkActiveStatusAC = (
  id: string,
  status: boolean
): changeWorkActiveStatusAT => ({
  type: "PRO-SETTINGS/CHANGE-WORK-ACTIVE-STATUS",
  status,
  id,
});
const changeWorkVidzhetStatusAC = (
  id: string,
  status: boolean
): changeWorkVidzhetStatusAT => ({
  type: "PRO-SETTINGS/CHANGE-WORK-VIDZHET-STATUS",
  status,
  id,
});
const deleteWorkServiceAC = (id: string): deleteWorkServiceAT => ({
  type: "PRO-SETTINGS/DELETE-WORK",
  id,
});
const editWorkServiceAC = (
  id: string,
  payload: createWorkFormType
): editWorkServiceAT => ({
  type: "PRO-SETTINGS/EDIT-WORK-SERVICE",
  id,
  payload,
});

const removeWorkCategoryAC = (id: string):removeWorkCategoryAT => {
  return {type: "PRO-SETTINGS/REMOVE-WORK-CATEGORY",id}
}
const editWorkCategoryAC = (id: string, name: string):editWorkCategoryAT => {
  return {type: "PRO-SETTINGS/EDIT-WORK-CATEGORY",payload: {id, name}}
}

export const searchUserForTeamTC =
  (id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiUser.findUserByProfileId(id);
      dispatch(searchUserForTeamAC(res.data));
    } catch (error: any | AxiosError) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(searchUserForTeamAC(null));
        }
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const createWorkCategoryTC =
  (category: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.create(category);
      dispatch(createCategoryAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getWorkCategoriesTC =
  (): thunkActionRootType => async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.getWorkCategories();
      dispatch(getCategoryAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const createWorkServiceTC =
  (dto: createWorkFormType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.createWorkService(dto);
      dispatch(createWorkServiceAC(res.data));
      dispatch(createWorkWindowAC(false));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const getWorkServicesTC =
  (): thunkActionRootType => async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.getWorkServices();
      dispatch(getWorkServicesAC(res.data));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const changeWorkActiveStatusTC =
  (id: string, status: boolean): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.changeActiveStatusWork(id, status);
      dispatch(changeWorkActiveStatusAC(id, status));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const changeWorkVidzhetStatusTC =
  (id: string, status: boolean): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.changeActiveStatusVidzhet(id, status);
      dispatch(changeWorkVidzhetStatusAC(id, status));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const deleteWorkServiceTC =
  (id: string): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.deleteWorkService(id);
      dispatch(deleteWorkServiceAC(id));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
    }
  };

export const editWorkServiceTC =
  (id: string, payload: createWorkFormType): thunkActionRootType =>
  async (dispatch) => {
    dispatch(setIsLoadingAC(true));
    try {
      const res = await apiProSettings.editWorkService(payload, id);
      dispatch(editWorkServiceAC(id, payload));
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
      dispatch(setIsLoadingAC(false));
      dispatch(editWorkServiceWindowAC({ value: false, workService: null }));
    }
  };

  export const removeWorkCategoryTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiProSettings.deleteWorkCategory(id)
      dispatch(removeWorkCategoryAC(id))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }

  export const editWorkCategoryTC = (id: string, name: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
      const res = await apiProSettings.editWorkCategory(id, name)
      dispatch(editWorkCategoryAC(id,name))
      dispatch(editWorkCategoryWindowAC({value: false, category: {id: "",category: ""}}))
    } catch (error) {
      
    } finally {
      dispatch(setIsLoadingAC(false))
    }
  }
