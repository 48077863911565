import { css, styled } from "styled-components";


type propsType = {
    component?: "TimeTable" | "Clients" | "settings" | "MobileTimeTable",
    colors?: "similiar" | "other",
    minHeight?: string
}
export const TableRow = styled.div<propsType>`
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
gap: 12px;
background: #F8F8F8;
border-radius: 8px;
height: 64px;
@media (max-width: 900px) {
        gap: 0px;
        padding: 3px;
    }

${props => props.colors === "other" && css<propsType>`
&:nth-child(2n){
    background: #f8f8f8;
};
&:nth-child(2n + 1){
    background: #fff;
} 
`};
${props => props.component === "TimeTable" && css<propsType>`
    justify-content: flex-start;
    height: 58px;
    min-height: 58px;
    padding: 0;
    margin-top: 6px;
`};
${props => props.component === "MobileTimeTable" && css<propsType>`
    height: 56px;
    min-height: 56px;
    justify-content: flex-start;
    padding: 3px 10px;
`};
${props => props.component === "Clients" && css<propsType>`
   
    gap: 0;
    &:hover {
        background: #E0E0E0;
    };
    @media screen and (max-width: 768px) {
        display: none;
    };
`};
${props => props.component === "settings" && css<propsType>`
   background: rgba(38, 38, 38, 0.10);
   &:nth-child(2n){
    background: #fff;
   };
   .tableRowItem {
    width: 100%;
    span{
        font-size: 12px;
    }
   };
   @media screen and (max-width: 768px) {
        padding: 5px;
        gap: 0;
    }

`};
${props => props.minHeight && css<propsType>`
    min-height: ${props => props.minHeight};
`};

.payment {
    @media screen and (max-width: 1400px) {
        display: flex;
    }
};



`