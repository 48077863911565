import { makeStyles } from "@material-ui/core";
import React from "react";


export const userInfoStyles = makeStyles({
    container: {
        minWidth: '752px',
        minHeight: '560px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',

    },
    miniContainer: {
        height: '388px',
        width: '752px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    description: {
        width: '380px',
        height: '205px',
    },
    info: {

        width: '324px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '560px'
    },

    vector: {

        border: '1px solid #C4C4C4'
    },
   
    btn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        width: '162px',
        height: '38px',
        borderRadius: '8px 0px 0px 8px',
        border: 'none',
        background: '#FFFFFF',
        fontFamily: 'Mont',
        fontWeight: 600,
        fontSize: '12px'


    },
    btnContainer: {
        display: 'flex',
        marginTop: '8px'
    },
    btnRight: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        width: '162px',
        height: '38px',
        fontWeight: 600,
        background: '#FFFFFF',
        borderRadius: '0px 8px 8px 0px',
        border: 'none',
        fontFamily: 'Mont',
        fontSize: '12px'

    },
    textItem: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#262626',
        textAlign: 'start'
    },
    select: {
        height: '38px',
        boxShadow: '0px 2px 4px rgb(0 0 0 / 8%)',
        maxWidth: '100%',
        marginTop: '8px',
        borderRadius: '8px',
        border: 'none',
        fontFamily: 'Mont',
        textIndent: '10px'

    },
    inputBox: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch'
    },
    btnEnd: {
        width: '100%',
        height: '44px',
        background: '#5D5FEF',
        borderRadius: '8px',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '100%',
        color: '#FFFFFF',
        border: 'none'
    },
    request: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '23px',

        /* #262626 */

        color: '#262626',
        maxWidth: '326px',
        maxHeight: '69px',
        textAlign: 'start',
        margin: '0 10px'
    },
    instruction: {
        marginTop: '32px'
    },
    aim: {
        fontWeight: 600,
        fontSize: '24px',
        textAlign: 'start',
        color: '#26262',
        marginLeft: '10px'
    },
    textDescription: {
        maxWidth: '380px',
        maxHeight: '72px',
        fontWeight: 600,
        fontSize: '12px',
        textAlign: 'start',
        marginLeft: '10px',
        marginTop: '8px',
    },
    active: {
        background: '#598866',
        color: 'white'
    },
    // form: {
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'space-between'
    // },
    state: {},
    vectorContainer: {},
    '@media (max-width: 768px)': {
        container: {
            dispaly: 'flex',
            flexDirection: 'column',
            height: '100%',
            marginTop: '0px',
            alignItems: 'center',
            overflow: 'scroll',
            gap: '10px'

        },

        description: {
            dispaly: 'flex',
            flexDirection: 'column',
            maxWidth: '320px',
            maxHeight: '229px',
            alignSelf: 'center',
            textAlign: 'start',
            marginLeft: '0',
            marginTop: '15px'
        },
        textDescription: {
            marginLeft: '0'
        },
        aim: {
            marginLeft: '0',
            fontSize: '16px'
        },
        request: {
            marginLeft: '0',

        },
        form: {
            gap: '7px'
        },
        vectorContainer: {
            display: 'none'
            /* border: '3px solid red',
             height: '320px',
            width: '0px'*/
            //transform: 'rotate(-90deg)'
        },
        vector: {
            width: '320px'
        },
        state: {

        },
        info: {
            //marginLeft: '10px',
            marginTop: '20px'
        },
        btnEnd: {
            marginTop: '24px',
            width: '320px'
        },
        miniContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0px auto',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        btn: {
            maxWidth: '160px'
        },
        btnRight: {
            maxWidth: '159px'
        },
        instruction: {
            marginTop: '20px'
        },

        inputBox: {
            width: '320px'
        }
    }
})

export default userInfoStyles