import AliceCarousel from "react-alice-carousel";
import { FlexBox } from "../../styles/components/FlexBox";
import { Icon } from "../../styles/components/Icon";
import { Text } from "../../styles/components/Text";
import { useEffect, useRef, useState } from "react";
import { addClientCommentTC } from "../../store/ClientCardReducer";
import { theme } from "../../styles/components/Theme.styled";
import { thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch } from "react-redux";
import { commentClientType } from "../../store/ClientsReducer";
import c from "./clientCard.module.css";
import { Box } from "../../styles/components/Box";

type propsType = {
  clientId: string;
  comments: commentClientType[];
};

export const ClientComments = (props: propsType) => {
  const [comments, setComments] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState(false)
  const dispatch: thunkDispatchRootType = useDispatch();
  const addComment = () => {
    if (comments) {
      dispatch(addClientCommentTC(props.clientId, comments));
      setComments("");
    } else {
      return;
    }
  };
  useEffect(() => {
    
      
      setIsUpdated(true)
    
  },[props.comments.length])
  return (
    <FlexBox  padding="0 8px" height="260px" width="40%" direction="column">
      <FlexBox
        direction="row"
        align="center"
        padding="0 0 0 15px"
        borderRadius="8px"
        background="rgba(68, 68, 68, 0.8)"
        height="51px"
        style={{ margin: "0 -8px" }}
        // className={c.head}
      >
        <Text color="#fff" font="mont" size={16}>
          Комментарий по клиенту
        </Text>
      </FlexBox>
      <FlexBox marginTop="5px" direction="row">
        {!!props.comments.length && (
          <AliceCarousel
          syncStateOnPropsUpdate = {!isUpdated}
          activeIndex={0}
            onUpdated={(e) => console.log("e",e)}
            renderNextButton={() => (
              <Box
              // onClick={() => cref.current?.slideTo(0)}
                style={{ alignItems: "center", justifyContent: "center" }}
                top="85px"
                left="50%"
                hoverBg="#262626"
                borderRaduis="50%"
                width="24px"
                height="24px"
                background={"rgba(68, 68, 68, 0.2)"}
                position="absolute"
              >
                <Icon fill="#fff" svgID="next" width={5} height={9} />
              </Box>
            )}
            renderPrevButton={() => (
              <Box
                hoverBg="#262626"
                style={{ alignItems: "center", justifyContent: "center" }}
                top="85px"
                borderRaduis="50%"
                width="24px"
                height="24px"
                background={"rgba(68, 68, 68, 0.2)"}
                position="absolute"
                left="40%"
              >
                <Icon
                  transform="rotate(180deg)"
                  fill="#fff"
                  svgID="next"
                  width={5}
                  height={9}
                />
              </Box>
            )}
            renderDotsItem={() => <></>}
            mouseTracking
            items={props.comments.map((el) => (
              <FlexBox
                height="78px"
                borderRadius="8px"
                border="1px solid rgba(68, 68, 68, 0.2)"
                direction="row"
                gap={5}
              >
                <FlexBox
                  padding="0 5px"
                  align="center"
                  direction="row"
                  width="10%"
                >
                  <Icon
                    fill="#fff"
                    svgID="message-comment"
                    width={28}
                    height={28}
                  />
                </FlexBox>

                <FlexBox
                  gap={10}
                  justify="center"
                  width="20%"
                  direction="column"
                >
                  <Text
                    size={10}
                    font="mont"
                    color={theme.colors.fonts.secondary}
                  >
                    {new Date(el.createdAt).toLocaleString("ru", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </Text>
                  <Text size={13} font="mont" color={theme.colors.fonts.main}>
                    {el.author}
                  </Text>
                </FlexBox>
                <FlexBox
                  padding="5px"
                  align="center"
                  direction="row"
                  width="70%"
                  height="100%"
                >
                  <Text
                    align="start"
                    size={11}
                    color={theme.colors.fonts.main}
                    font="mont"
                  >
                    {el.comment}
                  </Text>
                </FlexBox>
              </FlexBox>
            ))}
          />
        )}
      </FlexBox>

      <FlexBox
        // marginTop="-7px"
        borderRadius="8px"
        border="1px solid rgba(68, 68, 68, 0.2)"
        gap={5}
        align="center"
        height="78px"
        background={theme.colors.colorForm}
        padding="5px 10px"
        direction="row"
      >
        <textarea
          className={c.texArea}
          onChange={(e) => setComments(e.currentTarget.value)}
          value={comments}
          name=""
          id=""
          cols={30}
          rows={3}
        ></textarea>
        <button className={c.addComment} onClick={addComment}>
          Добавить
        </button>
      </FlexBox>
    </FlexBox>
  );
};
