import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDataUserTC, userInfoType } from "../store/UserReducer";
import userInfoStyles from "./userInfoStyles";
import { AppRootState } from "../store/storeRedux";
import { FlexBox } from "../styles/components/FlexBox";

type propsForMeType = {
    userId: string
}
const selectUser = (state: AppRootState) => state.user

export const ForMe = (props: propsForMeType) => {
    const user = useSelector(selectUser)
    const classes = userInfoStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        register,
        formState: {
            errors
        },
        handleSubmit
    } = useForm<userInfoType>()
    const onSubmit = (data: userInfoType) => {
        dispatch(setDataUserTC(props.userId, data))
        

    }
    useEffect(() => {
        if(user.user?.completeRegisterData.secondLevel){
            navigate("/greeting")
        }
    }, [user.user?.completeRegisterData.secondLevel])
    return (
        
            <FlexBox as={"form"} direction="column" height="100%" gap={24} onSubmit={handleSubmit(onSubmit)} >
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Отрасль</div>
                    <select {...register("kind")} className={classes.select} name="industry" >
                        <option value="Стоматология">Стоматология</option>
                        <option value="Красота и тело">Красота и тело</option>
                        <option value="Ремонт">Ремонт</option>
                        <option value="Обслуживание">Обслуживание</option>
                        <option value="Не выбрано">Не выбрано</option>
                    </select>
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Количество пользователей для ведения в CRM?</div>
                    <input {...register("userCRMcount")} className={classes.select} type="number" placeholder="Введите количество клиентов" />
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Сколько пользователей будут пользоваться CRM?</div>
                    <input {...register("teamMatesCount")} className={classes.select} placeholder="Введите количество людей" type="number" />
                </div>
                <div>
                    <button type="submit" className={classes.btnEnd}>Завершить регистрацию</button>
                </div>
            </FlexBox>

        
    )
}