import styled, { css } from "styled-components"
import sprite from "../../images/sprite.svg"
type propsType = {
    svgID: string,
    width: number,
    height: number,
    stroke?:string,
    fill?:string,
    transform?: string
}
type styleType = {
    stroke?:string,
    fill?:string,
    transform?: string
}
export const Icon = (props:propsType) => {
    return(
        <SVG stroke={props.stroke} fill={props.fill} transform={props.transform}  width={props.width} height={props.height}>
            <use xlinkHref={`${sprite}#${props.svgID}`}></use>
        </SVG>
    )
}

const SVG = styled.svg<styleType>`
    
    ${props => props.stroke && css<styleType>`
        stroke: ${props => props.stroke};
    `};
    ${props => props.fill && css<styleType>`
        fill: ${props => props.fill};
        
    `};
    ${props => props.transform && css<styleType>`
        transform: ${props => props.transform};
    `}
`