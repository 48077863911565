import { styled } from "styled-components";


export const LabelFile = styled.label`
    border-radius: 72px;
background: rgba(196, 196, 196, 0.50);
width: 72px;
height: 72px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
svg {
    width: 24px;
    height: 24px;
}
`