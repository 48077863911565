import { apiAnalytic } from "../apiServer/apiAnalytic";
import { analyticNoteType } from "../apiTypes/responses/analyticResponseType";
import { thunkActionRootType } from "./storeRedux";



export type  AnalyticStateType = {
    notesAnalyticItems: analyticNoteType[]
}

const initState:AnalyticStateType = {
    notesAnalyticItems: []
}

export type analyticAT = getAnalyticNoteAT

type getAnalyticNoteAT = {
    type: "ANALYTIC/NOTE",
    items: analyticNoteType[]
}

export const AnalyticReducer = (state: AnalyticStateType = initState, action: analyticAT): AnalyticStateType => {
    switch (action.type) {
        case "ANALYTIC/NOTE":
            return {...state, notesAnalyticItems: action.items}
        default:
            return state
    }
}


const getAnalyticNoteAC = (items: analyticNoteType[]):getAnalyticNoteAT => {
    return {type: "ANALYTIC/NOTE",items}
}
export const getAnalyticNoteTC = (start:Date, end: Date):thunkActionRootType => async dispatch => {
    try {
        const res = await apiAnalytic.getForNotes(start,end)
        dispatch(getAnalyticNoteAC(res.data))
    } catch (error) {
        
    }
} 