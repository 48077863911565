import { css, styled } from "styled-components";

type propsType = {
    column?: boolean,
    justify?: "start" | "end"
    align?: "start" | "end",
    width?: number,
    component?: "TimeTable",
    gap?: number
}

export const TextContainer = styled.div<propsType>`
    display: flex;
    
    ${props => props.column && css<propsType>`
        flex-direction: column;
    `};
    ${props => props.width && css<propsType>`
        width: ${props => props.width + "px"};
    `}
    ${props => props.justify === "start" && css<propsType>`
        justify-content: flex-start;
    `};
    ${props => props.justify === "end" && css<propsType>`
        justify-content: flex-end;
    `};
    ${props => props.align === "start" && css<propsType>`
        align-items: start;
    `};
    ${props => props.align === "end" && css<propsType>`
        align-items:end;
    `};
    gap: ${props => props.gap ? props.gap + "px" : 0};

`