


export type errorStateType = {
    loginEmailError: string,
    loginPasswordError: string,
    registerEmailError: string,
    profileReqError: {
        oldPassword: string,
        newPassword: string,
        successMessagePass: string
    },
    authEmailError: string,
    passwordCodeError: string,
    recoveryPassError: string, 
    registerPhone: string,
    registerLogin: string,
    createWorkTimeError: string,
    updateWorkTimeError: string
}

const initState: errorStateType = {
    loginEmailError: "",
    loginPasswordError: "",
    registerEmailError: "",
    authEmailError: "",
    profileReqError: {
        oldPassword: "",
        newPassword: "",
        successMessagePass: ""
    },
    passwordCodeError: "",
    recoveryPassError: "",
    registerPhone: "",
    registerLogin: "",
    createWorkTimeError: "",
    updateWorkTimeError: ""
}

export type errorAT = setLoginEmailErrorAT | setLoginPasswordErrorAT | setRegisterEmailErrorAT | setProfileReqErrorAT
    | setPassRecoveryAT | setPasswordCodeErrorAT | setRestorePassErrorAT | setRegisterEmailErrorAT | setRegisterLoginErrorAT
    | setRegisterPhoneErrorAT | setWorkTimeErrorAT | setUpdateWorkTimeErrorAT

type setLoginEmailErrorAT = {
    type: "ERROR/LOGIN-EMAIL",
    message: string
}
type setRegisterEmailErrorAT = {
    type: "ERROR/REGISTER-EMAIL",
    message: string
}
type setLoginPasswordErrorAT = {
    type: "ERROR/LOGIN-PASSWORD",
    message: string
}
type setProfileReqErrorAT = {
    type: "ERROR/PROFILE-REQ-ERROR",
    oldPassValue: string,
    newPassValue: string,
    passSuccessMess: string
}
type setPassRecoveryAT = {
    type: "ERROR/RECOVERY-PASSWORD",
    message: string
}
type setPasswordCodeErrorAT = {
    type: "ERROR/PASSWORD-CODE",
    message: string
}
type setRestorePassErrorAT = {
    type: "ERROR/RESTORE-PASSWORD",
    message: string
}
type setRegisterLoginErrorAT = {
    type: "ERROR/REGISTER-LOGIN",
    message: string
}
type setRegisterPhoneErrorAT = {
    type: "ERROR/REGISTER-PHONE",
    message: string
}

type setWorkTimeErrorAT = {
    type: "ERROR/CREATE-WORK-TIME",
    message: string
}

type setUpdateWorkTimeErrorAT = {
    type: "ERROR/UPDATE-WORK-TIME",
    message: string
}
export const ErrorMessagesReducer = (state: errorStateType = initState, action:errorAT):errorStateType => {
    switch(action.type){
        case "ERROR/LOGIN-EMAIL":
            return {...state, loginEmailError: action.message}
        case "ERROR/LOGIN-PASSWORD":
            return {...state, loginPasswordError: action.message}
        case "ERROR/REGISTER-EMAIL":
            return {...state, registerEmailError: action.message}
        case "ERROR/PROFILE-REQ-ERROR":
            return {...state, profileReqError: {newPassword: action.newPassValue, oldPassword: action.oldPassValue, successMessagePass: action.passSuccessMess}}
        case "ERROR/RECOVERY-PASSWORD":
            return {...state, authEmailError: action.message}
        case "ERROR/PASSWORD-CODE":
            return {...state, passwordCodeError: action.message}
        case "ERROR/RESTORE-PASSWORD":
            return {...state, recoveryPassError: action.message}
        case "ERROR/REGISTER-LOGIN":
            return {...state, registerLogin: action.message}
        case "ERROR/REGISTER-PHONE":
            return {...state, registerPhone: action.message}
        case "ERROR/CREATE-WORK-TIME":
            return {...state, createWorkTimeError: action.message}
        case "ERROR/UPDATE-WORK-TIME":
            return {...state, updateWorkTimeError: action.message}
        default:
            return state
    }
}

export const setLoginEmailErrorAC = (message: string):setLoginEmailErrorAT => {
    return {type: "ERROR/LOGIN-EMAIL",message}
}
export const setRegisterEmailErrorAC = (message: string):setRegisterEmailErrorAT => {
    return {type: "ERROR/REGISTER-EMAIL",message}
}
export const setLoginPasswordErrorAC = (message: string):setLoginPasswordErrorAT => {
    return {type: "ERROR/LOGIN-PASSWORD",message}
}
export const setProfileReqErrorAC = (val1: string, val2: string, val3: string):setProfileReqErrorAT => {
    return {type: "ERROR/PROFILE-REQ-ERROR", newPassValue: val1, oldPassValue: val2, passSuccessMess: val3}
}
export const setPassRecoveryAC = (message: string):setPassRecoveryAT => {
    return {type: "ERROR/RECOVERY-PASSWORD",message}
}
export const setPasswordCodeErrorAC = (message: string): setPassRecoveryAT => {
    return {type: "ERROR/RECOVERY-PASSWORD", message}
}
 export const setRestorePassErrorAC = (message: string): setPassRecoveryAT => {
    return {type: "ERROR/RECOVERY-PASSWORD", message}
 }
 export const setRegisterLoginErrorAC = (message: string):setRegisterLoginErrorAT => {
    return {type: "ERROR/REGISTER-LOGIN", message}
 }
 export const setRegisterPhoneErrorAC = (message: string):setRegisterPhoneErrorAT => {
    return {type: "ERROR/REGISTER-PHONE", message}
 }

 export const setWorkTimeErrorAC = (message: string):setWorkTimeErrorAT => {
    return {type: "ERROR/CREATE-WORK-TIME",message}
 }

 export const setUpdateWorkTimeErrorAC = (message: string):setUpdateWorkTimeErrorAT => {
    return {type: "ERROR/UPDATE-WORK-TIME",message}
 }
