import { styled } from "styled-components";





export const NoteMedia = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
`