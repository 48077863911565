
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUserTC } from "../store/UserReducer";
import { AppRootState } from "../store/storeRedux";
import { setLoginEmailErrorAC, setLoginPasswordErrorAC } from "../store/ErrorMessagesReducer";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { TextField } from "../styles/components/TextField";
import { SuperButton } from "../styles/components/SuperButton";



const selectUser = (state: AppRootState) => state.user
const selectError = (state: AppRootState) => state.errorState

export const Login = () => {
    const user = useSelector(selectUser)
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const erroState = useSelector(selectError)
    const dispatch = useDispatch()
    function loginUser() {
        dispatch(loginUserTC(email, password))
    }

    
    return (
        <FlexBox direction="row" align="center" justify="center" height="100%" width="100%">

            <FlexBox direction="column" width="325px" height="440px" justify="space-between">
                <FlexBox align="start" direction="column" width="100%" height="64px">
                    <Text font="mont" color={theme.colors.fonts.main} size={28}>Войдите в аккаунт</Text>
                    <Text size={14} color={theme.colors.fonts.main} font="mont">Для входа введите email и пароль</Text>
                </FlexBox>
                <FlexBox direction="column" width="100%" gap={30} as={"form"} onSubmit={(e: { preventDefault: () => void; }) => e.preventDefault()}>
                    <FlexBox gap={2} align="start" direction="column" width="100%">
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Email</Text>
                        <TextField error = {erroState.loginEmailError ? true : false} width="100%" value={email} onChange={e => {
                            dispatch(setLoginEmailErrorAC(""))
                            setEmail(e.currentTarget.value)
                        }} type="email" placeholder="" />
                        <Text size={14} font="mont" color="#ff2424">{erroState.loginEmailError}</Text>
                    </FlexBox>
                    <FlexBox gap={2} align="start" direction="column" width="100%">
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Пароль</Text>
                        <TextField error = {erroState.loginPasswordError ? true : false} width="100%" value={password} onChange={e => {
                            dispatch(setLoginPasswordErrorAC(""))
                            setPassword(e.currentTarget.value)
                        }} type="password" placeholder="" />
                        <Text font="mont" size={14} color="#ff2424">{erroState.loginPasswordError}</Text>
                        <FlexBox width="100%" direction="row" align="end" justify="end">
                            <Link style={{fontSize: "15px"}} to={'/forgot'}>Забыли пароль?</Link>
                        </FlexBox>


                    </FlexBox>
                </FlexBox>

                <FlexBox direction="column" width="100%">
                    <SuperButton hover hoverColor={theme.colors.fonts.main} varinat="contained" height="48px" onClick={loginUser}>Войти</SuperButton>
                    <FlexBox gap={3} align="center" justify="center"  direction="row" marginTop="20px">
                        <Text color={theme.colors.fonts.main} font="mont" size={14}>Нет аккаунта?</Text>
                        <Text color={theme.colors.main} font="mont" size={14} as={Link} to={'/register'}> Зарегистрируйтесь</Text>
                    </FlexBox>
                </FlexBox>

            </FlexBox>
        </FlexBox>
    )
}

export default Login