
import { add, addDays, addMonths, endOfMonth, endOfWeek, isAfter, isBefore, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { ru } from 'date-fns/locale';
import { AppRootState } from '../store/storeRedux';
import { ModalWindowStateType, showCalendarWorkDaysAC, showModalForUpdateDayAC } from '../store/ModalWindowsReducer';
import { FlexBox } from '../styles/components/FlexBox';
import { NextPrevButton } from '../styles/components/NextButton';
import { SuperButton } from '../styles/components/SuperButton';
import { theme } from '../styles/components/Theme.styled';
import { Grid } from '../styles/components/Grid';
import { Text } from '../styles/components/Text';
import { calendarStateType, getWorkDaysTC, selectDayAC, updateDate } from '../store/CalendarReducer';
import { setMonthForSettingsAC } from '../store/SettingReducer';
import { Box } from '../styles/components/Box';
import { ModalLayer } from '../styles/components/ModalLayer';
import { getNoteForMonthTC } from '../store/TimeTableReducer';
import { calendarTrafficType } from '../apiTypes/state/calendarTraffic-type';


type propsType = {
    setDateForWeek(date: Date): void
}
type settingDateType = { dayOrder: number, dateCurrentDay: string }[]
type datesType = {
    date: Date,
    isPresent: boolean
}
type renderDatesTypes = datesType & { busyness: calendarTrafficType }
type trafficDatesType = datesType & {trafficCount: number}
const selectUser = (state: AppRootState) => state.user
const selectTimeTableState = (state:AppRootState) => state.timeTableState

export const CalendarTraffic = (props: propsType) => {
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [start, setStart] = useState<Date>(new Date())
    const [startPeriod, setStartPeriod] = useState<Date | null>(null)
    const [endPeriod, setEndPeriod] = useState<Date | null>(null)
    const [dates, setDates] = useState<Array<datesType>>([])
    const calendarState = useSelector<AppRootState, calendarStateType>(state => state.calendarState)
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)
    const timeTableState = useSelector(selectTimeTableState)
    const prevMonth = () => {
        setStart(subMonths(start, 1))
    }
    const nextMonth = () => {
        setStart(addMonths(start, 1))
    }

    const dateHandler = (date: Date) => {
        props.setDateForWeek(date)
        dispatch(showCalendarWorkDaysAC(false))
    }
    const returnBusynessType = (count: number):calendarTrafficType => {
        if(count <= 3 && count > 0) return calendarTrafficType.SEVERAL
        if(count > 3 && count <= 5) return calendarTrafficType.MORE
        if(count > 5) return calendarTrafficType.UPLOADED
        return calendarTrafficType.NONE
    }
    const returnBackGround = (item:calendarTrafficType):string => {
        if(item === calendarTrafficType.SEVERAL) return "rgba(38, 38, 38, 0.25)"
        if(item === calendarTrafficType.MORE) return "rgba(38, 38, 38, 0.75)"
        if(item === calendarTrafficType.UPLOADED) return "#262626"
      return "#fff"

    }
    const renderDates = (): JSX.Element => {
        const trafficDates: trafficDatesType[] = dates.map((el) => {
            const count = timeTableState.notesForWeek.filter(n => new Date(n.date).toLocaleDateString() === el.date.toLocaleDateString()).length
            return { date: el.date, isPresent: el.isPresent, trafficCount: count }

        })
        const renderDates:renderDatesTypes[] = trafficDates.map(el => ({date:el.date, isPresent: el.isPresent, busyness: returnBusynessType(el.trafficCount)}))
        return <Grid width='100%' height='100%' columns='1fr 1fr 1fr 1fr 1fr 1fr 1fr' gap={2}>
            {renderDates.map(d =>

                <FlexBox border='2px solid #fff' className='date' align='center' justify='center' background={returnBackGround(d.busyness)} borderRadius='8px' width='100%' height='100%' direction='row' onClick={() => dateHandler(d.date)}>
                    <Text font='mont' size={14} color={d.busyness === calendarTrafficType.NONE ? "#262626" : "#fff"}>{d.date.getDate()}</Text>
                </FlexBox>
            )
            }
        </Grid>
    }
    const fillCalendar = () => {
        const datesArray: datesType[] = []
        const datesForSettngs: settingDateType = []
        let firstDay = startOfMonth(start)
        let weekOfFirst = startOfWeek(firstDay, { locale: ru })
        const lastDay = endOfMonth(start)
        let weekOfEnd = endOfWeek(lastDay, { locale: ru })
        while (isBefore(weekOfFirst, weekOfEnd)) {
            if (weekOfFirst.getMonth() === start.getMonth()) {
                datesForSettngs.push({
                    dayOrder: weekOfFirst.getDay() === 0 ? weekOfFirst.getDay() + 7 : weekOfFirst.getDay(),
                    dateCurrentDay: weekOfFirst.toLocaleDateString()
                })
            }
            datesArray.push({ date: weekOfFirst, isPresent: weekOfFirst.getMonth() === start.getMonth() })
            weekOfFirst = addDays(weekOfFirst, 1)
        }
        setDates(datesArray)
        dispatch(setMonthForSettingsAC(datesForSettngs, start.toLocaleString('ru', { month: 'numeric', year: 'numeric' })))
        setStartPeriod(firstDay)
        setEndPeriod(lastDay)
    }

    let weekDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"]
    const year = start.getFullYear()
    const month = start.toLocaleString("ru", { month: "long" })

    useEffect(() => {
        fillCalendar()
    }, [start])
    useEffect(() => {
        if(startPeriod && endPeriod){
            dispatch(getNoteForMonthTC(user.user?.id as string,startPeriod.toISOString(),endPeriod.toISOString() ))
        }
       
    },[startPeriod,endPeriod])

    return (
        <>
            <ModalLayer onClick={() => dispatch(showCalendarWorkDaysAC(false))}/>
            {

                <Box zIndex={35} position='fixed' boxShadow='0px 4px 8px 0px rgba(0, 0, 0, 0.25)' width='350px' height='370px' borderRaduis='16px' background='#fff' top='50%' left='50%' transform='translate(-50%,-50%)'>

                    <FlexBox direction='column' align='center' justify='center' width='100%' height='100%' padding='14px 17px'>
                        <Text size={14} color={theme.colors.fonts.main} font='mont'>Выберите день для создания интервала</Text>
                        <Text size={14} font='mont' color={theme.colors.fonts.main}>{year}</Text>

                        <FlexBox padding='0 20px' direction='row' width='100%' justify='space-between' align='center'>
                            <SuperButton onClick={prevMonth} maxWidth='15px' varinat='fake'>
                                <NextPrevButton component="calendar" type='prev' />
                            </SuperButton>
                            <Text transform='capitalize' font='mont' size={14} color={theme.colors.fonts.main}>{month}</Text>
                            <SuperButton onClick={nextMonth} maxWidth='15px' varinat='fake'>
                                <NextPrevButton component='calendar' type='next' />
                            </SuperButton>
                        </FlexBox>
                        <FlexBox height='100%' padding='15px 0' width='100%' direction='column'>
                            <FlexBox direction='row' width='100%' justify='space-between'>
                                {
                                    weekDays.map(w =>
                                        <FlexBox align='center' justify='center' width='100%' direction='row' key={new Date().toISOString() + w}>
                                            <Text transform="capitalize" weight={500} font='Inter' color={theme.colors.fonts.main} size={15} >{w}</Text>
                                        </FlexBox>
                                    )
                                }

                            </FlexBox>
                            <FlexBox height='100%' direction='row'>
                                {renderDates()}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Box>
            }


        </>

    )
}






