import axios from "axios";
import { tokenService } from "./tokenService";
import { workCategoryType, workServiceType } from "../apiTypes/root/proSettings.type";
import { createWorkFormType } from "../apiTypes/formTypes/createWorkFormType";
import { apiAuth } from "./apiAuth";


const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/user-pro`
})


instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token)  config!.headers!.Authorization = token
        return config
    },
    (error) => Promise.reject(error)
    
)

instance.interceptors.response.use(
   (response) => response,
   async (error) => {
     const { config } = error;
     if (error.response.status === 401 && !config._retry) {
       config._retry = true;
       try {
         const res = await apiAuth.refreshToken();
         tokenService.saveToken(res.data.accessToken);
         instance.defaults.headers.common.Authorization =
           tokenService.getToken();
         return instance(config);
       } catch (error) {
         tokenService.removeToken();
       }
     } else {
       tokenService.removeToken();
     }
     return Promise.reject(error);
   }
 );

export const apiProSettings = {
     create(category: string){
        return instance.post<workCategoryType>('/create/work-category', {category})
     },
     getWorkCategories(){
        return instance.get< workCategoryType[]>("/get/work-category")
     },
     deleteWorkCategory(categoryId: string){
      return instance.delete(`/delete/work-category/${categoryId}`)
     },
     editWorkCategory(categoryId: string,name: string){
      return instance.put(`/edit/work-category/${categoryId}`, {name})
     },
     createWorkService(dto: createWorkFormType){
        return instance.post("/work-service",dto)
     },
     getWorkServices(){
        return instance.get<workServiceType[]>("/work-service")
     },
     changeActiveStatusWork(id: string, status: boolean){
        return instance.put(`/work-service/${id}/active`, {status})
     },
     changeActiveStatusVidzhet(id: string, status: boolean){
        return instance.put(`/work-service/${id}/vidzhet`,{status})
     },
     deleteWorkService(id: string){
        return instance.delete(`/work-service/${id}`)
     },
     editWorkService(dto: createWorkFormType, id: string){
        return instance.put(`/work-service/${id}`,dto)
     }
}