import c from "./clientCard.module.css";
import plus from "../../assets/actions/plus.svg";
import phone from "../../assets/actions/phone.svg";
import cat from "../../assets/actions/cat.png";
import { v1 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { notesStateType } from "../../store/NotesReducer";
import { useEffect, useRef, useState } from "react";
import { stateUserType, tariffType } from "../../store/UserReducer";
import addPhoto from "../../assets/add-photo.png";
import "../../styles/global/alice.css";
import {
  clientBalanceWindowAC,
  editClientWindowAC,
  ModalWindowStateType,
  remindWindowAC,
  selectClientAC,
  setDateAndTimeAC,
  showNewEntryAC,
  showQueueAC,
} from "../../store/ModalWindowsReducer";
import time from "../../assets/actions/time.svg";
import {
  addClientCardPhotoTC,
  addClientCommentTC,
  clientCardStateType,
  resetClientCardAC,
  setClientCardTC,
} from "../../store/ClientCardReducer";
import ring2 from "../../assets/ring2.svg";
import row from "../../assets/row.svg";
import { NewEntry } from "../../modalWindows/child/NewEntry";
import { sessionsType, setSessionsAC } from "../../store/SessionsReducer";
import { useParams } from "react-router-dom";
import { Works } from "./works/Works";
import { getReminderClientTC } from "../../store/RemindersReducers";
import { FlexBox } from "../../styles/components/FlexBox";
import { theme } from "../../styles/components/Theme.styled";
import { Text } from "../../styles/components/Text";
import { Box } from "../../styles/components/Box";
import { Icon } from "../../styles/components/Icon";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ClientComments } from "./ClientComments";
import { ClientVisits } from "./ClientVisits";
import { ClientQueue } from "./ClientQueue";

type actionsType = {
  id: string;
  title: string;
  img: string;
  cb?: () => void;
};

type newPhotoFileType = {
  fileName: string;
  file: File;
  type: string;
};
const selectReminders = (state: AppRootState) => state.remindersState;
export const ClientCard = () => {
  const fileReader = new FileReader();
  const formatter = new Intl.NumberFormat("ru", {
    style: "currency",
    currency: "rur",
  });

  const { id } = useParams();
  const [photoLabel, showPhotoLabel] = useState(false);
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [newPhotoFile, setNewPhotoFile] = useState<newPhotoFileType | null>(
    null
  );
  const dispatch: thunkDispatchRootType = useDispatch();
  const showClientPay = () =>
    dispatch(clientBalanceWindowAC({ value: true, client: clientCard }));
  const showQueue = () =>
    dispatch(showQueueAC({ value: true, client: clientCard }));

  const client = useSelector<AppRootState, clientCardStateType>(
    (state) => state.clientCard
  );
  const clientCard = client.client;

  const user = useSelector<AppRootState, stateUserType>((state) => state.user);
  const reminders = useSelector(selectReminders);
  const notes = useSelector<AppRootState, notesStateType[]>(
    (state) => state.notes
  );

  const phoneField: {
    id: string;
    title: string;
    value: string | undefined | null;
  }[] = [
    { id: v1(), title: "Тел:", value: clientCard.phone && clientCard.phone },
    { id: v1(), title: "Скидка:", value: "" },
    //{ id: v1(), title: "Номер карты:" },
    {
      id: v1(),
      title: "День рождения: ",
      value: JSON.stringify(clientCard.birthday).substring(1, 11),
    },
  ];
  const showEditClient = () => {
    dispatch(editClientWindowAC({value: true, client: clientCard}));
  };
  const showNewEntry = () => {
    dispatch(setDateAndTimeAC("", "", ""));
    dispatch(showNewEntryAC({value: true, fromQueue: false}));
    dispatch(selectClientAC(clientCard));
  };
  const actionsArray: actionsType[] = [
    // { id: v1(), title: "Отправить SMS", img: shape },
    // { id: v1(), title: "Документы", img: document },
    { id: v1(), title: "В очередь", img: "users-more", cb: showQueue },
    { id: v1(), title: "Записать на прием", img: "pinpaper", cb: showNewEntry },
    { id: v1(), title: "Редактировать", img: "edit-4", cb: showEditClient },
    { id: v1(), title: "Пополнить счет", img: "card", cb: showClientPay },
  ];

  const addRemindWindow = () => {
    dispatch(remindWindowAC({ value: true, client: clientCard }));
  };
  const photoHandler = (file: null | File) => {
    if (file) {
      fileReader.readAsDataURL(file);
      setNewPhotoFile({ file, fileName: file.name, type: file.type });
    }
  };
  fileReader.onloadend = () => {
    if (fileReader.result) {
      setNewPhotoURL(fileReader.result.toString());
    }
  };
  const uploadClientPhoto = () => {
    if (newPhotoFile) {
      const { file, fileName, type } = newPhotoFile;
      dispatch(addClientCardPhotoTC(file, type, fileName, clientCard.id));
      showPhotoLabel(false)
    }
  };
  const [showPopup, setShowPopup] = useState(false);
     const handleBtnActions = () => {
         setShowPopup((prevShowPopup) => !prevShowPopup);
       };
  // useEffect(() => {
  //     // dispatch(getNotesTC(user.user?._id as string))
  //     dispatch(getPayTC(clientCard.id))
  //     // dispatch(getQueueTC(clientCard.id))
  //     dispatch(getRemindTC(clientCard.id))
  // }, [JSON.stringify(notes)])
  // useEffect(() => {
  //     const visits = notes.filter(n => n.phone === clientCard.phone && new Date(n.date.split(".").reverse().join("-")) < new Date())
  //     setVisits(visits.length || 0)
  //     const currentVisits = notes.sort((a, b) => a.date && b.date && new Date(a.date.split(".").reverse().join("-")) < new Date(b.date.split(".").reverse().join("-")) ? 1 : -1).slice(0, 3)
  //     setLastVisits(currentVisits);

  // }, [notes.length])
  // useEffect( () => {
  //     const counter = remindersState.filter( el => el.isActive === true).length
  //     setCount(counter)
  // }, [JSON.stringify(remindersState)])
  const handleDragStart = (e: any) => e.preventDefault();

  const responsive = {
    0: { items: 1 },
    560: { items: 2 },
    1024: { items: 3 },
  };

  const listInnerRef = useRef<any>();
  const onScroll = () => {
    //@ts-ignore
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      return new Promise((res, rej) => {
        setTimeout(() => res("data"), 500);
      }).then((res) => console.log("res", res));
    }
  };
  useEffect(() => {
    console.log("log1")
    if (id) {
      dispatch(setClientCardTC(id));
      dispatch(getReminderClientTC(id));
    }
    return () => {
      dispatch(resetClientCardAC())
    }
  }, []);
  useEffect(() => {
    if (!photoLabel) {
      setNewPhotoURL("");
      setNewPhotoFile(null);
    }
  }, [photoLabel]);
  return (
    <div style={{width:"95%", height:"100vh", overflowY:"scroll"}} className={c.wrapper}>
      <div className={c.title}>
        <span>Карточка клиента</span>
      </div>
      <div className={c.info} style={{ height: showPopup ? '550px' : '350px' }}>
        <div className={c.name}>
          <div className={c.photo}>
            <div className={c.ava}>
              <img src={clientCard.photoURL} alt="" />
            </div>
            <div className={c.nameSurname}>
              <span className={c.n}>{clientCard.surname}</span>
              <div className={c.np}>
                <span>{clientCard.name}</span>
                <span>{clientCard.patronymic}</span>
              </div>
            </div>
          </div>
          <div className={c.balanceContainer}>
            <div className={`${c.item} ${c.visits}`}>
              <span>Визитов</span>
              <Text color={theme.colors.fonts.main} font="Inter" size={24}>
                {clientCard.visitCount}
              </Text>
            </div>

            <div className={`${c.item} ${c.spent}`}>
              <span>Потрачено</span>
              <Text color={theme.colors.fonts.main} font="Inter" size={24}>
                {formatter.format(client.client.spentMoneyCount)}
              </Text>
            </div>
            <div className={`${c.item} ${c.balance}`}>
              <span>Баланс</span>
              <Text color={theme.colors.fonts.main} font="Inter" size={24}>
                {formatter.format(client.client.balance)}
              </Text>
            </div>
            <div className={c.actionClient}>
                         <button onClick={handleBtnActions}>Действия с клиентом</button>
                     </div>
                     {showPopup && ( 
                             <div className={c.action2}>
                             {actionsArray.map((el) => (
                                 <button onClick={el.cb} key={el.id}>
                                 <span>{el.title}</span>
                                 <img src={el.img} alt="" />
                                 </button>
                             ))}
                             </div>
                     )}
          </div>
        </div>
        <div className={c.phone}>
          {phoneField.map((el) => (
            <div key={el.id}>
              <span>{el.title}</span>
              <span>{el.value}</span>
            </div>
          ))}
        </div>
        <div className={c.action}>
          {actionsArray.map((el) => (
            <button onClick={el.cb && el.cb} key={el.id}>
              <Text font="mont" size={11} color={theme.colors.fonts.secondary}>
                {el.title}
              </Text>
              <Icon fill="#fff" stroke={theme.colors.main} svgID={el.img} width={24} height={24} />
            </button>
          ))}
        </div>
      </div>
      <div className={c.notification}>
        <ClientVisits showNewEntry={showNewEntry} clientId={clientCard.id} />
        <div className={`${c.itemNot} `}>
          <div className={c.add}>
            <div className={c.addContent}>
              <span>Напоминания</span>
              <img onClick={addRemindWindow} src={plus} alt="" />
            </div>
          </div>
          <Box
            ref={listInnerRef}
            onScroll={onScroll}
            gap={3}
            direction="row"
            width="100%"
            height="100%"
            padding="5px"
            overFlowY="auto"
          >
            <FlexBox direction="column" gap={5} width="100%">
              {reminders.clientReminders.map((el) => (
                <FlexBox
                  direction="row"
                  width="100%"
                  height="78px"
                  gap={5}
                  padding="0 5px"
                  background={theme.colors.colorForm}
                  border="1px solid rgba(0, 0, 0, 0.2)"
                  borderRadius="8px"
                >
                  <FlexBox direction="row" height="100%" width="12%">
                    <img src={ring2} alt="" />
                  </FlexBox>
                  <FlexBox
                    gap={5}
                    direction="column"
                    width="100%"
                    height="100%"
                    padding="5px"
                  >
                    <FlexBox
                      direction="row"
                      width="100%"
                      justify="space-between"
                    >
                      <img src={row} alt="" />
                      <Text
                        color={theme.colors.fonts.secondary}
                        font="mont"
                        size={11}
                      >
                        {el.userName}
                      </Text>
                      <Text color="black" font="Inter" size={12} weight={600}>
                        на {new Date(el.eventDate).toLocaleDateString()}
                      </Text>
                    </FlexBox>
                    <Text
                      color={theme.colors.fonts.secondary}
                      size={11}
                      font="mont"
                    >
                      {el.comment}
                    </Text>
                  </FlexBox>
                </FlexBox>
              ))}
            </FlexBox>
          </Box>
        </div>
        <div className={`${c.itemNot} ${c.req}`}>
          <div className={c.add}>
            <div className={c.addContent}>
              <span>Обращения</span>
            </div>
          </div>
          <div className={c.reqContent}>
            <img src={phone} alt="" />
            <p>
              Список заинтересованных клиентов, обратившихся из виджета на сайте
              или мессенжера
            </p>
          </div>
        </div>
        <ClientQueue clientId={clientCard.id} showQueue={showQueue} />
      </div>
      <div className={c.photos}>
        <div className={`${c.photosItem} ${c.photosPhoto}`}>
          {photoLabel && (
            <div className={c.photoLabel}>
              <label className={c.photoLabelContainer}>
                <input
                  onChange={(e) => {
                    if (e.target.files) photoHandler(e.target.files[0]);
                  }}
                  type="file"
                />
                {newPhotoURL ? (
                  <FlexBox direction="row" width="130px"  height="130px">
                    <img style={{ borderRadius: "4px", width: "100%", objectFit: "cover"}} alt="" src={newPhotoURL} />
                  </FlexBox>
                ) : (
                  <>
                    <FlexBox
                      gap={10}
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <FlexBox padding="0 5px" direction="row">
                        <Text align="left" color="black" font="mont" size={18}>
                          Поместите фото в центре
                        </Text>
                      </FlexBox>

                      <img src={addPhoto} alt="" />
                    </FlexBox>
                  </>
                )}
              </label>
              <FlexBox
                gap={5}
                align="center"
                justify="center"
                direction="row"
                width="100%"
              >
                <Text size={14} font="mont" color="black">
                  нажмите
                </Text>
                <label onClick={uploadClientPhoto} className={c.photoLabelBtn}>
                  загрузить
                </label>
              </FlexBox>
            </div>
          )}
          <header className={c.head}>
            <span>Фотографии до и после выполнения работ</span>
            <div
              onClick={() => showPhotoLabel((prev) => !prev)}
              className={c.plusContainer}
            >
              <img src={plus} alt="" />
            </div>
          </header>
          <div className={c.photosContainer}>
            {!!clientCard.workPhotos.length && (
              <AliceCarousel
                responsive={responsive}
                mouseTracking
                items={clientCard.workPhotos.map((c, i) => (
                  <img
                    onDragStart={handleDragStart}
                    alt=""
                    key={c + i}
                    src={c}
                  />
                ))}
              />
            )}
          </div>
        </div>
        <ClientComments
          clientId={clientCard.id}
          comments={clientCard.comments}
        />
      </div>
      <Works client={clientCard} />
    </div>
  );
};
