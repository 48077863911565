import { useDispatch, useSelector } from "react-redux"
import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import { Icon } from "../../styles/components/Icon"
import { SuperButton } from "../../styles/components/SuperButton"
import { Text } from "../../styles/components/Text"
import { TextField } from "../../styles/components/TextField"
import { theme } from "../../styles/components/Theme.styled"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { debitPayTC } from "../../store/UserPayReducer"
import { showadminPayWindowAC, showQrCodeWindowAC } from "../../store/ModalWindowsReducer"
import { useEffect, useState } from "react"
import { userPayTC } from "../../store/UserReducer"
import { adminIncUserBalanceTC } from "../../store/AdminReducer"


const selectUserPayState = (state:AppRootState) => state.userPayState
const selectModalsate = (state:AppRootState) => state.ModalWindowReducer
const selectUser = (state:AppRootState) => state.user
export const PayWindow = () => {
    const [balance,setBalance] = useState(0)
    const userPayState = useSelector(selectUserPayState)
    const modalState = useSelector(selectModalsate)
const user = useSelector(selectUser)
    const dispatch:thunkDispatchRootType = useDispatch()
    const payHandler = () => {
        //dispatch(showQrCodeWindowAC(true))
      if(user.user?.isAdmin && modalState.adminPayWindow){
        dispatch(adminIncUserBalanceTC(modalState.adminPayWindow.userId, balance))
      } else {
        dispatch(userPayTC(balance))
      } 
    }
    useEffect(() => {
        return () => {
            showadminPayWindowAC({value: false, userId: ''})
        }
        
    },[])
    return (
        <Box direction="row" position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" borderRaduis="8px" zIndex={27} background={theme.colors.colorForm} width="463px" height="74px">
            <FlexBox padding="5px" gap={5} direction="row" width="100%" height="100%" align="center" justify="center">
                <TextField onChange={(e) => setBalance(+e.currentTarget.value)} type="number" placeholder="Сумма" height="36px" width="100%" />
                <SuperButton onClick={payHandler } hover hoverColor="#262626" style={{display: "flex", alignItems: "center",justifyContent: "center", gap: "3px"}} varinat="contained" height="36px" maxWidth="120px" minWidth="120px">
                    <Icon transform="scale(0.8)" svgID="pay" height={24} width={27}/>
                    <Text color="#fff" font="mont" size={12}>Оплатить</Text>
                </SuperButton>
            </FlexBox>

        </Box>
    )
}