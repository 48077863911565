import { Status } from "../../store/NotesReducer";

export const noteHelper = {
    getSvgId(status: Status):string {
        if(status === Status.CONFIRMED){
            return "calendar-check"
        } else if(status === Status.WAIT){
            return "calendar-user"
        } else if(status === Status.IN_Office){
            return "user-check"
        } else if(status === Status.HELD){
            return "calendar-plus"
        } else if(status === Status.PORTING){
            return "calendar-edit"
        } else if(status === Status.NOT_ANSWER){
            return "phone-cross"
        } else if(status === Status.NOT_COME){
            return "user-cross"
        } else if(status === Status.REFUSE){
            return "calendar-cross"
        }
        return "calendar-cross"
        
    }
}