import { styled } from "styled-components";


export const NoteInfo = styled.div`
display: flex;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 12px;
gap: 3px;
flex-direction: column;
justify-content: center;
color: #FFFFFF;

`