import { io } from "socket.io-client";
import { tokenService } from "./tokenService";



const url = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://localhost:3002"
export let socket = io(url, {
     transports: ["websocket"],
     auth: {token: tokenService.getToken()} ,
    
    
    })


