import styled from "styled-components";


type propsType = {
    color: string
}
export const LabelInputColor = styled.label<propsType>`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid ${props => props.color ? props.color : "blue"};
    background: ${props => props.color ? props.color : "blue"};
    display: flex;
    align-items: center;
    justify-content: center;
    input {
        width: 50px;
        height: 30px;
        opacity: 0;
    }
`