import { AxiosError, isAxiosError } from "axios";
import { apiUserPay } from "../apiServer/apiUserPay";
import { apiUserPro } from "../apiServer/apiUserPro";
import {  tarifHistoryType } from "../apiTypes/root/tarifHistory.type";
import { userPayHistoryType, userProType } from "../apiTypes/root/userPro.type";
import { setIsLoadingAC } from "./AppReducer";
import { logoutTC } from "./AuthReducer";
import { thunkActionRootType } from "./storeRedux";
import { clientRemindersViewForNotifType } from "../apiTypes/root/reminders.type";

export type userProAT = getTarifHistoryAT | getPayHistoryAT | getClientRemondersAT | deactivateClientReminderAT

type getTarifHistoryAT = {
  type: "USER-PRO/GET-TARIF-HISTORY";
  payload: tarifHistoryType;
};
type getPayHistoryAT = {
  type: "USER-PRO/GET-PAY-HISTORY";
  payload: userPayHistoryType[];
};

type getClientRemondersAT = {
  type: "USER-PRO/NOTIFICATIONS/CLIENT",
  payload: clientRemindersViewForNotifType[]
}
type deactivateClientReminderAT = {
  type: "USER-PRO/NOTIFICATION/DEACTIVATE/CLIENT-REMINDER",
  id: string
}
const iniState: userProType = {
    tarifHistory: {
        items: []
    },
    payHistory: [],
    notifications: {
      clientReminders: []
    }
    
}
export const UserProReducer = (
  state: userProType = iniState,
  action: userProAT
): userProType => {
  switch (action.type) {
    case "USER-PRO/GET-TARIF-HISTORY":
      return { ...state, tarifHistory: action.payload };
    case "USER-PRO/GET-PAY-HISTORY":
        return {...state, payHistory: action.payload}
      case "USER-PRO/NOTIFICATIONS/CLIENT":
        return {...state, notifications: {...state.notifications, clientReminders: action.payload}}
      case "USER-PRO/NOTIFICATION/DEACTIVATE/CLIENT-REMINDER":
        return {...state, notifications: {...state.notifications, clientReminders: state.notifications.clientReminders.filter(r => r.id !== action.id)}}
    default:
      return state;
  }
};

const getTarifHistoryAC = (payload: tarifHistoryType):getTarifHistoryAT => ({type: "USER-PRO/GET-TARIF-HISTORY",payload})
const getPayHistoryAC = (payload: userPayHistoryType[]):getPayHistoryAT => ({type: "USER-PRO/GET-PAY-HISTORY",payload})
const getClientRemindersAC = (payload: clientRemindersViewForNotifType[]):getClientRemondersAT => ({type: "USER-PRO/NOTIFICATIONS/CLIENT",payload})
const deactivateClientReminderAC = (id: string):deactivateClientReminderAT => ({type: "USER-PRO/NOTIFICATION/DEACTIVATE/CLIENT-REMINDER", id})

export const getTarifHistoryTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getTarifHistory()
        dispatch(getTarifHistoryAC({items: res.data}))
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const getPayHistoryTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserPro.getPayHistory()
        dispatch(getPayHistoryAC(res.data))
    } catch (error: AxiosError | any) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          dispatch(logoutTC());
        }
      }
    } finally {
        dispatch(setIsLoadingAC(false))

    }
}

export const getClientRemindersTC = ():thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
      const res = await apiUserPro.getClReminderForNotif()
      dispatch(getClientRemindersAC(res.data))
  } catch (error) {
    
  } finally {
    dispatch(setIsLoadingAC(false))
  }
}

export const deactivateClientReminderTC = (id: string):thunkActionRootType => async dispatch => {
  dispatch(setIsLoadingAC(true))
  try {
    const res = await apiUserPro.deactivateClientReminder(id)
    dispatch(deactivateClientReminderAC(id))
  } catch (error) {
    
  } finally {
    dispatch(setIsLoadingAC(false))
  }
}


