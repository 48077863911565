import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { Box } from "../styles/components/Box"
import { FlexBox } from "../styles/components/FlexBox"
import { SuperButton } from "../styles/components/SuperButton"
import { Text } from "../styles/components/Text"
import { theme } from "../styles/components/Theme.styled"
import { useState, useEffect } from "react"
import { addDays, addMonths, endOfMonth, endOfWeek, isBefore, startOfMonth, startOfWeek, subMonths } from "date-fns"
import { ru } from "date-fns/locale"
import { Grid } from "../styles/components/Grid"
import { NextPrevButton } from "../styles/components/NextButton"
import { getFreeTimesForDayTC, setDateAndTimeForNoteAC } from "../store/SessionsReducer"



type propsType = {
    setDate: (date: string) => void
    setSelectDate: (show: false) => void
    setTime: (time: string) => void
    setMinutes: (time: string) => void


}
type settingDateType = { dayOrder: number, dateCurrentDay: string }[]
type datesType = {
    date: Date,
    isPresent: boolean
}

const selectFreeTimes = (state: AppRootState) => state.sessions
const selectUser = (state: AppRootState) => state.user
export const FreeTimesCalendar = (props: propsType) => {
    const buttonCount = 9
    const sessions = useSelector(selectFreeTimes)
    const user = useSelector(selectUser)
    const dispatch: thunkDispatchRootType = useDispatch()
    const [btnIdx, setBtnIdx] = useState<number>(9)
    const [start, setStart] = useState<Date>(new Date())
    const [dates, setDates] = useState<Array<datesType>>([])
    const [activeDate,setActiveDate] = useState<Date>(new Date())
    let weekDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"]
    const year = start.getFullYear()
    const month = start.toLocaleString("ru", { month: "long" })
    const fillCalendar = () => {
        const datesArray: datesType[] = []
        const datesForSettngs: settingDateType = []
        let firstDay = startOfMonth(start)
        let weekOfFirst = startOfWeek(firstDay, { locale: ru })
        const lastDay = endOfMonth(start)
        let weekOfEnd = endOfWeek(lastDay, { locale: ru })
        while (isBefore(weekOfFirst, weekOfEnd)) {
            if (weekOfFirst.getMonth() === start.getMonth()) {
                datesForSettngs.push({
                    dayOrder: weekOfFirst.getDay() === 0 ? weekOfFirst.getDay() + 7 : weekOfFirst.getDay(),
                    dateCurrentDay: weekOfFirst.toLocaleDateString()
                })
            }
            datesArray.push({ date: weekOfFirst, isPresent: weekOfFirst.getMonth() === start.getMonth() })
            weekOfFirst = addDays(weekOfFirst, 1)
        }
        setDates(datesArray)
    }
    const showNoteWindow = (date?: string, time?: string, duration?: number) => {
        if (date && time && duration) {
            props.setDate(date)
            props.setTime(time)
            props.setMinutes(duration.toString())
        }
        props.setSelectDate(false)
    }
    const prevMonth = (e:any) => {
        e.preventDefault()
        setStart(subMonths(start, 1))
    }
    const nextMonth = (e:any) => {
        e.preventDefault()
        setStart(addMonths(start, 1))
    }
    const getDate = () => {
        const date =  new Date(activeDate.toLocaleString("ru", {year: "numeric",month: "2-digit",day: "2-digit"}).split(".").reverse().join("-")).toISOString() 
        dispatch(getFreeTimesForDayTC(user.user?.id as string, date))
    }
    const upItems = () => {
        if (btnIdx !== sessions.freeTimeForDay.length) {
            setBtnIdx(prev => prev + 1)
        } else {
            return
        }

    }
    const downItems = () => {
        if (btnIdx !== 9) {
            setBtnIdx(prev => prev - 1)
        } else {
            return
        }
    }
    useEffect(() => {
        fillCalendar()
    }, [start])
    useEffect(() => {
        getDate()
    }, [activeDate])
    
    return (
        <Box className="freeTimeCalendars" direction="row" position="absolute" left="5px" top="148px" boxShadow='0px 4px 8px 0px rgba(0, 0, 0, 0.25)' background="#fff" height="385px" borderRaduis="16px" width="442px" zIndex={10}>
            <Box className="calendarFree" position='relative' boxShadow='0px 4px 8px 0px rgba(0, 0, 0, 0.25)' width='350px' height='385px' borderRaduis='16px' background='#fff' zIndex={10} >
                <FlexBox direction='column' align='center' justify='center' width='100%' height='100%' padding='14px 17px'>
                    <Text size={14} font='mont' color={theme.colors.fonts.main}>{year}</Text>

                    <FlexBox padding='0 20px' direction='row' width='100%' justify='space-between' align='center'>
                        <SuperButton onClick={prevMonth} maxWidth='15px' varinat='fake'>
                            <NextPrevButton component="calendar" type='prev' />
                        </SuperButton>
                        <Text transform='capitalize' font='mont' size={14} color={theme.colors.fonts.main}>{month}</Text>
                        <SuperButton onClick={nextMonth} maxWidth='15px' varinat='fake'>
                            <NextPrevButton component='calendar' type='next' />
                        </SuperButton>
                    </FlexBox>
                    <FlexBox height='100%' padding='15px 0' width='100%' direction='column'>
                        <FlexBox direction='row' width='100%' justify='space-between'>
                            {
                                weekDays.map(w =>
                                    <FlexBox align='center' justify='center' width='100%' direction='row' key={new Date().toISOString() + w}>
                                        <Text transform="capitalize" weight={500} font='Inter' color={theme.colors.fonts.main} size={15} >{w}</Text>
                                    </FlexBox>
                                )
                            }

                        </FlexBox>
                        <FlexBox height='100%' direction='row'>
                            <Grid width='100%' height='100%' columns='1fr 1fr 1fr 1fr 1fr 1fr 1fr' gap={2}>
                                {dates.map(d =>

                                    <FlexBox activeDate = {d.date.toLocaleDateString() === activeDate.toLocaleDateString()} onClick={() => setActiveDate(d.date)} border='2px solid #fff' className='date' align='center' justify='center' borderRadius='8px' width='100%' height='100%' direction='row'>
                                        <Text font='mont' size={14} color={d.isPresent ? theme.colors.fonts.main : "#AAA"}>{d.date.getDate()}</Text>
                                    </FlexBox>
                                )
                                }
                            </Grid>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </Box>
            {
                sessions.freeTimeForDay.length
                    ? <FlexBox align="center" padding="10px" gap={5} direction="column">
                        {
                            sessions.freeTimeForDay.length > 9
                                ? <SuperButton disabled={btnIdx === sessions.freeTimeForDay.length} onClick={upItems} height="auto" maxWidth='15px' varinat='fake'>
                                    <NextPrevButton component="timeButton" type='up' />
                                </SuperButton>
                                : <></>
                        }

                        <FlexBox slideCount={-((btnIdx - buttonCount) * 36)} overFlow="hidden" direction="column">
                            <FlexBox className="timesBtns" gap={6} direction="column">
                                {sessions.freeTimeForDay.map(el =>
                                    <SuperButton onClick={() => showNoteWindow(el.date, el.start, (el.endNumber - el.startNumber))} varinat="time" key={el.startNumber}>{el.start}</SuperButton>
                                )}
                            </FlexBox>
                        </FlexBox>

                        {
                            sessions.freeTimeForDay.length > 9
                                ? <SuperButton disabled={btnIdx === 9} onClick={downItems} height="auto" maxWidth='15px' varinat='fake'>
                                    <NextPrevButton component='timeButton' type='down' />
                                </SuperButton>
                                : <></>
                        }

                    </FlexBox>
                    : <></>
            }

        </Box>


    )
}