import axios from "axios";
import { rightsType } from "../store/TeamMateReducer";



const instance = axios.create({
    baseURL: "/api/teamMates"
})


export const  apiTeamMates = {
    getTeamMates(userID: string){
        return instance.get(`/get/${userID}`)
    },
    getTeamMatesNotes(userID: string, date: string){
        return instance.get(`/get/teamMates-notes/${date}/${userID}`)
    },
    changeTeamMateRightValue(teamMateID:string, right:rightsType){
        return instance.put(`/change/teamMate/right/${teamMateID}`,right)
    }
}
