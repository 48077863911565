import styled, { css } from "styled-components";
import { theme } from "./Theme.styled";


type propsType = {
    width: string,
    height: string,
    columns: string,
    gap?:number,
    pading?:string,
    
}

export const Grid = styled.div<propsType>`
display: grid;
    width: ${props => props.width};
    height: ${props => props.height};
    grid-template-columns: ${props => props.columns};
    gap: ${props => props.gap ? props.gap + "px" : 0};
    padding: ${props => props.pading ? props.pading  : 0};
    .date {
        &:hover {
            border: 2px solid;
            border-color: ${theme.colors.main};
            cursor: pointer;
            background: #fff;
           
            
        };
        &:hover>span{
            color: #262626;
        }
    }
`