import { callListType } from "../apiTypes/root/callList.type"
import {Text} from "../styles/components/Text"
import s from "./timeTables.module.css"

type propsType = {
    items: callListType[]
}

export const CallList = (props:propsType) => {
    return (
        <div style={{ width: "100%", height: "100%", background: "#ffffff", color: "#000000"}}>
                                                
        {
         props.items.map( c => 
         <div key={c.noteId} style={{height: "8%", background: "#598866", color: "white", fontSize: "12px", padding: "5px", borderRadius: "10px", margin: "1px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
             <Text color={'#fff'} font="mont" size={10}>{`${c.surname} ${c.name}`}</Text>
             <Text color={'#fff'} font="mont" size={10}>{c.phone}</Text>
         </div>)
        }
         

        
     </div>
    )
}