import React, { useEffect } from "react"
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalWindowStateType, setDateAndTimeAC, showTimesWindowAC } from "../store/ModalWindowsReducer";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import classes from "./timesWindow.module.css"
import { getFreeTimesForDayTC } from "../store/SessionsReducer";


const selectUser = (state: AppRootState) => state.user
const selectSessions = (state: AppRootState) => state.sessions
export const TimesWindow = ({ date, setMinutes, setTime }: { date: string, setMinutes: (minutes: string) => void , setTime: (time: string) => void }) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const user = useSelector(selectUser)
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)
    const sessions = useSelector(selectSessions)

   useEffect( () => {
        if(date) {
            dispatch(getFreeTimesForDayTC(user.user?.id as string, date))
        }
   }, [date])

    return ReactDOM.createPortal(
        <>
            <div onClick={() => (dispatch(showTimesWindowAC(false)))} style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                background: 'black',
                opacity: '0.6',
                top: 0,
                left: 0,
                zIndex: 50
            }}>

            </div>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <p className={classes.title}> {`Свободное время на`} {date as string}</p>
                    <div className={classes.btns}>
                        {
                            sessions.freeTimeForDay.map(el =>
                                <button onClick = {() => {
                                    setTime(el.start)
                                    setMinutes((el.endNumber - el.startNumber).toString())
                                    dispatch( showTimesWindowAC(false))
                                }} className={classes.btn}> {el.start}</button>)
                        }
                    </div>

                </div>
            </div>
        </>, document.body
    )
}