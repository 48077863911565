import { useEffect } from "react"
import { TableHeader } from "../styles/components/TableHeader"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState } from "../store/storeRedux"
import { fetchTeamMatesTC } from "../store/TeamMateReducer"
import { Column } from "../styles/components/Column"
import { Avatar } from "../styles/components/Avatar"
import { Text } from "../styles/components/Text"


type propsType = {
    userID: string | null,
    day: {date: string, day: moment.Moment}
}
const selectTeamMates = (state:AppRootState) => state.teamMates
const selectUser = (state:AppRootState) => state.user
export const TeamMateHeader = (props: propsType) => {
    const dispatch = useDispatch()
    const teamMates = useSelector(selectTeamMates)
    const user = useSelector(selectUser)
    useEffect(() => {
        dispatch(fetchTeamMatesTC(props.userID as string))
    },[])
    return(
        <>
        <TableHeader type="dynamic" offset="medium">
            <Column axis = "column" component="TimeTable">
                <Text size={13} color="#fff" font="mont" transform="capitalize">{props.day.day.toDate().toLocaleString("ru",{weekday: "long"})}</Text>
                <Text size={13} color="#fff" font="mont">{props.day.day.toDate().toLocaleString("ru", {day: "2-digit", month: "long", year: "numeric"})}</Text>
            </Column>
            {teamMates.teamMates.map(el => 
                <Column component="TimeTable">
                <Avatar size="medium" src={el.user.avatarUrl} alt="" />
                <span>{el.user.firstName}</span>
                </Column>
                )}
                <Column component="TimeTable">
                    <Avatar size="medium" src={user.user?.avatarUrl}></Avatar>
                    <Text size={14} font="mont" color="#fff">{user.user?.firstName}</Text>
                </Column>
        </TableHeader>
        </>
    )
}