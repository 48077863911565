import { tariffType } from "../store/UserReducer"



export const convertTimeToNumber = (el: string) => {
    let startTime = ((Number(el.split(":")[0])) * 60) + (Number(el.split(":")[1]))
    return startTime
}
export const getEndSession = (begin: string, duration: string): string => {
    let start = convertTimeToNumber(begin)
    const dur = parseInt(duration)
    const total = start + dur
    return Math.trunc(total / 60).toString().padStart(2, '0') + ':' + (total % 60).toString().padStart(2, '0')
}
export const convertNumberToTimeString = (time: number): string => {
    return Math.trunc(time / 60).toString().padStart(2, '0') + ':' + (time % 60).toString().padStart(2, '0')
}

export const getDuration = (start: number, end: number): string => {
    const res = String(end - start)
    return res
}
export const returnCapitalizeStr = (val: string): string => {
    return (val[0].toUpperCase() + val.slice(1))
}
export const returnTariff = (tariff: tariffType): string => {
    if (tariff === tariffType.FREE) {
        return "Обновить до PRO"
    }
    if (tariff === tariffType.PRO) {
        return "Обновить до PRO+"
    }
    if (tariff === tariffType.SuperPRO) {
        return "Обновить тариф"
    }
    return "Обновить тариф"
}

export const getMonthLong = (date: string | Date): string => {
    if(typeof date === "string"){
        const convert = date.split(".").reverse().join("-")
        const dateString = new Date(convert).toLocaleString("ru", { day: "numeric", month: "long", year: "numeric" })
        return dateString
    } else {
        return date.toLocaleString("ru", { day: "numeric", month: "long", year: "numeric" })
    }
   
}


export const appUtils = {
    getMoneyStr(val: number):string{
        const formatter = new Intl.NumberFormat("ru", {style: "currency", currency: "rur"})
        return formatter.format(val)
    }
}