import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { AppRootState } from '../../store/storeRedux'
import { calendarStateType } from '../../store/CalendarReducer'
import { ModalWindowStateType, addNewClientCardAC, showNewEntryAC, showTimesWindowAC } from '../../store/ModalWindowsReducer'
import { stateUserType } from '../../store/UserReducer'
import { Icon } from '../../styles/components/Icon'
import { Select } from '../../styles/components/Select'
import { CustomSelect } from '../../styles/components/CustomSelect'
import { FreeTimesCalendar } from '../../timeTable/FreeTimeCalendar'
import { FlexBox } from '../../styles/components/FlexBox'
import { createAndSignUpFormType } from '../../apiTypes/formTypes/clientFormType'
import { SuperButton } from '../../styles/components/SuperButton'
import { Avatar } from '../../styles/components/Avatar'
import { theme } from '../../styles/components/Theme.styled'
import { FormInputContainer } from '../../styles/components/FormInputContainer'
import { TextField } from '../../styles/components/TextField'
import { Text } from '../../styles/components/Text'
import PhoneInput from 'react-phone-input-2'
import { RadioInput } from '../../styles/components/Radio'
import { CheckBox } from '../../styles/components/CheckBox'
import { FormBox } from '../../styles/components/FormBox'
import { Border } from '../../styles/components/Border'
import { TimesWindow } from '../../timeTable/TimesWindow'
import { addClientAndSignTC } from '../../store/ClientsReducer'

const selectSessions = (state: AppRootState) => state.sessions
const selectProsettings = (state: AppRootState) => state.proSettings

export const AddNewClientCard = () => {
    const sessions = useSelector(selectSessions)
    const [activeClientForm, setActiveClientForm] = useState<boolean>(true)
    const [activeNewEntryForm, setActiveNewEntryForm] = useState<boolean>(false)
    const { register, handleSubmit, control, setValue, getValues, formState: { errors }, watch, clearErrors } = useForm<createAndSignUpFormType>()
    const [photoUrl, setPhotoUrl] = useState<any>("")
    const [calendar, setCalendar] = useState(false)
    const [selectDate, setSelectDate] = useState(false)
    const [minutes, setMinutes] = useState(sessions.selectedDuration || "60")
    const [time, setTime] = useState<string>(sessions.selectedTime)
    const [date, setDate] = useState<string>(sessions.selectedDate)
    const userState = useSelector<AppRootState, stateUserType>(state => state.user)
    const modalState = useSelector<AppRootState, ModalWindowStateType>(state => state.ModalWindowReducer)
    const calendarState = useSelector<AppRootState, calendarStateType>(state => state.calendarState)
    const proSettings = useSelector(selectProsettings)
    const dispatch = useDispatch()
    const showCalendar = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault()
        setSelectDate(true)
    }
    const counter = (e: any) => {
        e.preventDefault()
        let minute = parseInt(minutes)
        setMinutes((minute + 5).toString().padStart(2, '0'));
    }
    const decrement = (e: any) => {
        e.preventDefault()
        setMinutes((parseInt(minutes) - 5).toString())



    }
    const cancel = () => {
        dispatch(addNewClientCardAC(false))
    }


    const onSubmit = (data: createAndSignUpFormType) => {
        const dob = data.client.birthday ? new Date(data.client.birthday) : new Date()
        dispatch(addClientAndSignTC(data.client.avatar[0], data.client.name, data.client.surname, data.client.patronymic, data.client.phoneNumber, data.client.RadioGroup, dob, data.client.switch, { date, duration: minutes, time, comments: data.newEntry.comments, userId: userState.user?.id as string }))

    };
    const defineDate = () => {
        setSelectDate(true)
        setDate('')
        setTime('')
    }

    const showTimesWindow = () => {
        dispatch(showTimesWindowAC(true))
    }
    const setForm = (e: any) => {
        e.preventDefault()
        setActiveNewEntryForm(true)
        setActiveClientForm(false)
    }
    const back = (e: any) => {
        e.preventDefault()
        setActiveNewEntryForm(false)
        setActiveClientForm(true)
    }
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        if (fileReader.result) {
            setPhotoUrl(fileReader.result)
        }

    }
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            if (type === 'change') {
                clearErrors()
            }
            if (name === "client.RadioGroup") {
                clearErrors("client.RadioGroup")
            }
            if (name === "client.phoneNumber") {
                clearErrors("client.phoneNumber")
            }

            if (value.client?.avatar && name === "client.avatar") {
                fileReader.readAsDataURL(value.client.avatar[0])

            }
        })

        return () => subscription.unsubscribe();
    }, [watch])

    return (
        <FlexBox as={"form"} onSubmit={handleSubmit(onSubmit)} borderRadius='6px' direction='row' background='#f8f8f8' gap={20}  >
            <FormBox activeComponent={activeClientForm} component="addNewClientClient" position='static' noneTransform as={"form"} width="400px" padding="15px 44px" gap={12}>
                <Text size={16} font="mont" color={theme.colors.fonts.main}>Добавление нового клиента</Text>
                {
                    !photoUrl
                        ? <SuperButton as={"label"} varinat="photo">
                            <Icon svgID="camera" width={24} height={24} />
                            <input  {...register("client.avatar")} type="file" />
                        </SuperButton>
                        : <label>
                            <Avatar size="userAvatar" src={photoUrl} />
                            <input  {...register("client.avatar")} type="file" />
                        </label>
                }

                <FormInputContainer>
                    <Text font="mont" size={14} color={theme.colors.fonts.main}>Фамилия</Text>
                    <TextField transform="capitalize" {...register("client.surname", { required: { value: true, message: "Обязательное поле" }, maxLength: { value: 20, message: "Фамилия обязательное поле не более 20 символов" } })} placeholder="Фамилия" />
                    <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.client?.surname?.message}</Text>
                </FormInputContainer>
                <FormInputContainer>
                    <Text font="mont" size={14} color={theme.colors.fonts.main}>Имя</Text>
                    <TextField transform="capitalize" {...register("client.name", { required: { value: true, message: "Обязательное поле" }, maxLength: { value: 15, message: "Имя обязательное поле не более 20 символов" } })} placeholder="Имя" />
                    <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.client?.name?.message}</Text>
                </FormInputContainer>
                <FormInputContainer>
                    <Text font="mont" size={14} color={theme.colors.fonts.main}>Отчество</Text>
                    <TextField transform="capitalize" {...register("client.patronymic")} placeholder="Отчество" />
                </FormInputContainer>
                <FormInputContainer>
                    <Text font="mont" size={14} color={theme.colors.fonts.main}>Телефон</Text>
                    <PhoneInput
                        inputStyle={{ height: '50px' }}
                        onlyCountries={['by', 'ru']}
                        country={'ru'}
                        value={getValues("client.phoneNumber")}
                        onChange={(v, f, e) => { if (e.target.value) setValue("client.phoneNumber", e.target.value) }}
                    />
                    <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.client?.phoneNumber?.message}</Text>
                </FormInputContainer>
                <FlexBox justify="center" align="center" width="100%" direction="row">
                    <FlexBox gap={10} width="100%" direction="row">
                        <RadioInput child={<input name="RadioGroup" onChange={() => setValue("client.RadioGroup", "male")} type="radio" />} label="М" />
                        <RadioInput child={<input name="RadioGroup" onChange={() => setValue("client.RadioGroup", "female")} type="radio" />} label="Ж" />

                    </FlexBox>
                    <FlexBox width="100%" direction="row" >
                        {
                            !calendar
                                ? <TextField style={{ display: "flex", gap: "8px", padding: "0 10px" }} onClick={() => setCalendar(true)} width="100%" as={"div"}>
                                    {!getValues("client.birthday") && <Icon svgID="calendar" width={16} height={16} />}
                                    {
                                        getValues("client.birthday")
                                            ? <Text size={14} color={theme.colors.fonts.main} font="mont">{new Date((getValues("client.birthday"))).toLocaleDateString()}</Text>
                                            : <Text size={14} color={theme.colors.fonts.main} font="mont">День рождения</Text>
                                    }


                                </TextField>
                                : <TextField max="2999-12-31" autoFocus placeholder="Дата рождения" width="100%"  {...register('client.birthday', { onBlur: () => setCalendar(false) })} type="date" />
                        }

                    </FlexBox>
                </FlexBox>
                {errors.client?.RadioGroup?.message && <Text color={theme.colors.fonts.errors} size={14} font="mont">{errors.client.RadioGroup?.message}</Text>}

                <FlexBox marginTop="12px" width="100%" justify="start" align="center" gap={10} direction="row">
                    <CheckBox variant="switch" render={() => <input {...register("client.switch")} type="checkbox" />} value={watch("client.switch")} />
                    <Text color={theme.colors.fonts.main} font="mont" size={14}>Согласие на SMS-уведомления</Text>
                </FlexBox>
                <FlexBox style={{marginTop: "40px"}} className='btnNext' marginTop="2px" width="100%" direction="row" gap={12} height="48px">
                    <SuperButton onClick={setForm} varinat="contained">Далее</SuperButton>
                </FlexBox>
            </FormBox>

            <FlexBox className='addNewClientBorder' align='center' justify='center' direction='row' height='auto'>
                <Border opacity={0.6} borderColor='#444444' width={"1px"} height={"90%"} />
            </FlexBox>
            <FormBox component="addNewClientSign" activeComponent={activeNewEntryForm} top='0px' left='0px' position='relative' width="400px" noneTransform padding="15px 22px" gap={12}>
                {selectDate &&
                    <FreeTimesCalendar setMinutes={setMinutes} setTime={setTime} setSelectDate={setSelectDate} setDate={setDate} />
                }
                {
                    modalState.timesWindow && <>
                        <TimesWindow setMinutes={setMinutes} setTime={setTime} date={date} />
                    </>
                }
                <FlexBox direction='row' width='100%' justify="start" className='rowBack'>
                   <SuperButton maxWidth='20px' onClick={back} varinat='fake'>
                    <Icon svgID='rowBack' width={12} height={6}/>
                   </SuperButton>
                </FlexBox>
                <Text size={16} font="mont" color={theme.colors.fonts.main}>Запись в расписание</Text>
                <FlexBox direction='column' height='100%' width='100%' justify='space-around'>
                    <FormInputContainer>
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Услуга</Text>
                        {/* <Select tooltip="Для тарифов PRO и PRO+" setValue={setValue} name="newEntry.service" state={proSettings.works} placeholder="Выберите услугу" /> */}
                    </FormInputContainer>
                    <FormInputContainer>
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Дата и время</Text>
                        <FlexBox direction='row' align='center' justify='space-between'>
                            <CustomSelect />
                            <FlexBox direction='row' width='100%' justify='space-between' gap={8} height='100%'>

                                {
                                    date && !selectDate
                                        ? <FlexBox padding='0 6px' direction='row' borderRadius='8px' align='center' justify='space-around' width='100%' background='#fff' onClick={defineDate} border='1px solid rgba(0, 0, 0, 0.10)'>{new Date(date).toLocaleDateString()}</FlexBox>
                                        : <FlexBox padding='0 6px' onClick={showCalendar} direction='row' borderRadius='8px' align='center' justify='space-around' width='100%' background='#fff' border='1px solid rgba(0, 0, 0, 0.10);'>
                                            <SuperButton maxWidth='20px' height='100%' varinat='fake' >
                                                <Icon fill='#262626' svgID='calendar2' width={20} height={16} />
                                            </SuperButton>
                                            <Text font='mont' size={12} color='#444'>Дата</Text>

                                        </FlexBox>
                                }

                            </FlexBox>
                            {
                                time && !selectDate
                                    ? <FlexBox onClick={showTimesWindow} direction='row' padding='0 6px' borderRadius='8px' align='center' justify='space-around' width='100%' height='100%' border='1px solid rgba(0, 0, 0, 0.10)' background='#fff'>{time}</FlexBox>
                                    : <FlexBox direction='row' padding='0 6px' borderRadius='8px' align='center' justify='space-around' width='100%' height='100%' border='1px solid rgba(0, 0, 0, 0.10)' background='#fff'>
                                        <Icon svgID='clock' width={22} height={22} transform='scale(0.7)' />
                                        <Text font='mont' size={12} color='#444'>Время</Text>
                                    </FlexBox>
                            }
                        </FlexBox>
                    </FormInputContainer>
                    <FormInputContainer>
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Продолжительность</Text>
                        <FlexBox background='#fff' direction='row' height='50px' align='center' justify='space-around' borderRadius='8px'>
                            <SuperButton varinat="counter" disabled={parseInt(minutes) <= 0} onClick={decrement}>
                                <Text color="#fff" font="mont" size={10}>-5</Text>
                            </SuperButton>
                            <Text color="#262626" font="mont" size={16}>
                                {Math.trunc(parseInt(minutes) / 60).toString().padStart(2, '0')}:{(parseInt(minutes) % 60).toString().padStart(2, '0')}
                            </Text>
                            <SuperButton onClick={counter} varinat="counter">
                                <Text size={10} font="mont" color="#fff">+5</Text>
                            </SuperButton>
                        </FlexBox>
                    </FormInputContainer>
                    <FormInputContainer>
                        <Text font="mont" size={14} color={theme.colors.fonts.main}>Комментарии</Text>
                        <textarea {...register("newEntry.comments")} style={{ height: '100px', borderRadius: '6px' }} cols={30}  ></textarea>
                    </FormInputContainer>
                    <FlexBox marginTop="2px" width="100%" direction="row" gap={12} height="48px">
                        <SuperButton onClick={cancel} varinat="outlined">Отменить</SuperButton>
                        <SuperButton type="submit" varinat="contained">Создать</SuperButton>
                    </FlexBox>
                    <Text className='addNewClientErrorClient' color={theme.colors.fonts.errors} size={14} font="mont">{errors.client && "Проверьте данные при создании клиента"}</Text>
                </FlexBox>

            </FormBox >

        </FlexBox >
    )
}