import { Controller, useForm } from "react-hook-form"
import { FileInputComponent } from "../../styles/components/FileInputComponent"
import { SimpleForm } from "../../styles/components/SimpleForm"
import { Title } from "../../styles/components/Title"
import { TCreateTeammateForm } from "../../apiTypes/formTypes/fillModeFormTypes"
import { useEffect, useState } from "react"
import { FormInputContainer } from "../../styles/components/FormInputContainer"
import { TextField } from "../../styles/components/TextField"
import PhoneInput from "react-phone-input-2"
import { LabelInputColor } from "../../styles/components/ColorInput"
import { ButtonContainer } from "../../styles/components/ButtonContainer"
import { SuperButton } from "../../styles/components/SuperButton"
import { useDispatch, useSelector } from "react-redux"
import { showCreateTeammateAC } from "../../store/ModalWindowsReducer"
import { AppRootState } from "../../store/storeRedux"
import { createTeamMateTC } from "../../store/TeamMateReducer"
import { setErrorMessageTeamReqAC } from "../../store/AppReducer"


const selectUser = (state: AppRootState) => state.user
const selectAppState = (state: AppRootState) => state.appState
export const CreatingTeammateWindow = () => {
    const dispatch = useDispatch()
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<TCreateTeammateForm>()
    const [photoUrl, setPhotoUrl] = useState<string>("")
    const [color, setColor] = useState("")
    const user = useSelector(selectUser)
    const appState = useSelector(selectAppState)
    const fileReader = new FileReader()

    const hideWindow = () => dispatch(showCreateTeammateAC(false))
    const saveData = (data: TCreateTeammateForm) => {
        const formData = new FormData()
        formData.append("image", data.image[0])
        formData.append("firstName", data.firstName)
        formData.append("lastName", data.lastName)
        formData.append("thirdName", data.thirdName)
        formData.append("email", data.email)
        formData.append("phoneNumber", data.phoneNumber)
        formData.append("password", data.password)
        formData.append("color", data.color)
        dispatch(createTeamMateTC(formData, user.user?.id as string))
    }
    useEffect(() => {
        const subsc = watch((value, { name, type }) => {
            if (value.image && name === "image") {
                fileReader.readAsDataURL(value.image[0])
            }
            if (value.color && name === "color" && type) {
                setColor(value.color)
            }
            if (value.email && name === "email" && type) {
                dispatch(setErrorMessageTeamReqAC(""))
            }
        })
        return () => subsc.unsubscribe()
    }, [watch])
    fileReader.onloadend = () => {
        if (fileReader.result && typeof fileReader.result === "string") {
            setPhotoUrl(fileReader.result)
        }

    }
    return (
        <SimpleForm onSubmit={handleSubmit(saveData)}>
            <Title size={18}>Создать нового пользователя</Title>
            <FileInputComponent photoUrl={photoUrl} register={register} />
            <FormInputContainer>
                <Title size={14}>Фамилия</Title>
                <TextField {...register("lastName")} placeholder="Фамилия" type="text" />
            </FormInputContainer>
            <FormInputContainer>
                <Title size={14}>Имя</Title>
                <TextField {...register("firstName")} placeholder="Имя" type="text" />
            </FormInputContainer>
            <FormInputContainer>
                <Title size={14}>Отчество</Title>
                <TextField {...register("thirdName")} placeholder="Отчество" type="text" />
            </FormInputContainer>
            <FormInputContainer>
                <Title size={14}>Почта и телефон</Title>
                <div>
                    <TextField {...register("email", { pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "неверный формат email" } })} placeholder="email - логин" type="text" />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { onChange, value } }) =>
                            <PhoneInput
                                onChange={(e, f, v) => onChange(v)}
                                value={value}
                                onlyCountries={["ru", "by"]}
                                country={"ru"}
                            />
                        }
                    />
                </div>
                {errors.email?.message && <p>{errors.email.message}</p>}
                {appState.errorMessageTeamRequest && <p>{appState.errorMessageTeamRequest}</p>}

            </FormInputContainer>
            <FormInputContainer>
                <Title size={14}>Временный пароль</Title>
                <div>
                    <TextField {...register("password")} placeholder="пароль" type="password" />
                    {/* <TextField {...register("password2", {validate: (val: string) => watch("password") !== val ? "не совпадают" : ""})} placeholder="повторите пароль" type="password" /> */}
                </div>
                {/* {errors.password2 && <span>{errors.password2.message}</span>} */}

            </FormInputContainer>
            <FormInputContainer>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <LabelInputColor color={color}>
                        <input  {...register("color")} type="color" />
                    </LabelInputColor>
                    <div style={{ marginLeft: "40px", width: "194px" }}>
                        <Title size={12}>
                            Выберите желаемый цвет для
                            отображения в расписании
                        </Title>
                    </div>

                </div>

            </FormInputContainer>
            <ButtonContainer>
                <SuperButton onClick={hideWindow} varinat="outlined">Отменить</SuperButton>
                <SuperButton type="submit" varinat="contained">Создать</SuperButton>
            </ButtonContainer>
        </SimpleForm>
    )
}