import * as React from "react";
import { Reorder } from "framer-motion";
import { useDispatch } from "react-redux";
import { rootUserType, selectTariffTC, tariffType } from "../store/UserReducer";
import { Icon } from "../styles/components/Icon";
import { TariffCard } from "../styles/components/TariffCard";
import { Text } from "../styles/components/Text";
import { FlexBox } from "../styles/components/FlexBox";
import { Border } from "../styles/components/Border";
import { SuperButton } from "../styles/components/SuperButton";
import { profile } from "console";

interface Props {
    item: {
        id: string,
        tariff: tariffType,
        title: string,
        active: boolean,
        cost: string,
        userCount: string,
        opportunities: string[],
        subTitle?: string,

    },
    profile: rootUserType | null,
    userId: string | null
}


export const ItemMobile = ({ item, profile, userId }: Props) => {
    const dispatch = useDispatch()
    const changeTariff = (id: string | null, tariff: tariffType) => {
        dispatch(selectTariffTC(id, tariff))
    }
    const rootColor = item.tariff === tariffType.PRO ? "#444" : "#fff"
    const titleColor = item.tariff === tariffType.PRO ? "#262626" : "#fff"
    return (
        <TariffCard tariff={item.tariff}    id={item.id} >

            <FlexBox direction="row" align="center" justify="center" gap={12}>
                <Text className="proTitle" contentMargin="5px" subtitle contentSize={15} content={item.subTitle} size={50} font="mont" color={titleColor}>{item.title}</Text>
            </FlexBox>
            <FlexBox justify="space-between" width="100%" direction="row">
                <FlexBox fontSize={10} height="69px" justify="space-between" direction="column">
                    <Text realtiveSize color={rootColor} font="mont" size={"1.6em"}>{"стоимость в день"}</Text>
                    <FlexBox fontSize={10} align="center" justify="center" direction="row">
                        <Text realtiveSize subtitle content="р." contentSize={16} size={"3.6em"} font="mont" color={rootColor}>{item.cost}</Text>
                        {item.tariff === tariffType.SuperPRO && <Text realtiveSize size={"3.6em"} color={rootColor} font="mont">+</Text>}
                    </FlexBox>
                </FlexBox>
                <FlexBox fontSize={10} height="69px" justify="space-between" direction="column">
                    <Text realtiveSize color={rootColor} font="mont" size={"3.6em"}>{item.userCount}</Text>
                    <Text realtiveSize color={rootColor} font="mont" size={"1.6em"}>Пользователь</Text>
                </FlexBox>
            </FlexBox>
            <Border opacity={item.tariff === tariffType.PRO ? 0.6 : 1} height={0} width={"100%"} borderColor={rootColor} />
            <FlexBox direction="column" gap={16}>
                {
                    item.opportunities.map(el =>
                        <FlexBox fontSize={10} key={new Date().toISOString() + el} direction="row" gap={14} align="center">
                            {
                                item.tariff === tariffType.PRO
                                    ? <Icon svgID="list2" width={22} height={22} />
                                    : <Icon fill={rootColor} stroke={rootColor} svgID="list" width={22} height={22} />
                            }

                            <Text realtiveSize size="1.8em" font="mont" color={rootColor}>{el}</Text>
                        </FlexBox>
                    )
                }
            </FlexBox>

            {
                profile?.tariff === item.tariff
                    ? <SuperButton className="btnTariff" active = {profile?.tariff === item.tariff} as={"div"} varinat="tariff">
                        <Icon svgID="proIcon" width={17} height={19} />
                        <Text size={20} color="#fff" font="mont">Текущий план</Text>
                    </SuperButton>
                    : 
                         <SuperButton  style={{opacity:1}} onClick={() => changeTariff(userId, item.tariff)} varinat="changeTariff">
                            <Icon svgID="checkbox" width={36} height={36}/>
                            <Text size={20} color="#fff" font="mont">Выбрать план</Text>
                        </SuperButton>
            }
        </TariffCard>
    );
};